import React from "react";
import "./PointsRemember.css";
import Skeleton from "react-loading-skeleton";

const Cancellation = ({ singlePackageData, loader }) => {
  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
                <div className="package-update-card">
                  <div className="content-consider">
                    <div>
                      <Skeleton width={100} />
                      <div className="agent">
                        <div className="singlePackageTabShowBullets">
                          <ul>
                            <li><Skeleton width={720} /></li>
                            <li><Skeleton width={400} /></li>
                            <li><Skeleton width={300} /></li>
                            <li><Skeleton width={200} /></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card">
            {/* Content for accommodation */}
            <div className="content-consider">
              <div>
                <div className="agent">
                  <div>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>Cancellation Policy</span>
                  </div>
                  {/* <button className="change_btn">Change</button> */}
                </div>
                {singlePackageData?.packagePolicies?.length > 0 ? (
                  <>
                    {singlePackageData?.packagePolicies?.map((items) => {
                      const cancellation_policy = items;
                      return (
                        <>
                          {items.policyType === "cancellation_policy" && (
                            <div
                              className="singlePackageTabShowBullets"
                              dangerouslySetInnerHTML={{
                                __html: items.policyContent,
                              }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-data-found">No data found</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>


  );
};

export default Cancellation;
