import React, { useEffect, useState } from "react";
import "./AllThemes.css";
import { Link } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
function AllThemes() {
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");
  const [themes, setThemes] = useState([]);
  
  const fetchThemesDetails = (token) => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_APP_URL}/api/tour-themes`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, 'themes');
        if (data.success) {
          setThemes(data?.themes);
          setLoader(false);
        } else {
          // console.log("Error fetching data");
        }
      })
      .catch((error) => {
        console.error("Error fetching  details:", error);
      });
  };

  useEffect(() => {
    fetchThemesDetails();
  }, []);

  return (
    <>
       <PageMetaData
        title={`All Themes - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
        canonicalUrl={"https://www.mountainmysteries.com/themes"}
      />
      <OgMetaTitles
        title={`All Themes - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/themes`}
      />


      <Header />
      {loader ? (
        <>
          <div className="container">
            <div>
              <div className="mt-20">
                <Skeleton width={300} height={30} />
              </div>
              <div className="destinationsCard mt-10 evenly-align">
                {Array.from({ length: 9 }).map((_, index) => (
                  <div className="destination_link destination-image">
                    <Skeleton height={280} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <section>
          <div className="container pb_30">
            <h3 className="text-left mt-20">All Themes</h3>
            <div className="NewFile_Content">
              {themes.map((items) => {
                return (
                  <>
                  


                 {items && items?.id && <div className="NewFile_catgoryWrap">
                    <div className="NewFile_imageBox">
                      <Link
                        to={`/themes/${items.id}?themename=${items?.slug}`}
                        className="w-full h-full"
                        >
                        <img src={items.featuredImage} alt="" />
                      </Link>
                    </div>
                  </div>}
                        </>
                );
              })}
            </div>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
}

export default AllThemes;
