import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import FilterHoliday from "../../CustomComponents/FilterSection/FilterHoliday";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";

function AllThemesPackages() {
  const [themesPkg, setThemesPkg] = useState([]);
  const [themeName, setThemeName] = useState("");
  const location = useLocation();
  const { id, themename } = useParams();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setThemeName(query.get("themename"));
    if (id) {
      fetchThemesPkg(id);
    }
  }, [id, location.search]);

  const fetchThemesPkg = (id) => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_APP_URL}/api/package-tour-themes/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, 'themespkg');
        if (data.success) {
          setThemesPkg(data?.themes);
        } else {
          // console.log("Error fetching data");
        }
      })
      .catch((error) => {
        console.error("Error fetching  details:", error);
      });
  };
  // console.log(themesPkg, "pkgdata");

  return (
    <>
      <Header />
      <div className="container pb_30" id="themePackages">
        <div className="">
          <h3
            className="text-left mt-20"
            style={{ textTransform: "capitalize" }}
          >
            <span>Showing All Packages in {themeName} Theme </span>
          </h3>
          <div className="cards-flex ">
            {themesPkg?.length > 0 ? (
              <>
                {themesPkg.map((pkg, index) => {
                  return (
                    <>
                      {pkg && pkg?.id && (
                        <CustomListingCard
                          key={index}
                          imgSrc={pkg?.package?.images[0]}
                          title={pkg?.package?.name}
                          // packageHub={pkg.packageHub}
                          duration={pkg?.package?.days}
                          nightDuration={pkg?.package?.nights}
                          price={pkg?.package?.price}
                          // bookingLink={pkg.bookingLink}
                          // detailsLink={pkg.detailsLink}
                          packagehandle={pkg?.package?.slug}
                        />
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
        {/* <main>
                    <FilterHoliday />

                </main> */}
      </div>

      <Footer />
    </>
  );
}

export default AllThemesPackages;
