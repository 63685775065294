import React, { useEffect, useState } from "react";
import "../SendItinerary/SendItinerary.css";
import whatsappIcon from "../../assets/images/whatsapp.png";
import printIcon from "../../assets/images/print.png";
import emailIcon from "../../assets/images/email.png";
import { toast } from "react-toastify";
import PrintButtonClickPackage from "../ButtonToDownload/PrintButtonClickPackage";
import PrintButtonPackageDetails from "../ButtonToDownload/PrintButtonPackageDetails";

function SendItinerary({ singlePackageData }) {
  // console.log("ppp", singlePackageData);
  const [message, setMessage] = useState(
    singlePackageData?.packageHandle +
      "click below to check detailed itinerary:👇👇👇"
  );

  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setMessage(
      singlePackageData?.packageHandle + " " +
        "click below to check detailed itinerary:👇👇👇"
    );
  }, [singlePackageData]);
  const [url, setUrl] = useState(`https://www.mountainmysteries.com/package/${singlePackageData?.packageHandle}?location=&guest=2&children=0&packagehandle=${singlePackageData?.packageHandle}`);

  const handleClick = () => {
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%0A${encodeURIComponent(
      url
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePrintClick = (printId, ignoreIds) => {
    ignoreIds?.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.classList.add("no-print");
      }
    });
    const printContent = document.getElementById(printId).innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    ignoreIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.classList.remove("no-print");
      }
    });
  };
  const sendEmailData = (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      package_id: singlePackageData?.packageId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/send-package-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result?.success) {
          setShowPopup(false);
          toast.success(result?.message);
          setEmail("");
        } else {
          toast.error(result?.message);
        }
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  return (
    <>
      <div className="flex flex_gap_10">
        <div onClick={handleClick} className="list_details cursor_pointer">
          <img src={whatsappIcon} alt="whatsapp-icon" className="icon-img" />
          <span className="itineary_send_txt">
            Send <br /> Itinerary
          </span>
        </div>
        {/* <div onClick={handlePrintClick} className="list_details cursor_pointer">
          <img src={printIcon} alt="print-icon" className="icon-img" />
          <span>
            Print <br /> Itinerary
          </span>
        </div> */}
        <PrintButtonPackageDetails />
        <div
          onClick={() => setShowPopup(true)}
          className="list_details cursor_pointer"
        >
          <img src={emailIcon} alt="email-icon" className="icon-img" />
          <span  className="itineary_send_txt">
            Email <br /> Itinerary
          </span>
        </div>                                                  
      </div>
      {showPopup && (
        <div className="bg-backdrop">
          <div
            className="dashboard_checkin_modal_container" id="send_query_popup"
          >
            <h4
              style={{ fontSize: "18px", fontWeight: "600", color: "#BB735D" }}
            >
              Plan Your Journey
            </h4>
            <div
              className="dashboard_checkin_modal_close_btn"
              onClick={() => setShowPopup(false)}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1 12.7464L13 0.851318"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
                <path
                  d="M13 12.7463L0.999999 0.851296"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
            <form
              onSubmit={(e) => sendEmailData(e)}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <input
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                style={{
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
              <div class="search-btn-width">
                <button class="common_btn">Send</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default SendItinerary;
