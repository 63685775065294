import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../../assets/toastUtils";
import Flatpickr from "react-flatpickr";
import GooglePlaceApi from "../../GoogleApi/GooglePlaceApi/GooglePlaceApi";
import "../CustomSearchFormActivities/CustomSearchFormActivities.css";
import NewHeaderBg from "../../NewHeaderBg/NewHeaderBg";
import SearchStateInput from "../../SearchSateInput/SearchStateInput";
import NewSearchSateInput from "../../NewSearchSateInput/NewSearchSateInput";
import { currentDateSingle } from "../../../utils/utils";

function CustomSearchFormActivities({ topRadios }) {
  const [guestDetails, setIsguestDetails] = useState(false);
  const [destinationToggle, setdestinationToggle] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };
  const [dataFromChild, setDataFromChild] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  // const [stateResultArray, setStateResultArray] = useState("");
  // console.log(stateResultArray);

  const getDataFromChild = (data, resultArray, selectedType) => {
    setDataFromChild(data);
    setSelectedType(selectedType);
    // setStateResultArray(resultArray)
  };
  // console.log(dataFromChild, "getDataFromChild");

  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const checkinDay = queryParams.get("checkinDay") || "";
  const checkinMonth = queryParams.get("checkinMonth") || "";
  const checkinYear = queryParams.get("checkinYear") || "";
  const checkoutDay = queryParams.get("checkoutDay") || "";
  const checkoutMonth = queryParams.get("checkoutMonth") || "";
  const checkoutYear = queryParams.get("checkoutYear") || "";

  const [checkin, setCheckin] = useState({
    day: checkinDay,
    month: checkinMonth,
    year: checkinYear,
  });

  const [checkout, setCheckout] = useState({
    day: checkoutDay,
    month: checkoutMonth,
    year: checkoutYear,
  });
  const navigate = useNavigate();

  // STATES
  const [date, setDate] = useState(new Date());
  const [selectedRadio, setSelectedRadio] = useState("");

  const [data, setData] = useState({
    fromCity: "",
    toCity: "",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //
  const [adult, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);

  // Date alterations
  const [loader, setLoader] = useState(false);

  // const [checkin, setCheckin] = useState({
  //   day: router.query.checkinDay || "",
  //   month: router.query.checkinMonth || "",
  //   year: router.query.checkinYear || "",
  // });

  // const [checkout, setCheckout] = useState({
  //   day: router.query.checkoutDay || "",
  //   month: router.query.checkoutMonth || "",
  //   year: router.query.checkoutYear || "",
  // });

  useEffect(() => {
    if (
      checkinDay &&
      checkinMonth &&
      checkinYear &&
      checkoutDay &&
      checkoutMonth &&
      checkoutYear
    ) {
      const formatDateToString = (year, month, day) => {
        const paddedMonth = String(month).padStart(2, "0");
        const paddedDay = String(day).padStart(2, "0");
        return `${year}-${paddedMonth}-${paddedDay}`;
      };

      const checkInDateString = formatDateToString(
        checkinYear,
        checkinMonth,
        checkinDay
      );
      const checkOutDateString = formatDateToString(
        checkoutYear,
        checkoutMonth,
        checkoutDay
      );

      const checkInDate = new Date(checkInDateString);
      const checkOutDate = new Date(checkOutDateString);

      setStartDate(checkInDate);
      setEndDate(checkOutDate);
    }
  }, [
    checkinDay,
    checkinMonth,
    checkinYear,
    checkoutDay,
    checkoutMonth,
    checkoutYear,
  ]);

  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = children - 1;
    setChildren(updatedChildren);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = children + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const onAdultIncrease = () => {
    const newAdult = adult + 1;
    setAdults(newAdult);
    if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
      setRooms(rooms + 1);
    }
  };

  const onAdultDecrease = () => {
    if (adult > 1) {
      const newAdult = adult - 1;
      setAdults(newAdult);
      if (newAdult % 2 !== 0 && rooms > 1) {
        setRooms(rooms - 1);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const handleSubmitButtonClickHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    // if (endDate <= startDate) {
    //   alert("Check out must be a date after check in");
    //   return;
    // }

    setLoader(true);

    try {
      await navigate(
        `/listing-activities?location=${dataFromChild}&date=${new Date(
          date
        ).getDate()}&month=${new Date(date).getMonth()}&year=${new Date(
          date
        ).getFullYear()}&radio=${selectedRadio}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const handleSubmitButtonClickHandlerActivity = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    // if (endDate <= startDate) {
    //   alert("Check out must be a date after check in");
    //   return;
    // }

    setLoader(true);

    try {
      await navigate(
        `/single-activitiy/${selectedType?.id}?location=${
          selectedType?.location
        }&date=${new Date(date).getDate()}&month=${
          new Date(date).getMonth() + 1
        }&year=${new Date(date).getFullYear()}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  // console.log(selectedType, "ppp");

  return (
    <>
      <section className="NewSearch-BG">
        <div className="container">
          {/* <NewHeaderBg /> */}
          <div className="top-banner-img" id="activityBanner">
            {/* <img src={bgimg} alt="banner-img" /> */}

            <div
              className="NewSearchActivities-header"
              id=""
              style={
                guestDetails || destinationToggle
                  ? { position: "", zIndex: "9999" }
                  : { position: "" }
              }
            >
              {" "}
              <h1 className="text-center">
                Himachal’s Adventure & Experiences
              </h1>
              <p className="common_text">
                Book Exciting Adventures & Experiences in Himachal
              </p>
              <div
                style={
                  destinationToggle
                    ? { position: "", zIndex: "999" }
                    : { position: "" }
                }
                className="NewSearch-Main search_form"
                id="activitySearchForm"
              >
                <form
                  className="search_form_main"
                  // onSubmit={handleSubmitButtonClickHandler}
                  onSubmit={
                    selectedType?.type === "activity"
                      ? handleSubmitButtonClickHandlerActivity
                      : handleSubmitButtonClickHandler
                  }
                >
                  <button className="hide_radio_btn">
                    <span>All</span>
                    <svg
                      fill="#BB735D"
                      height="10px"
                      width="10px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 330 330"
                      xmlSpace="preserve"
                    >
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  </button>

                  <div className="search_data_home">
                    <div className="search_form_div NewSearch-tags">
                      <label htmlFor="">City/Destination/Activity</label>
                      {/* <SearchStateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        dropdownGuestDetails={dropdownGuestDetails}
                        setIsguestDetails={setIsguestDetails}
                      /> */}
                      <NewSearchSateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        dropdownGuestDetails={dropdownGuestDetails}
                        setIsguestDetails={setIsguestDetails}
                        type="activity"
                      />

                      {/* <GooglePlaceApi
                        onClick={() => setdestinationToggle(true)}
                        setSelectedLocation={setSelectedLocation}
                        data={data}
                        setData={setData}
                        showErrorToast={showErrorToast}
                        position={"accommodations"}
                      /> */}
                    </div>

                    <div className="search_form_div NewSearch-tags border_0">
                      <label>Travel Date</label>
                      <Flatpickr
                        className="m_0 serach_form_input"
                        color="#ffffff"
                        value={date}
                        onChange={([date]) => setDate(date)}
                        min={new Date()}
                        f
                      />
                    </div>
                    {/* <div className="search_form_div NewSearch-tags icon_button_only">
                                    <label
                                        onClick={dropdownGuestDetails}
                                        className="search_label"
                                    >
                                        Room & Guests{" "}
                                        <svg
                                            width="9"
                                            height="5"
                                            viewBox="0 0 9 5"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.25 0.75L4.75 4.25L1.25 0.75"
                                                stroke="#BB735D"
                                            />
                                        </svg>
                                    </label>
                                    <p
                                        style={{ position: "relative" }}
                                        className="dropdown-toggle search_form_input"
                                    >
                                        <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                                            <div className="optionCounter"></div>
                                            <span id="adult_count">{adult}</span>
                                            <span> Adults,</span> <span id="room_count">{rooms}</span>
                                            <span> Rooms, </span>{" "}
                                            <span id="child_count">{children}</span>
                                            <span> Children</span>
                                        </div>
                                        {guestDetails && (
                                            <>
                                                <div
                                                    className="guest_details_dropdown"
                                                    id="guestDetailsPopup"
                                                >
                                                    <div className="agent">
                                                        <span>Adult</span>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                disabled={adult <= 1}
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={onAdultDecrease}
                                                            >
                                                                <span className="onclick_btn" id="adult_minus">
                                                                    -
                                                                </span>
                                                            </button>

                                                            <input
                                                                className="optionCounterNumber"
                                                                value={adult}
                                                                min={1}
                                                            />
                                                            <button
                                                                type="button"
                                                                // disabled={rooms <= 1}
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={onAdultIncrease}
                                                            >
                                                                <span className="onclick_btn" id="adult_plus">
                                                                    +
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agent">
                                                        <span>Rooms</span>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                disabled={rooms <= 1}
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={() => setRooms(Number(rooms) - 1)}
                                                            >
                                                                <span className="onclick_btn" id="adult_minus">
                                                                    -
                                                                </span>
                                                            </button>

                                                            <input
                                                                className="optionCounterNumber"
                                                                value={rooms}
                                                                min={1}
                                                            />

                                                            <button
                                                                type="button"
                                                                // disabled={rooms <= 1}
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={() => setRooms(Number(rooms) + 1)}
                                                            >
                                                                <span className="onclick_btn" id="adult_plus">
                                                                    +
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agent">
                                                        <span>Children</span>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                disabled={children <= 0}
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={onChildDecrease}
                                                            >
                                                                <span className="onclick_btn" id="adult_minus">
                                                                    -
                                                                </span>
                                                            </button>

                                                            <input
                                                                className="optionCounterNumber"
                                                                value={children}
                                                                min={1}
                                                            />

                                                            <button
                                                                type="button"
                                                               
                                                                className="optionCounterButton optionCounterButtonWithBorder"
                                                                onClick={onChildIncrease}
                                                            >
                                                                <span className="onclick_btn" id="adult_plus">
                                                                    +
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                   

                                                    {childAge.map((age, index) => (
                                                       
                                                        <div className="navbar agent">
                                                            <div>
                                                                <span>Child Age</span>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className="childAge_input"
                                                                    type="number"
                                                                    name="childAge[]"
                                                                    min="1"
                                                                    value={age}
                                                                    onChange={(event) =>
                                                                        handleChildrenAgeChange(index, event)
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </p>
                                </div> */}

                    <div className="">
                      <button type="submit" className="search-btn m_0">
                        SEARCH
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {destinationToggle && (
        <div
          onClick={() => setdestinationToggle(false)}
          className="ScreenDimmer"
        ></div>
      )}
    </>
  );
}

export default CustomSearchFormActivities;
