import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";

function Disclaimer() {
    return (
        <>
            <PageMetaData
                title="Disclaimer | Mountain Mysteries"
                description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
                keywords="hotel management, RevCatalyst, property optimization"
            />
            <Header />
            <ReviewReservationBanner heading="Disclaimer" />
            {/* <PageLinks links={["Home", "Disclaimer"]} /> */}
            <section className="pb_30">
                <div className="container">
                    <div className="overview-content mt-20">
                        <div>
                            <h4 className='mt-20 mb-10'>Interpretation and Definitions</h4>
                            <b className='mt-10 block'>Interpretation</b>
                            <p>The words of which the initial letter is capitalized have meanings defined under the following
                                conditions. The following definitions shall have the same meaning regardless of whether they appear
                                in singular or in plural.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Definitions</h4>
                            <p>For the purposes of this Privacy Policy:</p>
                            <li>Company referred to as either the Company, We, Us or Our in this Agreement refers to Mountain
                                Mysteries, Near Petrol Pump Kasumpti, 9, Vikas Nagar - SDA Complex Rd, Shimla, Himachal Pradesh
                                171002.</li>
                            <li>Goods refer to the items offered for sale on the Service.</li>
                            <li>Orders mean a request by You to purchase Goods from Us.</li>
                            <li>Service refers to the Website.</li>
                            <li>Website refers to Mountain Mysteries, accessible from <Link className="view_btn"
                                to="https://mountainmysteries.com/"> https://mountainmysteries.com/</Link></li>
                            <li>You means the individual accessing or using the Service, or the company, or other legal entity on
                                behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Disclaimer</h4>
                            <p>The information contained on the Service is for general information purposes only.</p>
                            <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
                            <p>In no event shall the Company be liable for any special, direct, indirect, consequential, or
                                incidental damages or any damages whatsoever, whether in an action of contract, negligence or other
                                tort, arising out of or in connection with the use of the Service or the contents of the Service.
                                The Company reserves the right to make additions, deletions, or modifications to the contents on the
                                Service at any time without prior notice.</p>
                            <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>External Links Disclaimer</h4>
                            <p>The Service may contain links to external websites that are not provided or maintained by or in any
                                way affiliated with the Company.</p>
                            <p>Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness
                                of any information on these external websites.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Errors and Omissions Disclaimer</h4>
                            <p>The information given by the Service is for general guidance on matters of interest only. Even if the
                                Company takes every precaution to insure that the content of the Service is both current and
                                accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there
                                may be delays, omissions or inaccuracies in the information contained on the Service.</p>
                            <p>The Company is not responsible for any errors or omissions, or for the results obtained from the use
                                of this information.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Fair Use Disclaimer</h4>
                            <p>The Company may use copyrighted material which has not always been specifically authorized by the
                                copyright owner. The Company is making such material available for criticism, comment, news
                                reporting, teaching, scholarship, or research.</p>
                            <p>The Company believes this constitutes a "fair use" of any such copyrighted material as provided for
                                in section 107 of the United States Copyright law.</p>
                            <p>If You wish to use copyrighted material from the Service for your own purposes that go beyond fair
                                use, You must obtain permission from the copyright owner.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Views Expressed Disclaimer</h4>
                            <p>The Service may contain views and opinions which are those of the authors and do not necessarily
                                reflect
                                the official policy or position of any other author, agency, organization, employer or company,
                                including the Company.</p>
                            <p>Comments published by users are their sole responsibility and the users will take full
                                responsibility,
                                liability and blame for any libel or litigation that results from something written in or as a
                                direct
                                result of something written in a comment. The Company is not liable for any comment published by
                                users
                                and reserve the right to delete any comment for any reason whatsoever.

                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>No Responsibility Disclaimer</h4>
                            <p>The information on the Service is provided with the understanding that the Company is not herein
                                engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it
                                should not be used as a substitute for consultation with professional accounting, tax, legal or
                                other competent advisers.</p>
                            <p>In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or
                                consequential damages whatsoever arising out of or in connection with your access or use or
                                inability to access or use the Service.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Use at Your Own Risk Disclaimer</h4>
                            <p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy,
                                timeliness or of the results obtained from the use of this information, and without warranty of any
                                kind, express or implied, including, but not limited to warranties of performance, merchantability
                                and fitness for a particular purpose.</p>
                            <p>The Company will not be liable to You or anyone else for any decision made or action taken in
                                reliance on the information given by the Service or for any consequential, special or similar
                                damages, even if advised of the possibility of such damages.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Contact Us</h4>
                            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                            <li>By visiting this page on our website: <Link className="view_btn"
                                to="/contact-us">
                                https://mountainmysteries.com/contact</Link></li>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Disclaimer;