import React, { useEffect, useRef, useState } from "react";
import "../CustomSlider/CustomSlider.css"
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import CustomViewRooms from "../CustomViewRooms/CustomViewRooms";
import NoDataFound from "../NoDataFound/NoDataFound";
import CustomSliderCard from "../CustomSliderCard/CustomSliderCard";
import Skeleton from "react-loading-skeleton";
import NewCustomViewRoom from "../CustomViewRooms/NewCustomViewRoom";

function NewCustomSlider({ children, onClose, data, setIsSliderVisible, selectedHotelId, selectedHotelPrice, updateHotelNameForSelectedDay, setCustomizableRatesLoader}) {

  // console.log(selectedHotelId, "selectedHotelId")
  const bookingDetailsPopupRef = useRef(null);
  const headerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsSliderVisible(true);
    document.body.style.overflow = "hidden";

    // Handle clicks outside the slider to close it
    const handleClickOutside = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        onClose();
        setCustomizableRatesLoader(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      setIsSliderVisible(false);
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsSliderVisible]);

  const [selectedRoom, setSelectedRoom] = useState(null);


  // console.log( "selectedRoom", selectedRoom);
  
  const handleViewRoomsClickHandler = async (room) => {
    // console.log("handleViewRoomsClickHandler", room);
    // console.log(room, "room");
    
    
    await fetchHotelData(room?.hotel_id);
    setSelectedRoom(room);
  };

  const handleClose = () => {
    onClose();
    setCustomizableRatesLoader(false);
  };

  const handleBackToList = () => {
    setSelectedRoom(null);
  };

  const [hotelData, setHotelData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [bookingData, setBookingData] = useState({});

  const fetchHotelData = async (id) => {
    // Assuming setLoader is a state setter for loading indicator

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // console.log(today.getDay() + 1, "getday")
    // console.log(today.getMonth(), "getday")

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const data = {
      hotelId: id,
      // location: "shimla",
      // room: 3,
      // adult: 1,
      // children: 1,
      // childAge: [7],
      // checkinDay: 28,
      // checkinMonth: 7,
      // checkinYear: 2024,
      // checkoutDay: 29,
      // checkoutMonth: 7,
      // checkoutYear: 2024,
      hotel_name: "Golden Fern Resort Shimla",
      hotel_address: "Kachi Ghatti, Shimla, Himachal Pradesh 171010",
    };

    setBookingData(data);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("hotelId", data.hotelId);
    // formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    // formdata.append("adults", data.adult);
    // formdata.append("children", data.children);
    formdata.append("checkIn", "2024-09-02");
    // formdata.append("checkOut", checkOutDate);
    // formdata.append("PropertyType", "hotel");

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 0; i < data.children; i++) {
        formdata.append(`childAge[${i}]`, data.childAge[i]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data); // Assuming setHotelData is a state setter for hotel data
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false); // Hide loader after request completion
    }
  };

  const handleScroll = () => {
    if (bookingDetailsPopupRef.current.scrollTop > 50) {
      headerRef.current.classList.add('is-fixed');
    } else {
      headerRef.current.classList.remove('is-fixed');
    }
  };

  useEffect(() => {
    const sliderContainer = bookingDetailsPopupRef.current;
    sliderContainer.addEventListener('scroll', handleScroll);

    return () => {
      sliderContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sortedData = [...data].sort((a, b) => (a.hotel_id === selectedHotelId ? -1 : 1));


  return (
    <>
      <div className="bg-backdrop">
        <div
          id="booking_details_slider_container_new"
          ref={bookingDetailsPopupRef}
        >
          <div className="popup" id="popup">
            <div className="d_flex booking_detail_popup_head fixed_header" ref={headerRef}>
              <div onClick={handleClose} className="close_popup">
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <div className="d_flex booking_id ">
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Change Hotel
                </p>
              </div>
            </div>
            {loader ? (
              <Loader size="4em" color="#016937" />
              // <Skeleton count={5} />
            ) : selectedRoom ? (
              // Render detailed view if a room is selected
              <NewCustomViewRoom
              updateHotelNameForSelectedDay={updateHotelNameForSelectedDay}
                rooms={hotelData || <Skeleton />}
                room={selectedRoom}
                handleBackToList={handleBackToList}
                bookingData={bookingData}
                onClose={onClose}
                selectedHotel={selectedRoom}
                setCustomizableRatesLoader={setCustomizableRatesLoader}
              />
            ) : (
              <div className="hotel_card_properties" style={{ margin: "20px" }}>
                <p
                  style={{ textTransform: "capitalize", fontSize: "14px" }}
                  className="text-left"
                >
                  Showing {data.length} Properties in{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {data[0].hotel_city}
                  </span>
                </p>
                {sortedData && sortedData.length > 0 ? (
                  sortedData.map((items) => (
                    <CustomSliderCard
                    updateHotelNameForSelectedDay={updateHotelNameForSelectedDay}
                      key={items.id}
                      items={items}
                      onViewRoomsClick={handleViewRoomsClickHandler}
                      selectedHotelId={selectedHotelId}
                      selectedHotelPrice={selectedHotelPrice}
                      handleClose={handleClose}
                      onClose={onClose}
                      selectedHotel={selectedRoom}
                      setCustomizableRatesLoader={setCustomizableRatesLoader}
                    />
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewCustomSlider;
