import React, { useEffect, useState } from "react";
import Header from "../../CustomComponents/Header/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Loader from "../../CustomComponents/Loader/Loader";
import Footer from "../../CustomComponents/Footer/Footer";
import { animateScroll as scroll } from "react-scroll";
import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import Slider from "react-slick";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../CustomComponents/SkeletonLoader/SkeletonLoader";
import imgNotFound from "../../assets/images/single-image.png";
import AllHotelsSearchForm from "../../CustomComponents/AllHotelsSearchForm/AllHotelsSearchForm";
import { currentDateSingle, formatIndianCurrency } from "../../utils/utils";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function SingleDestinationsData() {
  var settings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true,
          // arrows:true
        },
      },
      {
        breakpoint: 565,
        settings: {
          infinite: true,
          slidesToShow: 3,
          // arrows:true
        },
      },
      {
        breakpoint: 450,
        settings: {
          infinite: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          infinite: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  const { destination } = useParams();
  // console.log(destination, "destination");
  const location = useLocation();
  const [bookingData, setBookingData] = useState({});
  const [hotelData, setHotelData] = useState({});
  const navigate = useNavigate();
  const [singleDestinationData, setSingleDestinationData] = useState([]);

  const [loader, setLoader] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    hotelData &&
    hotelData &&
    hotelData.length > 0 &&
    hotelData.slice(startIndex, endIndex);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const data = {
  //     hotelID: params.get("hotelID"),
  //     location: params.get("location"),
  //     room: params.get("room"),
  //     adult: params.get("adult"),
  //     children: params.get("children"),
  //     checkinDay: params.get("checkinDay"),
  //     checkinMonth: params.get("checkinMonth"),
  //     checkinYear: params.get("checkinYear"),
  //     checkoutDay: params.get("checkoutDay"),
  //     checkoutMonth: params.get("checkoutMonth"),
  //     checkoutYear: params.get("checkoutYear"),
  //     radio: params.get("radio"),
  //     childAge: params.get("childAge"),
  //   };
  //   setBookingData(data);
  //   postBookingData(data);
  // }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const currentDate = currentDateSingle;

    const data = {
      // hotelID: params.get("hotelID"),
      location: destination,
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      checkinDay: new Date(currentDate).getDate(),
      checkinMonth: new Date(currentDate).getMonth() + 1,
      checkinYear: new Date(currentDate).getFullYear(),
      checkoutDay: new Date(currentDate).getDate() + 1,
      checkoutMonth: new Date(currentDate).getMonth() + 1,
      checkoutYear: new Date(currentDate).getFullYear(),
      radio: params.get("radio"),
      childAge: childAges, // Assign the array of child ages
    };
    // console.log(hotelData, "data hotel")
    setBookingData(data);
    postBookingData(data);
  }, [location.search, destination]);
  // console.log(items, "  data hotel")

  // console.log(bookingData, "bookingData");

  const postBookingData = async (data) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("location", destination);
    formdata.append("limit", 8);

    // formdata.append("checkIn", checkInDate);
    // formdata.append("checkOut", checkOutDate);
    // formdata.append("adults", data.adult);
    // formdata.append("child", data.children);
    // formdata.append("location", data.location);
    // formdata.append("rooms", data.room);
    // formdata.append("PropertyType", data?.radio ? data?.radio : "hotel");

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
      fetchDestinations(destination);
      fetchPackageData(destination);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // showErrorToast(error.message);
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const handleViewRoomsClickHandler = async (item) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    try {
      await navigate(
        `/single-hotel/${item?.hotel_slug}?hotelId=${item?.hotel_id}&location=${bookingData.location}&room=1&adult=1&children=0&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`,
        { state: { item: item } }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const fetchDestinations = async (destination) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/destinations/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const findImagesArray = result?.data.find(
          (item) => item?.name?.toLowerCase() === destination?.toLowerCase()
        );
        // console.log("findImagesArray", findImagesArray);
        // setDestinationsImagesArray(findImagesArray?.images)
        setSingleDestinationData(findImagesArray);
      })
      .catch((error) => console.error(error));
  };
  const [packageData, setPackageData] = useState([]);
  // console.log(packageData, "packageData");

  const fetchPackageData = async (destination) => {
    setLoader(true);
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    formdata.append("location", destination);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);

      const filteredData = result?.data?.filter(
        (item) => item.canBookable === 1
      );
      setPackageData(filteredData);
    } catch (error) {
      setPackageData([]);
      setLoader(false);
      console.error(error);
    }
  };

  // console.log("hotelData", hotelData);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div id="singleDestination">
      <PageMetaData
        title={`Properties/Packages In ${capitalizeFirstLetter(
          bookingData.location
        )} - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title={`Properties/Packages In ${capitalizeFirstLetter(
          bookingData.location
        )} - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/destination/${bookingData.location}`}
        // image={}
      />

      <Header />
      {/* <AllHotelsSearchForm searchType="single_destination" /> */}

      {loader ? (
        <div className="container">
          <div className="overview-content">
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="gallery">
                <div>
                  <Skeleton height={350} />
                </div>
                <div className="gallery2">
                  <div>
                    <Skeleton height={170} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={170} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={170} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={170} />
                  </div>
                </div>
              </div>
            ))}
            <div>
              <h3 style={{ textAlign: "left" }}>
                <Skeleton width={600} />
              </h3>

              <div className="destinationsCard mt-10">
                {Array.from({ length: 8 }).map((_, index) => (
                  <div className="card">
                    <div className="hotelCardImg">
                      <Skeleton height={170} />
                    </div>
                    <div className="hotel-content-flex hotel-main-card">
                      <div className="" style={{ background: "#fff" }}>
                        <Skeleton height={193} />
                      </div>

                      <div className="hotel-content" style={{ width: "100%" }}>
                        <div className="hotel_main border_0">
                          <h4>
                            <b>
                              <Skeleton width={100} />
                            </b>
                          </h4>
                          <div>
                            <Skeleton width={70} />
                          </div>
                          <small>
                            <Skeleton width={200} />
                          </small>
                          <div className=" flex justify_content_between w-full align_items_end">
                            <div>
                              <p>
                                <Skeleton width={100} />
                              </p>
                              <h5>
                                <Skeleton width={50} />
                              </h5>
                            </div>
                            <div className="view_room_btn">
                              <Skeleton />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <section>
            <div className="container">
              <div className="overview-content">
                <h4 className="mt-30">
                  <span
                    onClick={() => [
                      scroll.scrollToTop({
                        duration: 500,
                        smooth: "easeInOutQuad",
                      }),
                      navigate("/all-destinations"),
                    ]}
                    style={{
                      background: "#cccccc7e",
                      padding: "5px 10px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                        fill="#333333"
                      ></path>
                    </svg>
                  </span>{" "}
                  <b>{singleDestinationData?.name}</b>
                </h4>
                {singleDestinationData &&
                  singleDestinationData?.images?.length > 0 && (
                    <div className="gallery">
                      <div>
                        <img
                          src={singleDestinationData?.images[0]}
                          alt="hotel-image"
                        />
                        {singleDestinationData?.images?.length > 1 && (
                          <div>
                            <button
                              className="images_btn"
                              onClick={() => {
                                Fancybox.show(
                                  singleDestinationData?.images?.map((src) => ({
                                    src,
                                    type: "image",
                                  }))
                                );
                              }}
                            >
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 16 15"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z" />
                              </svg>
                              See all images
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="gallery2">
                        <div>
                          <img
                            src={
                              singleDestinationData?.images[1]
                                ? singleDestinationData?.images[1]
                                : singleDestinationData?.images[0]
                            }
                            alt="hotel-image"
                          />
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          <img
                            src={
                              singleDestinationData?.images[2]
                                ? singleDestinationData?.images[2]
                                : singleDestinationData?.images[0]
                            }
                            alt="hotel-image"
                          />
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          <img
                            src={
                              singleDestinationData?.images[3]
                                ? singleDestinationData?.images[3]
                                : singleDestinationData?.images[0]
                            }
                            alt="hotel-image"
                          />
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          <img
                            src={
                              singleDestinationData?.images[4]
                                ? singleDestinationData?.images[4]
                                : singleDestinationData?.images[0]
                            }
                            alt="hotel-image"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                <p className="font_12 pb_20">
                  {singleDestinationData?.description}
                </p>
              </div>
            </div>
          </section>

          {hotelData && hotelData?.length > 0 && (
            <section className="properties_section">
              <div className="container">
                <div>
                  <h3
                    style={{ textTransform: "capitalize" }}
                    className="text-left"
                  >
                    Showing Featured Properties In {bookingData.location}
                  </h3>
                  <div className="destinationsCard mt-10">
                    {hotelData && hotelData?.length > 0 ? (
                      displayedData?.slice(0, 8).map((items) => {
                        return (
                          <div className="card">
                            <div className="hotelCardImg">
                              <img
                                onClick={() =>
                                  handleViewRoomsClickHandler(items)
                                }
                                src={
                                  items.hotel_image[0]
                                    ? items.hotel_image[0]
                                    : imgNotFound
                                }
                                className="cursor_pointer"
                                alt="hotel-img"
                              />

                              {items.hotel_image.length > 1 && (
                                <div>
                                  <button
                                    className="view_images_btn"
                                    onClick={() => {
                                      Fancybox.show(
                                        items.hotel_image.map((src) => ({
                                          src,
                                          type: "image",
                                        }))
                                      );
                                    }}
                                  >
                                    <svg
                                      width="10"
                                      height="10"
                                      viewBox="0 0 16 15"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z"
                                        fill="white"
                                      />
                                    </svg>
                                    View all images
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="card-content p_10">
                              <div>
                                {" "}
                                <h6   onClick={() =>
                                  handleViewRoomsClickHandler(items)
                                }
                                className="cursor_pointer" style={{ fontSize: "13px" }}>
                                  <b>{items.hotel_name}</b>
                                </h6>
                                <div className="">
                                  {
                                    <StarRating
                                      value={
                                        items.hotel_rating
                                          ? items.hotel_rating
                                          : 0
                                      }
                                    />
                                  }
                                </div>
                                <small className="location_text mb_5">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                      fill="#888888"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                      fill="#888888"
                                    />
                                  </svg>
                                  {items.hotel_address}
                                </small>
                              </div>
                              <div className=" flex justify_content_between w-full align_items_end">
                                <div>
                                  <p>Starting From</p>
                                  <h5>
                                    {formatIndianCurrency(items.starting_price)}{" "}
                                    <small> + GST</small>
                                  </h5>
                                </div>
                                <div className="view_room_btn">
                                  <button
                                    onClick={() =>
                                      handleViewRoomsClickHandler(items)
                                    }
                                    className="primary_btn"
                                    type="submit"
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}

          {packageData?.length > 0 && (
            <section>
              <div className="container pb_30">
                <h3
                  className="mt-40 text-left"
                  style={{ textTransform: "capitalize" }}
                >
                  Showing Featured packages In {bookingData.location}
                </h3>
                <div className="line" style={{ marginTop: 0 }}></div>

                <Slider {...settings} className="mt-10">
                  {packageData?.map((data, index) => (
                    <CustomListingCard
                      key={index}
                      imgSrc={
                        data.packageImages[0]
                          ? data.packageImages[0]
                          : data.packageDestination[0].images
                      }
                      title={data.packageName}
                      packageHub={data.packageHub}
                      duration={data.totalDays}
                      nightDuration={data.totalNights}
                      price={data.startingPrice}
                      bookingLink={data.bookingLink}
                      detailsLink={data.detailsLink}
                      packagehandle={data.packagehandle}
                      canBookable={data?.canBookable}
                    />
                  ))}
                </Slider>
              </div>
            </section>
          )}
        </>
      )}

      <Footer />
    </div>
  );
}

export default SingleDestinationsData;
