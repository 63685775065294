import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import Loader from "../../CustomComponents/Loader/Loader";
import SingleHotelCard from "../../CustomComponents/SingleHotelCard/SingleHotelCard";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import "./SingleHotel.css";
import Skeleton from "react-loading-skeleton";
import SimilarHotels from "../../CustomComponents/SimilarHotels/SimilarHotels";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function SingleHotel() {
  const location = useLocation();
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState({});
  const [hotelData, setHotelData] = useState({});

  const [loader, setLoader] = useState(true);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const agentDiscount = userDetails?.agent_category_discount || 0;
  const agentDiscountType = userDetails?.agent_discount_type || "percentage";

  // console.log(agentDiscount);

  // const { item: currentHotel } = location?.state || {};
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 9;

  Fancybox.bind("[data-fancybox]", {
    // Fancybox options if any
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      const params = new URLSearchParams(location.search);

      // if (
      //   !params.has("hotelId") ||
      //   params.get("hotelId") === "" ||
      //   !params.has("location") ||
      //   params.get("location") === "" ||
      //   !params.has("room") ||
      //   params.get("room") === "" ||
      //   !params.has("adult") ||
      //   params.get("adult") === "" ||
      //   !params.has("checkinDay") ||
      //   params.get("checkinDay") === "" ||
      //   !params.has("checkinMonth") ||
      //   params.get("checkinMonth") === "" ||
      //   !params.has("checkinYear") ||
      //   params.get("checkinYear") === "" ||
      //   !params.has("checkoutDay") ||
      //   params.get("checkoutDay") === "" ||
      //   !params.has("checkoutMonth") ||
      //   params.get("checkoutMonth") === "" ||
      //   !params.has("checkoutYear") ||
      //   params.get("checkoutYear") === ""
      // ) {
      //   navigate("/");
      //   return;
      // }

      // Get the childAge parameters
      const childAges = [];
      let i = 1;
      while (params.has(`childAge[${i}]`)) {
        childAges.push(params.get(`childAge[${i}]`));
        i++;
      }

      const data = {
        hotelId: params.get("hotelId"),
        location: params.get("location"),
        room: params.get("room"),
        adult: params.get("adult"),
        children: params.get("children"),
        checkinDay: params.get("checkinDay"),
        checkinMonth: params.get("checkinMonth"),
        checkinYear: params.get("checkinYear"),
        checkoutDay: params.get("checkoutDay"),
        checkoutMonth: params.get("checkoutMonth"),
        checkoutYear: params.get("checkoutYear"),
        radio: params.get("radio"),
        hotel_name: params.get("hotel_name"),
        hotel_address: params.get("hotel_address"),
        childAge: childAges,
      };

      setBookingData(data);
      postBookingData(data);
    },
    [location.search],
    navigate
  );

  const postBookingData = async (data) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("checkIn", checkInDate);
    formdata.append("checkOut", checkOutDate);
    formdata.append("adults", data.adult);
    formdata.append("child", data.children);
    formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    formdata.append("PropertyType", "hotel");

    formdata.append("hotelId", data.hotelId);

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error state or retry logic if needed
    } finally {
      setLoader(false);
    }
  };

  //  calculations

  // const room_rate = bookingData.
  // console.log(bookingData, "hotelData");
  return (
    <>
      <PageMetaData
        title={`${
          hotelData?.hotelInfo?.name &&
          hotelData?.hotelInfo?.name !== "" &&
          hotelData?.hotelInfo?.name !== undefined &&
          hotelData?.hotelInfo?.name
        } - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />

      <OgMetaTitles
        title={`${
          hotelData?.hotelInfo?.name &&
          hotelData?.hotelInfo?.name !== "" &&
          hotelData?.hotelInfo?.name !== undefined &&
          hotelData?.hotelInfo?.name
        } - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/single-hotel/${bookingData?.hotelId}?hotelId=${bookingData?.hotelId}&location=$${bookingData?.location}&room=${bookingData?.room}&adult=${bookingData?.adult}&children=0&&checkinDay=5&checkinMonth=10&checkinYear=2024&checkoutDay=6&checkoutMonth=10&checkoutYear=2024&hotel_name=Shimla%20Havens%20Resort&hotel_address=Shimla`}
      />

      <Header />
      <SingleSearchForm searchType="hotel_listing" singleHotelPage={true} />
      {/* <PageLinks links={["Home", `Properties In ${bookingData.location}`, `${bookingData.hotel_name}`]} /> */}
      {loader ? (
        <div className="container">
          <div className="overview-content loading-filter-main">
            {Array.from({ length: 1 }).map(
              (_, index) =>
                +(
                  <div className="gallery">
                    <div>
                      <Skeleton height={350} />
                    </div>
                    <div className="gallery2">
                      <div>
                        <Skeleton height={170} />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <Skeleton height={170} />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <Skeleton height={170} />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <Skeleton height={170} />
                      </div>
                    </div>
                  </div>
                )
            )}
            <h4 className="SingleHotel-Name">
              <b>
                <Skeleton width={250} />
              </b>
            </h4>
            <small>
              <Skeleton width={180} />
            </small>
            <p className="SingleHotel-description mb-20">
              <Skeleton width={900} />
              <Skeleton width={700} />
              <Skeleton width={500} />
            </p>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <div className="single-room-card">
                  <div
                    className="card_head flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="agent">
                      <h4>
                        <Skeleton width={400} />
                      </h4>
                      <div className="flex flex_gap_10">
                        <Skeleton />
                      </div>
                    </div>
                    <div>
                      <Skeleton width={200} />
                    </div>
                  </div>

                  <div className="single_room_data_">
                    <div className="hotel_view_img">
                      <Skeleton height={185} />
                    </div>

                    <div className="rooms_content">
                      {Array.from({ length: 3 }).map((_, index) => {
                        return (
                          <div
                            className="single_room_details"
                            style={{ padding: "6px 18px" }}
                          >
                            <div>
                              <p>
                                <small>
                                  <Skeleton width={200} />
                                </small>
                              </p>
                              <p>
                                <small>
                                  <Skeleton width={200} />
                                </small>
                              </p>
                            </div>
                            <div className="single_room_price">
                              <div>
                                <h4>
                                  <Skeleton width={100} />
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="loading-filter-responsive">
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="gallery">
                <div>
                  <Skeleton height={150} />
                </div>
                <div className="gallery2">
                  <div>
                    <Skeleton height={70} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={70} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={70} />
                  </div>
                  <div className="SingleHotel-gallary-sm">
                    <Skeleton height={70} />
                  </div>
                </div>
              </div>
            ))}
            <h4 className="SingleHotel-Name">
              <b>
                <Skeleton width={250} />
              </b>
            </h4>
            <small>
              <Skeleton width={180} />
            </small>
            <p className="SingleHotel-description mb-20">
              <Skeleton width={900} />
              <Skeleton width={700} />
              <Skeleton width={500} />
              <Skeleton width={300} />
              <Skeleton width={100} />
            </p>
          </div>
        </div>
      ) : (
        <section style={{ marginBottom: "50px" }}>
          <div className="container">
            <div className="overview-content">
              {hotelData &&
                hotelData.hotelImages &&
                hotelData.hotelImages.length > 0 && (
                  <div className="gallery">
                    <div>
                      <img src={hotelData.hotelImages[0]} alt="hotel-image" />
                      {hotelData?.hotelImages?.length > 1 && (
                        <div>
                          <button
                            className="images_btn"
                            onClick={() => {
                              Fancybox.show(
                                hotelData?.hotelImages?.map((src) => ({
                                  src,
                                  type: "image",
                                }))
                              );
                            }}
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 16 15"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z" />
                            </svg>
                            See all images
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="gallery2">
                      <div>
                        <img
                          src={
                            hotelData.hotelImages[1]
                              ? hotelData.hotelImages[1]
                              : hotelData.hotelImages[0]
                          }
                          alt="hotel-image"
                        />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <img
                          src={
                            hotelData.hotelImages[2]
                              ? hotelData.hotelImages[2]
                              : hotelData.hotelImages[0]
                          }
                          alt="hotel-image"
                        />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <img
                          src={
                            hotelData.hotelImages[3]
                              ? hotelData.hotelImages[3]
                              : hotelData.hotelImages[0]
                          }
                          alt="hotel-image"
                        />
                      </div>
                      <div className="SingleHotel-gallary-sm">
                        <img
                          src={
                            hotelData.hotelImages[4]
                              ? hotelData.hotelImages[4]
                              : hotelData.hotelImages[0]
                          }
                          alt="hotel-image"
                        />
                      </div>
                    </div>
                  </div>
                )}
              <h4 className="SingleHotel-Name">
                <b>{hotelData?.hotelInfo?.name}</b>
              </h4>
              <small>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                    fill="#888888"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                    fill="#888888"
                  />
                </svg>
                {hotelData?.hotelInfo?.address}
              </small>
              <p className="font_12">{hotelData?.hotelInfo?.details}</p>
              <SingleHotelCard
                rooms={hotelData}
                bookingData={bookingData}
                agentDiscount={agentDiscount}
              />
            </div>
          </div>
        </section>
      )}
      {/* <SimilarHotels filterId={bookingData?.hotelId} /> */}
      <Footer />
    </>
  );
}

export default SingleHotel;
