import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast } from "../../assets/toastUtils";
import { animateScroll as scroll } from "react-scroll";
import {
  calculateNumberOfNights,
  formatDate,
  formatIndianCurrency,
  textDateFormatter,
  textDateFormatterNoAdd,
} from "../../utils/utils";
import Footer from "../../CustomComponents/Footer/Footer";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import Header from "../../CustomComponents/Header/Header";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import "./CheckOutHotelNew.css";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";
// import LoadAtomCheckoutScript from "../../CustomComponents/PaymentGateway/LoadAtomCheckoutScript";

function CheckOutHotelNew() {
  const location = useLocation();
  const {
    item: selectedPlan,
    hotelData,
    ratesData,
    bookingData,
    room_id,
  } = location?.state || {};

  // console.log("ratesData", ratesData);

  // console.log(ratesData, "ratesData");

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userType = localStorage.getItem("userType");

  const [propertyDiscountArray, setPropertyDiscountArray] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState({});

  // console.log(selectedDiscount, "selectedDiscount");

  // console.log(userDetails, "userdetails");
  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const [payWithAgentCredit, setPayWithAgentCredit] = useState(false);
  const [payWithAdvance, setPayWithAdvance] = useState(false);

  // console.log(addressData, "addressdaat");

  const handleAddressData = (data) => {
    // console.log(data, "data");
    setAddressData(data);
  };

  const [filteredData, setFilteredData] = useState(null);
  const [noOfNights, setNoOfNights] = useState("");

  // console.log(filteredData, "filterdaatts");

  useEffect(() => {
    const filtered = ratesData?.rates.find((item) => item.room_id === room_id);
    setFilteredData(filtered);
  }, []);
  const [checkInDate, setCheckInDate] = useState("");

  const [checkOutDate, setCheckOutDate] = useState("");
  // console.log("checkInDate", checkInDate);
  // console.log("checkOutDate", checkOutDate);
  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
    agentAdvanceCalculations: 0,
  });

  // console.log(bookingValues, "bookingValues");

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Set the script attributes
    script.src = "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";
    script.async = true;

    // Event listener to check if the script has loaded
    script.onload = () => {
      // console.log("AtomCheckout script loaded successfully.");
    };

    // Event listener to check if there was an error loading the script
    script.onerror = () => {
      console.error("Error loading the AtomCheckout script.");
    };

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const filtered = ratesData?.rates?.find((item) => item.room_id === room_id);
    setFilteredData(filtered);
  }, [ratesData?.rates]);

  const [booking, setBoooking] = useState(false);
  const handleOnBooking = () => {
    setBoooking(!booking);
  };
  const closePopup = () => {
    setBoooking(false);
  };

  // console.log(bookingValues, "bookingValues");

  const calculateBookingValues = (
    roomRate,
    bookingDiscount,
    bookingDiscountType,
    agentDiscount,
    agentDiscountType,
    childPricesArray,
    noOfNights,
    noOfRooms,
    agentAdvanceCalculations
  ) => {
    let roomRateParsed = parseFloat(roomRate) * +noOfNights * +noOfRooms;
    if (isNaN(roomRateParsed)) {
      roomRateParsed = 0;
    }

    let taxSlab = +roomRate > 7500 ? 18 : 12;
    // console.log(roomRateParsed, "tax");
    // console.log(taxSlab, "tax");

    let bookingDiscountAmount = 0;
    if (bookingDiscountType === "percentage") {
      bookingDiscountAmount = (roomRateParsed * bookingDiscount) / 100;
    } else {
      bookingDiscountAmount = parseFloat(bookingDiscount);
      if (isNaN(bookingDiscountAmount)) {
        bookingDiscountAmount = 0;
      }
    }

    let agentDiscountAmount = 0;
    if (agentDiscountType === "percentage") {
      agentDiscountAmount =
        ((roomRateParsed - bookingDiscountAmount) * agentDiscount) / 100;
    } else {
      agentDiscountAmount = parseFloat(agentDiscount);
      if (isNaN(agentDiscountAmount)) {
        agentDiscountAmount = 0;
      }
    }

    let totalDiscountAmount = bookingDiscountAmount + agentDiscountAmount;

    let childPrices = childPricesArray.reduce((accumulator, currentPrice) => {
      const parsedPrice = parseFloat(currentPrice);
      return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
    }, 0);

    const subtotal =
      roomRateParsed - totalDiscountAmount + +childPrices * +noOfNights;
    const taxAmount = (subtotal * taxSlab) / 100;
    let grandTotal = subtotal + taxAmount;

    const grandTotalAfterAgentAdvance = grandTotal;

    // if(agentAdvanceCalculations?.pay_with_advance){
    //   grandTotal -= agentAdvanceCalculations?.advanceLimit;
    // }

    // if(agentAdvanceCalculations?.pay_with_company_credit){
    //   grandTotal -= agentAdvanceCalculations?.credit_balance;
    // }

    return {
      taxSlab,
      subtotal,
      taxAmount,
      grandTotal: Math.max(grandTotal, 0),
      discountAmt: totalDiscountAmount,
      totalChildAdultCost: childPrices * +noOfNights,
      childPrices,
      bookingDiscount,
      agentDiscount,
      grandTotalAfterAgentAdvance,
    };
  };

  const calculateCreditAndBalance = (
    name,
    hotelId,
    creditChecked,
    creditLimit,
    advanceChecked,
    advanceLimit,
    grandTotal,
    checkIn,
    checkOut
  ) => {
    let responseData = {
      pay_with_company_credit: creditChecked,
      pay_with_advance: advanceChecked,
      remaining_credit_balance: creditLimit,
      remaining_advance_balance: advanceLimit,
      credit_balance: creditLimit,
      advanceLimit: advanceLimit,
      checkIn: checkIn,
      checkOut: checkOut,
      grandTotal: grandTotal,
      paidAmount: 0,
      hotelId: hotelId,
      customerName: name,
      roomDetails: [],
    };

    if (creditChecked && !advanceChecked) {
      if (creditLimit > grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_credit_balance = creditLimit - grandTotal;
        responseData.paidAmount = grandTotal;
      } else {
        responseData.balanceAmount = grandTotal - creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;
      }
      return responseData;
    }

    if (!creditChecked && advanceChecked) {
      if (advanceLimit > grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_advance_balance = advanceLimit - grandTotal;
        responseData.paidAmount = grandTotal;
      } else {
        responseData.balanceAmount = grandTotal - advanceLimit;
        responseData.remaining_advance_balance = 0;
        responseData.paidAmount = advanceLimit;
      }
      return responseData;
    }

    if (creditChecked && advanceChecked) {
      let remainingAmount = grandTotal;

      if (creditLimit >= remainingAmount) {
        responseData.paidAmount = remainingAmount;
        responseData.remaining_credit_balance = creditLimit - remainingAmount;
        responseData.remaining_advance_balance = advanceLimit;
        responseData.balanceAmount = 0;
      } else {
        remainingAmount -= creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;

        if (advanceLimit >= remainingAmount) {
          responseData.paidAmount += remainingAmount;
          responseData.remaining_advance_balance =
            advanceLimit - remainingAmount;
          responseData.balanceAmount = 0;
        } else {
          responseData.paidAmount += advanceLimit;
          responseData.remaining_advance_balance = 0;
          responseData.balanceAmount = remainingAmount - advanceLimit;
        }
      }
      return responseData;
    }
    if (!creditChecked && !advanceChecked) {
      return responseData;
    }
  };

  const agentAdvanceCalculations = calculateCreditAndBalance(
    "",
    null,
    payWithAgentCredit, /// creditChecked
    userDetails?.agent_credit?.credit_amount,
    payWithAdvance, ///advanceChecked
    userDetails?.agent_advance_payment?.balance_amount,
    bookingValues.grandTotal,
    null,
    null
  );

  useEffect(() => {
    if (filteredData) {
      const roomRate = selectedPlan.rate_per_night;
      const bookingDiscount = selectedDiscount?.amount; // replace with actual value
      const bookingDiscountType = selectedDiscount?.type; // replace with actual value
      const agentDiscount = userDetails?.agent_category_discount || 0;
      const agentDiscountType =
        userDetails?.agent_discount_type || "percentage";
      // console.log(agentDiscountType, agentDiscount, "booking values");

      const calculatedValues = calculateBookingValues(
        +selectedPlan.rate_per_night,
        +bookingDiscount,
        bookingDiscountType,
        +agentDiscount,
        agentDiscountType,
        selectedPlan.child_price,
        +noOfNights,
        +bookingData.room,
        agentAdvanceCalculations
      );

      setBookingValues(calculatedValues);
    }
  }, [
    filteredData,
    userDetails?.agent_category_discount,
    agentAdvanceCalculations?.pay_with_advance,
    agentAdvanceCalculations?.pay_with_company_credit,
    selectedDiscount?.amount,
    selectedDiscount?.type,
  ]);
  // console.log(bookingValues, "booking values");

  const [loader, setLoader] = useState(false);
  const [guestData, setGuestData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    pincode: "",
    guest_city: "",
    guest_address: "",
    gst_number: "",
    company_number: "",
    admin_email: "",
    business_email: "",
    company_address: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuestData({
      ...guestData,
      [name]: value,
    });
  };
  const handleSubmitAddress = (e) => {
    e.preventDefault();
    // Here you can send the formData to a server or save it somewhere
    // console.log("Form data submitted:", guestData);
  };

  // console.log(guestData, "guestData");

  const guestDataHandleChange = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    setGuestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // console.log(hotelData, "ratesdata");
  // console.log(selectedPlan, "selectedPlan");

  const navigate = useNavigate();

  useEffect(() => {
    if (!bookingData || !selectedPlan || !hotelData || !ratesData) {
      navigate("/");
    }

    // console.log(bookingData);

    const calculateNoOfNights = () => {
      if (!bookingData.checkinYear) navigate("/");
      const checkin = new Date(
        bookingData.checkinYear,
        bookingData.checkinMonth - 1,
        bookingData.checkinDay
      );
      const checkout = new Date(
        bookingData.checkoutYear,
        bookingData.checkoutMonth - 1,
        bookingData.checkoutDay
      );
      const diffTime = Math.abs(checkout - checkin);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setNoOfNights(diffDays);
    };

    const formatDate = (year, month, day) => {
      return `${year}-${month}-${day}`;
    };

    if (bookingData) {
      calculateNoOfNights();
      setCheckInDate(
        formatDate(
          bookingData.checkinYear,
          bookingData.checkinMonth,
          bookingData.checkinDay
        )
      );
      setCheckOutDate(
        formatDate(
          bookingData.checkoutYear,
          bookingData.checkoutMonth,
          bookingData.checkoutDay
        )
      );
    }
  }, [bookingData, navigate]);

  // console.log(checkinDate);

  // ...................................country State selector...............................
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const [countryInputValue, setCountryInputValue] = useState("");
  const [stateInputValue, setStateInputValue] = useState("");

  const [filteredCountrySuggestion, setFilteredCountrySuggestions] = useState(
    []
  );
  const [filteredStateSuggestion, setFilteredStateSuggestion] = useState([]);

  const [clickedCountries, setClickedCountries] = useState(null);
  const [clickedState, setClickedState] = useState(null);

  const [countrySuggestionPopup, setCountrySuggestionPopup] = useState(false);
  const [stateSuggestionPopup, setStateSuggestionPopup] = useState(false);

  const [editable, setEditable] = useState(true);
  const [editableState, setEditableState] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasStateChanged, setHasStateChanged] = useState(false);

  // console.log(bookingData, "bookingData");

  const [propertyInfo, setPropertyInfo] = useState(false);
  const [guestDetails, setGuestDetails] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(false);
  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };
  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };
  const togglePropertyDetails = () => {
    setPropertyInfo(!propertyInfo);
  };
  const toggleGuestDetails = () => {
    setGuestDetails(!guestDetails);
  };
  const toggleBusinessProfile = () => {
    setBusinessProfile(!businessProfile);
  };

  const handleKeyDown = (event) => {
    if (
      !editable &&
      !hasChanged &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault(); // Prevent typing when not editable and no changes made
    } else if (
      !editable &&
      (event.key === "Backspace" || event.key === "Delete")
    ) {
      setEditable(true);
      setCountryInputValue(""); // Re-enable editing when backspace or delete key is pressed
    }
  };

  const handleStateKeyDown = (event) => {
    if (
      !editableState &&
      !hasStateChanged &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault(); // Prevent typing when not editable and no changes made
    } else if (
      !editableState &&
      (event.key === "Backspace" || event.key === "Delete")
    ) {
      setEditableState(true); // Re-enable editing when backspace or delete key is pressed
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://live.revcatalyst.in/api/v1/country/list"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const responseData = await response.json();
        setCountryList(responseData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryInputChange = (event) => {
    const value = event.target.value;
    setCountryInputValue(value);
    setHasChanged(true);
    // setCountrySuggestionPopup(!countrySuggestionPopup);
    const filteredCountries = countryList
      .map((country) => ({
        id: country.id,
        name: country.name,
      }))
      .filter((countrySuggestion) =>
        countrySuggestion.name.toLowerCase().includes(value.toLowerCase())
      );

    setFilteredCountrySuggestions(filteredCountries);
    if (event.target.value.trim() !== "") {
      setCountrySuggestionPopup(true);
    } else {
      setCountrySuggestionPopup(false);
    }
  };

  const handleCountrySuggestionClick = (suggestion, id) => {
    setCountryInputValue(suggestion);
    setClickedCountries(suggestion);
    setFilteredCountrySuggestions([]);
    setEditable(false);
    setHasChanged(false);
    try {
      fetch(`https://live.revcatalyst.in/api/v1/country/states/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setStateList(data.data);
          } else {
            showErrorToast(data.message);
          }
        });
    } catch (error) {}
  };

  const handleStateInputChange = (event) => {
    const value = event.target.value;
    setStateInputValue(value);
    setHasStateChanged(true);

    const filteredStates = stateList
      .map((state) => ({
        id: state.id,
        name: state.name,
      }))
      .filter((stateSuggestion) =>
        stateSuggestion.name.toLowerCase().includes(value.toLowerCase())
      );
    setFilteredStateSuggestion(filteredStates);
    if (event.target.value.trim() !== "") {
      setStateSuggestionPopup(true);
    } else {
      setStateSuggestionPopup(false);
    }
    // setHasChanged(true);
  };

  const handleStateSuggestionClick = (suggestion, id) => {
    setStateInputValue(suggestion);
    setClickedState(suggestion);
    setFilteredStateSuggestion([]);
    setEditableState(false);
    setHasStateChanged(false);
  };

  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setCountrySuggestionPopup(false);
        setStateSuggestionPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const postBookingData = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("checkIn", checkInDate);
    formdata.append("checkOut", checkOutDate);
    formdata.append("adults", data.adult);
    formdata.append("child", data.children);
    formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    formdata.append("PropertyType", "hotel");
    formdata.append("hotelId", data.hotelId);

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      //   setHotelData(result.data); // Log or handle the parsed JSON response
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error state or retry logic if needed
    }
  };

  // console.log(userDetails);

  const [formData, setFormData] = useState({
    amount: bookingValues.grandTotal,
    guest_name: guestData.guest_name,
    guest_phone: guestData.guest_phone,
    guest_email: guestData.guest_email,
    pincode: guestData.pincode,
    guest_state: stateInputValue,
    guest_country: countryInputValue,
    guest_city: guestData.guest_city,
    guest_address: guestData.guest_address,
    visitDate: checkInDate,
  });

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }

    setFormErrors({});

    setLoader(true);

    try {
      const formatedDate = new Date(checkInDate).toISOString().split("T")[0];

      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
   myHeaders.append("key", process.env.REACT_APP_API_KEY);

      const rateData = ratesData?.rates;

      const bookingPaxes = rateData.map((rate) => {
        let paxes = [];

        const childPrices = rate.child_price || [];
        if (Array.isArray(childPrices)) {
          childPrices.forEach((price) => {
            const is_complimentary = price === "0.00" ? 1 : 0;
            paxes.push({
              pax_type: "child",
              no_of_pax: 1,
              rate: price,
              child_age: bookingData.childAge[0] || 0,
              is_complimentary: is_complimentary,
            });
          });
        }

        for (let i = 0; i < rate.extra_adult_count; i++) {
          paxes.push({
            pax_type: "adult",
            no_of_pax: 1,
            rate: rate.extra_adult,
            child_age: bookingData.childAge[0] || 0,
            is_complimentary: 0,
          });
        }

        return paxes;
      });

      const raw = JSON.stringify({
        pay_with_agent_credit:
          agentAdvanceCalculations?.pay_with_company_credit,
        pay_with_advance: agentAdvanceCalculations?.pay_with_advance,
        remaining_credit_balance:
          agentAdvanceCalculations?.remaining_credit_balance,
        remaining_advance_balance:
          agentAdvanceCalculations?.remaining_advance_balance,
        hotelId: bookingData?.hotelId,
        agent_id: userDetails?.AgentId,
        customer_id: userDetails?.CustomerId,
        check_in: checkInDate,
        check_out: checkOutDate,
        guest_name: guestData.guest_name,
        guest_phone: guestData.guest_phone,
        guest_email: guestData.guest_email,
        pincode: guestData.pincode,
        guest_state: addressData.state,
        guest_country: addressData.country,
        guest_city: addressData.city,
        guest_address: addressData.address,
        gst_type: "exclusive",
        sub_total: bookingValues.subtotal,
        grand_total: bookingValues.grandTotal,
        paid_amount: agentAdvanceCalculations?.paidAmount,
        balance_amount:
          agentAdvanceCalculations?.balanceAmount || bookingValues.grandTotal,
        total_tax_amount: bookingValues.taxAmount,
        total_discount_amount: bookingValues.discountAmt,
        company_number: guestData?.company_number,
        company_email: guestData.business_email,
        gst_number: guestData?.gst_number,
        roomInputData: [
          {
            room_id: selectedPlan?.room_id,
            room_name: ratesData?.room_name,
            rate_plan_id: selectedPlan?.rate_plan_id,
            room_plan: selectedPlan?.meal_plan,
            no_of_rooms: bookingData?.room,
            no_nights: noOfNights,
            room_rate: selectedPlan?.rate_per_night,
            no_adult: bookingData?.adult,
            no_child: bookingData.children,
            discount_rate: bookingValues.bookingDiscount || 0,
            tac_rate: bookingValues.agentDiscount,
            discount_amount: bookingValues.discountAmt,
            tax_rate: bookingValues?.taxSlab,
            tax_amount: bookingValues.taxAmount,
            tax_type: "exclusive",
            room_total_amount: selectedPlan?.rate_per_night,
            room_grand_total: bookingValues.grandTotal,
            check_in: checkInDate,
            check_out: checkOutDate,
            booking_paxs: bookingPaxes.length > 0 ? bookingPaxes[0] : [],
          },
        ],
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/online/booking/checkout`,
        requestOptions
      );

      const data = await response.json();
      if (data.success) {
        setLoader(false);
        setTicketData(data?.booking);

        if (
          data.razorpayOrderId ||
          data.booking.id ||
          data.booking.property_id
        ) {
          try {
            const checkoutUrl = `${process.env.REACT_APP_APP_URL}/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=http://www.mountainmysteries.com/thank-you/`;
            // navigate(checkoutUrl);
            window.location.href = checkoutUrl;

            // if (checkoutData.success) {
            // } else {
            //   showErrorToast(checkoutData.message || "Checkout failed");
            // }
          } catch (error) {
            console.error("Checkout error:", error);
            showErrorToast("An error occurred during the checkout process.");
          }
        } else {
          showErrorToast(
            "Something went wrong! Please check your details and try again."
          );
        }
        if (
          data.razorpayOrderId ||
          data.booking.id ||
          data.booking.property_id
        ) {
          try {
            const checkoutUrl = `${process.env.REACT_APP_APP_URL}/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.mountainmysteries.com/thank-you/`;

            const checkoutResponse = await fetch(checkoutUrl, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            });

            const checkoutData = await checkoutResponse.json();

            if (checkoutData.success) {
              window.location.href = "https://www.ecohospitality.in/thankyou/";
            } else {
              showErrorToast(checkoutData.message || "Checkout failed");
            }
          } catch (error) {
            console.error("Checkout error:", error);
            showErrorToast("An error occurred during the checkout process.");
          }
        } else {
          showErrorToast(
            "Something went wrong! Please check your details and try again."
          );
        }
        // if (data.razorpayOrderId || data.booking.id || data.booking.property_id) {
        //   try {
        //     const checkoutUrl = `https://extranet.travcatalyst.com/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.ecohospitality.in/thankyou/`;

        //     const checkoutResponse = await fetch(checkoutUrl, {
        //       method: "GET",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //       },
        //     });

        //     const checkoutData = await checkoutResponse.json();

        //     if (checkoutData.success) {
        //       window.location.href = "https://www.ecohospitality.in/thankyou/";
        //     } else {
        //       showErrorToast(checkoutData.message || "Checkout failed");
        //     }
        //   } catch (error) {
        //     console.error("Checkout error:", error);
        //     showErrorToast("An error occurred during the checkout process.");
        //   }
        // } else {
        //   showErrorToast("Something went wrong! Please check your details and try again.");
        // }
        // if (data.razorpayOrderId || data.booking.id || data.booking.property_id) {
        //   try {
        //     const checkoutUrl = `https://extranet.travcatalyst.com/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.ecohospitality.in/thankyou/`;

        //     const checkoutResponse = await fetch(checkoutUrl, {
        //       method: "GET",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //       },
        //     });

        //     const checkoutData = await checkoutResponse.json();

        //     if (checkoutData.success) {
        //       window.location.href = "https://www.ecohospitality.in/thankyou/";
        //     } else {
        //       showErrorToast(checkoutData.message || "Checkout failed");
        //     }
        //   } catch (error) {
        //     console.error("Checkout error:", error);
        //     showErrorToast("An error occurred during the checkout process.");
        //   }
        // } else {
        //   showErrorToast("Something went wrong! Please check your details and try again.");
        // }
      } else {
        setLoader(false);
        showErrorToast(data.message || "Something Went Wrong");
      }
    } catch (error) {
      setLoader(false);
      console.error("Submission error:", error);
      showErrorToast("An error occurred during the booking process.");
    }
  };

  const [atomAccessToken, setAtomAccessToken] = useState(null);
  const [ticketData, setTicketData] = useState(null);

  // // Define dummy AtomPaynetz function
  // const AtomPaynetz = (options, environment) => {
  //   console.log(
  //     "AtomPaynetz initialized with options:",
  //     options,
  //     "Environment:",
  //     environment
  //   );
  //   // Simulate a successful payment process
  //   window.postMessage("paymentSuccess");
  // };

  // useEffect(() => {
  //   if (atomAccessToken && ticketData) {
  //     // console.log("ticketData", ticketData?.id);
  //     // console.log("atomAccessToken", atomAccessToken);
  //     const options = {
  //       atomTokenId: atomAccessToken,
  //       merchId: "599204",
  //       custEmail: guestData?.guest_email,
  //       custMobile: guestData?.guest_phone,
  //       returnUrl: `https://extranet.travcatalyst.com/api/v1/online/booking/update/${ticketData?.id}`,
  //     };

  //     const atom = new window.AtomPaynetz(options, "uat");
  //     // setLoader(false);
  //     // localStorage.removeItem("cart");
  //   }
  // }, [atomAccessToken, ticketData]);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setPropertyInfo(true);
      } else {
        setPropertyInfo(true);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setPropertyInfo(false);
    } else {
      setPropertyInfo(true);
    }
  }, []);

  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  // console.log("agentAdvanceCalculations", agentAdvanceCalculations);
  const fetchDiscountsHandler = (propertyId) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/list/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setPropertyDiscountArray(result?.discounts);
          // setLoader(false);
        } else {
          // setLoader(false);
          // setDiscounts([]);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (bookingData?.hotelId) {
      fetchDiscountsHandler(bookingData?.hotelId);
    }
  }, [bookingData?.hotelId]);

  const checkDates = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return currentDate >= start && currentDate <= end;
  };

  // const date = checkDates("2024-09-23", "2024-09-30")
  // console.log("date", date);

  const checkBookingDates = (
    checkinBookingDate,
    checkoutBookingDate,
    discountStartDate,
    discountEndDate
  ) => {
    const checkin = new Date(checkinBookingDate);
    const checkout = new Date(checkoutBookingDate);
    const discountStart = new Date(discountStartDate);
    const discountEnd = new Date(discountEndDate);

    // Check if the booking checkin/checkout lies within the discount date range
    return checkin <= discountEnd && checkout >= discountStart;
  };

  const isBookingWithinDiscount = checkBookingDates(
    "2024-09-24",
    "2024-09-25",
    "2024-09-24", // check-in date of booking
    "2024-09-30" // check-out date of booking
  );

  // console.log("isBookingWithinDiscount", isBookingWithinDiscount);

  const checkRatePlans = (ratePlan, ratePlanArray) => {
    const ratePlanIds =
      ratePlanArray.length > 0 ? ratePlanArray.includes(ratePlan) : true;
    return ratePlanIds;
  };
  const checkRoomsExists = (ratePlan, ratePlanArray) => {
    const ratePlanIds =
      ratePlanArray.length > 0 ? ratePlanArray.includes(ratePlan) : true;
    return ratePlanIds;
  };

  const checkDayExists = (dayArray) => {
    const currentDay = new Date().getDay();

    const adjustedDay = currentDay === 0 ? 1 : currentDay + 1;
    return dayArray.includes(adjustedDay);
  };

  const checkMinLOS = (checkin, checkout, minLOS) => {
    // console.log("checkin, checkout , minLOS", checkin, checkout, minLOS);

    const checkinDate = new Date(checkin);
    const checkoutDate = new Date(checkout);

    return minLOS
      ? checkoutDate - checkinDate >= minLOS * 24 * 60 * 60 * 1000
      : true;
  };

  const handleDiscountChange = (item) => {
    if (selectedDiscount?.id === item?.id) {
      // If the selected discount is the same as the clicked one, remove the selection
      setSelectedDiscount(null);
    } else {
      // Otherwise, set the clicked discount as the selected one
      setSelectedDiscount(item);
    }
  };

  const handleMessage = async ({ data }) => {
    if (data === "cancelTransaction") {
      // console.log("Payment cancelled!!");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/new/update/booking/${ticketData?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status: "cancelled" }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        window.open(result?.return_url);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [ticketData]);

  // razer pay
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //   script.async = true;

  //   const handleLoad = () => console.log('Razorpay script loaded!');
  //   const handleError = (e) => {
  //     console.error('Failed to load Razorpay script', e);
  //     // setError(e);
  //   };

  //   script.addEventListener('load', handleLoad);
  //   script.addEventListener('error', handleError);

  //   document.body.appendChild(script);

  //   return () => {
  //     script.removeEventListener('load', handleLoad);
  //     script.removeEventListener('error', handleError);
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // const handleConfirmAndPayClick = async () => {
  //   const responseData = calculateCreditAndBalance(
  //     agent.agent_name,
  //     ID,
  //     creditChecked,
  //     creditLimit,
  //     advanceChecked,
  //     advanceLimit,
  //     priceBreakup,
  //     agentSearchData.start_date,
  //     agentSearchData.end_date
  //   );

  //   const roomDetails = {
  //     room_id: selectedRoom.room_id,
  //     roomTypeName: selectedRoom.room_name,
  //     ratePlanId: selectedRatePlan.rate_plan_id,
  //     adult: agentSearchData.adults,
  //     taxType: "exclusive",
  //     roomPlan: selectedRatePlan.meal_plan,
  //     rooms: noOfRooms,
  //     nights: noOfNights,
  //     roomRate: roomRate,
  //     children: agentSearchData.number_of_children,
  //     checkIn: agentSearchData.start_date,
  //     checkOut: agentSearchData.end_date,
  //     taxRate: priceBreakup.taxSlab,
  //     taxAmount: priceBreakup.taxAmount,
  //     disAmount: priceBreakup.bookingDiscountAmount || 0,
  //     disRate: propertyDiscount || 0,
  //     totalAmount: priceBreakup.subtotal,
  //     netTotal: priceBreakup.grandTotal,
  //     tacRate: agentDiscount || 0,
  //     tacAmount: priceBreakup.agentDiscountAmount || 0,
  //     extraAdultPrice: selectedRatePlan.extra_adult || 0,
  //     extraAdult: extraAdultCount || 0,
  //   };
  //   responseData.roomDetails = [roomDetails];

  //   console.log(responseData, "responseData");

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/push-booking`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //         body: JSON.stringify(responseData),
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error(`HTTP error! status: ${response.status}`, errorData);
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();

  //      if(data.data.balance_amount === 0){
  //          showSuccessToast('Payment Successful');
  //          navigate('/bookings');
  //      }else{
  //       redirectToPaymentPage(data.data);
  //       setShowReservationsDetails(false);
  //      }

  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // const redirectToPaymentPage = (bookingData) => {
  //   if (!window.Razorpay) {
  //     console.error('Razorpay script is not loaded');
  //     return;
  //   }
  //   // localStorage.
  //   const options = {
  //     key: process.env.REACT_APP_RAZOR_KEY,
  //     amount: bookingData.balance_amount * 100,
  //     currency: 'INR',
  //     order_id: bookingData.razorpayOrderId,
  //     name: selectedProperty.property_name,
  //     description: `Payment against Booking - #${bookingData.bookingId}`,
  //     image: "path/to/image/ecologo.svg",
  //     prefill: {
  //       name: "John Doe",
  //       contact: "1234567890",
  //       email: "john.doe@example.com"
  //     },
  //     handler: function (response) {
  //          console.log(response ,"response_response")
  //         const data = {
  //           reservationId: bookingData.bookingId,
  //           status: 'confirmed',
  //           paidAmount: bookingData.balance_amount,
  //           gatewayOrderId: response.razorpay_order_id,
  //           gatewayPaymentId: response.razorpay_payment_id,
  //           gatewayPaymentSignature: response.razorpay_signature,
  //           gatewayMethod: 'Razorpay',
  //           gatewayCurrency: 'INR'
  //         };

  //         const queryString = new URLSearchParams(data).toString();
  //         navigate(`/payment?${queryString}`);

  //     },
  //     modal: {
  //       ondismiss: function () {
  //         const data = {
  //           reservationId: bookingData.bookingId,
  //           status: 'cancelled',
  //           paidAmount: 0,
  //           gatewayOrderId: 'NULL',
  //           gatewayPaymentId: 'NULL',
  //           gatewayPaymentSignature: 'NULL',
  //           gatewayMethod: 'NULL',
  //           gatewayCurrency: 'NULL'
  //         };

  //         const queryString = new URLSearchParams(data).toString();
  //         navigate(`/cancel-payment?${queryString}`);
  //       }
  //     },
  //     theme: {
  //       color: '#00aa49'
  //     }
  //   };

  //   const rzp = new window.Razorpay(options);
  //   rzp.open();
  // };

  const validate = () => {
    const errors = {};
    if (!guestData.guest_name.trim()) {
      errors.guest_name = "required";
    }
    if (!guestData.guest_phone.trim()) {
      errors.guest_phone = "required";
    }

    if (!guestData.guest_email.trim()) {
      errors.guest_email = "is required";
    }

    if (!guestData.guest_phone.trim().length < 10) {
      errors.guest_phone = "required";

      showErrorToast("Please enter valid phone number without country code.")
      // return;
      // errors.guest_phone = "required";
    }
    // if (!guestData.gst_number) {
    //   errors.gst_number = "required";
    // }
    // if (!guestData.business_email) {
    //   errors.business_email = "required";
    // }
    // if (!guestData.company_number) {
    //   errors.company_number = "required";
    // }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <>
      {/* <LoadAtomCheckoutScript onScriptLoad={handleScriptLoad} /> */}
      <div id="checkOutHotels">
        <PageMetaData
          title="Mountain Mysteries"
          description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
          keywords="hotel management, RevCatalyst, property optimization"
        />
        <Header />
        <ReviewReservationBanner
          id="test"
          heading="Review Reservation Details"
        />
        {/* <PageLinks links={["Home", `Properties In ${bookingData.location}`, `${bookingData.hotel_name}`]} /> */}
        <section>
          <div className="container">
            <main className="justify_content_center">
              <div className="reservation-content">
                <div className="flex flex_gap_10">
                  {" "}
                  <div className="mt-10">
                    <span
                      onClick={() => [
                        scroll.scrollToTop({
                          duration: 500,
                          smooth: "easeInOutQuad",
                        }),
                        navigate(-1),
                      ]}
                      style={{
                        background: "#cccccc7e",
                        padding: "5px 10px 10px",
                        borderRadius: "6px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                          fill="#333333"
                        ></path>
                      </svg>
                    </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <h4>
                      <b>{hotelData?.hotelInfo?.name}</b>
                    </h4>
                    <small>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                          fill="#888888"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                          fill="#888888"
                        />
                      </svg>{" "}
                      {hotelData?.hotelInfo?.address}
                    </small>
                  </div>
                </div>

                <div className="list_details" id="padding">
                  <div
                    className="flex justify_content_between cursor_pointer align_items_center"
                    onClick={togglePropertyDetails}
                  >
                    <h5 className="text-left pb_10">PROPERTY INFO</h5>
                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "transform 0.3s",
                        transform: propertyInfo
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <path
                        d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                        stroke="#222222"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  {propertyInfo && (
                    <>
                      <div className="hotel-data mt-10">
                        <div className="hotel_img">
                          <img
                            src={hotelData?.hotelImages[0]}
                            alt="hotel-room-image"
                          />
                        </div>
                        <div className="hotel-text">
                          <h6>
                            <b>{hotelData?.hotelInfo?.name}</b>
                          </h6>
                          <span>3 Star</span>
                          <small>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              style={{ margin: "0" }}
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                fill="#888888"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                fill="#888888"
                              />
                            </svg>
                            {hotelData?.hotelInfo?.address}
                          </small>{" "}
                          <br />
                          <p className="hotel-description">
                            {hotelData?.hotelInfo?.details}
                          </p>
                        </div>
                      </div>
                      <div className="flex_align_center Checkouthotel-main flex_gap_20">
                        <div className="Checkouthotel-dates flex_align_center justify_content_between w-half mt-10">
                          <div className="">
                            <span>Check in</span>
                            <h6 className="">
                              {textDateFormatterNoAdd(checkInDate)}
                            </h6>
                            {/* <small>2 PM</small> */}
                          </div>
                          <div className=" Checkouthotel-checkout">
                            <span>Check Out</span>
                            <h6>{textDateFormatterNoAdd(checkOutDate)}</h6>
                            {/* <small>2 PM</small> */}
                          </div>
                        </div>
                      </div>
                      <div className="guestDetailsData mt-20">
                        <h5 className="text-left p_10 border-bottom-one">
                          {bookingData?.room} Room , {bookingData?.adult}{" "}
                          Adults,
                          {bookingData?.children && (
                            <span> {bookingData?.children} Child</span>
                          )}
                        </h5>
                        <div className="room_details p_10">
                          <div className="border-right-one" id="right-border-0">
                            <div>
                              <h6>
                                {" "}
                                {bookingData?.room} x {ratesData?.room_name}
                              </h6>
                            </div>
                            <div className="mt-10 ">
                              <span>
                                {bookingData?.adult} Adults,
                                {bookingData?.children && (
                                  <span> {bookingData?.children} Child</span>
                                )}{" "}
                                <small>
                                  {" "}
                                  ({selectedPlan?.meal_plan_name}){" "}
                                </small>
                              </span>
                              {/* <p>{selectedPlan?.meal_plan_name} </p> */}
                              {/* <div className="alert_text p_b_10">Free Cancellation before 30 Jun 01:59 PM</div> */}
                              <div
                                className="link_btn mt-10 cursor_pointer"
                                onClick={handleOnBooking}
                              >
                                <b>Cancellation Policy</b>
                              </div>
                              {booking && (
                                <section className="popup_backdrop">
                                  <div className="cancellation_content">
                                    <div className="close_svg">
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        onClick={closePopup}
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L13 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                        <path
                                          d="M13 13L1 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                      </svg>
                                    </div>
                                    <div className="container">
                                      <div className="overview-content mt-20">
                                        <div>
                                          <p>
                                            We understand that plans can change,
                                            and we strive to make our
                                            cancellation policy as
                                            straightforward and fair as
                                            possible, in compliance with Indian
                                            laws and regulations. This
                                            cancellation policy is designed to
                                            align with Indian legal
                                            requirements, including the Consumer
                                            Protection Act, 2019, and other
                                            relevant regulations, while ensuring
                                            transparency and fairness for
                                            customers. Please read this policy
                                            carefully to understand your rights
                                            and obligations when canceling
                                            travel and holiday packages, as well
                                            as accommodation bookings with us.
                                          </p>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            General Cancellation Policy{" "}
                                          </h4>
                                          <b>1. Applicability</b>
                                          <p className="mt-10">
                                            This cancellation policy applies to
                                            all bookings made through Mountain
                                            Mysteries, whether online or
                                            offline. It covers travel packages,
                                            holiday packages, and accommodation
                                            inventory.
                                          </p>
                                          <b className="mt-10 block">
                                            2. Notification of Cancellation{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              Cancellations must be submitted in
                                              writing via email or through our
                                              online cancellation form available
                                              on our website.
                                            </li>
                                            <li>
                                              Please include your booking
                                              reference number and the reason
                                              for cancellation in your request.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            3. Compliance with Indian Laws{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              This policy is designed to comply
                                              with the Consumer Protection Act,
                                              2019, and other relevant Indian
                                              laws. In case of any conflict
                                              between this policy and applicable
                                              laws, the latter shall prevail.{" "}
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Cancellation Terms for Travel and
                                            Holiday Packages
                                          </h4>
                                          <b>1. Cancellation by Customer </b>
                                          <ul className="ml_15">
                                            <li>
                                              <b>
                                                More than 30 days before
                                                departure:
                                              </b>{" "}
                                              Full refund of the total booking
                                              amount, minus a processing fee of
                                              [Insert Amount or Percentage], in
                                              compliance with Indian laws.
                                            </li>
                                            <li>
                                              <b>
                                                15 to 30 days before departure:
                                              </b>{" "}
                                              50% refund of the total booking
                                              amount.
                                            </li>
                                            <li>
                                              <b>
                                                Less than 15 days before
                                                departure:
                                              </b>{" "}
                                              No refund will be issued unless
                                              mandated by applicable Indian
                                              regulations.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            2. Changes to Booking{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              Requests to modify travel or
                                              holiday packages (e.g., change of
                                              dates, destination) are subject to
                                              availability and may incur
                                              additional charges.
                                            </li>
                                            <li>
                                              Modifications must be requested at
                                              least 15 days before the scheduled
                                              departure date.{" "}
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            3. Non-Refundable Components{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              Some components of travel
                                              packages, such as flights, special
                                              event tickets, or third-party
                                              services, may be non-refundable
                                              regardless of the cancellation
                                              date. These will be clearly
                                              indicated at the time of booking.
                                            </li>
                                            <li>
                                              In accordance with the Indian
                                              Contract Act, 1872, non-refundable
                                              components will be governed by the
                                              terms agreed upon at the time of
                                              booking.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            4.Force Majeure{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              If cancellations occur due to
                                              events beyond our control, such as
                                              natural disasters, pandemics, or
                                              government restrictions, we will
                                              work with you to reschedule or
                                              provide a credit for future
                                              bookings. Refunds may not be
                                              possible for certain third-party
                                              services.
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Cancellation Terms for Accommodation
                                            Bookings{" "}
                                          </h4>
                                          <b>1. Cancellation by Customer</b>
                                          <ul className="ml_15">
                                            <li>
                                              <b>
                                                {" "}
                                                More than 15 days before
                                                check-in:
                                              </b>{" "}
                                              Full refund of the total booking
                                              amount, minus a processing fee of
                                              [Insert Amount or Percentage].
                                            </li>
                                            <li>
                                              <b>
                                                7 to 15 days before check-in:
                                              </b>{" "}
                                              50% refund of the total booking
                                              amount.{" "}
                                            </li>
                                            <li>
                                              <b>
                                                Less than 7 days before
                                                check-in:{" "}
                                              </b>
                                              No refund will be issued unless
                                              required by Indian consumer
                                              protection laws.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            2. No-Show Policy
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              If you fail to check in on the
                                              scheduled date without prior
                                              notification, the full booking
                                              amount will be charged, and no
                                              refund will be issued.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            3. Early Departure{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              No refunds will be provided for
                                              early departures or unused nights
                                              unless specified under special
                                              circumstances governed by Indian
                                              laws.
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Special Circumstances
                                          </h4>
                                          <b>1. Medical Emergencies</b>
                                          <ul className="ml_15">
                                            <li>
                                              In case of medical emergencies, we
                                              may offer a refund or rescheduling
                                              option upon submission of valid
                                              documentation, such as a medical
                                              certificate. Please contact our
                                              customer service team for
                                              assistance.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            2. Travel Insurance{" "}
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              We strongly recommend purchasing
                                              travel insurance to cover
                                              unforeseen circumstances that may
                                              result in cancellations or trip
                                              interruptions.
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Refund Process
                                          </h4>
                                          <b>1. Refund Timeline</b>
                                          <ul className="ml_15">
                                            <li>
                                              Refunds, if applicable, will be
                                              processed within 7-10 business
                                              days from the date of cancellation
                                              approval.{" "}
                                            </li>
                                            <li>
                                              Refunds will be issued to the
                                              original payment method used at
                                              the time of booking.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            2. Processing Fees
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              All cancellations may be subject
                                              to processing fees, which will be
                                              deducted from the refund amount.
                                              These fees cover administrative
                                              costs associated with the
                                              cancellation process.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            3. Compliance with the Consumer
                                            Protection Act, 2019
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              Refunds will be processed in
                                              accordance with the timelines and
                                              procedures set forth by the
                                              Consumer Protection Act, 2019, and
                                              other relevant Indian regulations.
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Cancellation by Mountain Mysteries
                                          </h4>
                                          <b>1. Right to Cancel </b>
                                          <ul className="ml_15">
                                            <li>
                                              Mountain Mysteries reserves the
                                              right to cancel bookings due to
                                              unforeseen circumstances, such as
                                              lack of availability, changes in
                                              travel restrictions, or safety
                                              concerns.
                                            </li>
                                          </ul>
                                          <b className="mt-10 block">
                                            2. Refund or Rescheduling
                                          </b>
                                          <ul className="ml_15">
                                            <li>
                                              In such cases, we will offer you
                                              the option to either reschedule
                                              your booking or receive a full
                                              refund of the amount paid.
                                            </li>
                                            <li>
                                              Our actions will comply with the
                                              Indian Contract Act, 1872,
                                              ensuring fairness and
                                              transparency.
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <h4 className="mt-10 mb-10">
                                            Contact Us
                                          </h4>
                                          <p>
                                            If you have any questions or need
                                            assistance with a cancellation,
                                            please don't hesitate to contact our
                                            customer support team.
                                          </p>
                                        </div>
                                        <div>
                                          <h4 className="mt-20 mb-10">
                                            Changes to This Cancellation Policy{" "}
                                          </h4>
                                          <p>
                                            Mountain Mysteries reserves the
                                            right to update or modify this
                                            cancellation policy at any time. We
                                            will notify you of any changes by
                                            posting the updated policy on our
                                            website. It is your responsibility
                                            to review this policy periodically
                                            for any changes.
                                          </p>
                                          <p>
                                            Thank you for choosing Mountain
                                            Mysteries. We appreciate your
                                            understanding and cooperation.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </div>
                          </div>
                          <div>
                            <div>
                              <li className="p_b_10">Book @ ₹0 available</li>
                              <li className="p_b_10">
                                Room With Free Cancellation
                              </li>
                              <li>No meals included</li>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="list_details p_0">
                  <div
                    className="flex justify_content_between p_20 cursor_pointer align_items_center"
                    onClick={toggleGuestDetails}
                    id="padding"
                  >
                    <h5 className="text-left">GUEST DETAILS</h5>
                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "transform 0.3s",
                        transform: guestDetails
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <path
                        d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                        stroke="#222222"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  {guestDetails && (
                    <>
                      <form action="">
                        <div
                          className="p_20 p_top_0 border-bottom-one"
                          id="padding"
                        >
                          <div className="input-flex">
                            <div className="HotelCheckout-form w-half">
                              <label htmlFor="">Full Name</label>
                              <input
                                className="HotelCheckout-Input"
                                id="guest_name"
                                type="text"
                                name="guest_name"
                                value={guestData.guest_name}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_name && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Name"
                              />
                            </div>
                            <div className="CheckoutHotel-Fields w-half">
                              <label htmlFor="">Phone</label>
                              <input
                                className="HotelCheckout-Input"
                                type="number"
                                id="guest_phone"
                                name="guest_phone"
                                value={guestData.guest_phone}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_phone && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Phone"
                              />
                            </div>
                            <div className="CheckoutHotel-Fields w-half">
                              <label htmlFor="">
                                Email Address{" "}
                                {/* <small>
                                  (Booking voucher will be sent to this email
                                  ID)
                                </small> */}
                              </label>
                              <input
                                className="HotelCheckout-Input"
                                id="guest_email"
                                name="guest_email"
                                value={guestData.guest_email}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_email && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="input-flex">
                            <div></div>
                          </div>
                          <div className="mt-10">
                            <label htmlFor="address">Address</label>
                            <AddressAutocomplete
                              formdata={formData}
                              onAddressChange={handleAddressData}
                            />
                          </div>
                        </div>
                      </form>
                      <div
                        onClick={toggleBusinessProfile}
                        className="p_20 flex_align_center flex_gap_10"
                        id="padding"
                      >
                        <input
                          type="checkbox"
                          className="cursor_pointer"
                          checked={businessProfile}
                        />
                        <div className="cursor_pointer">
                          Enter GST Details
                          <small> (Optional)</small>
                        </div>
                      </div>
                      {businessProfile && (
                        <form onSubmit={handleSubmit}>
                          <div className="p_20 p_top_0" id="padding">
                            <h5 className="text-left">BUSINESS PROFILE</h5>
                            <div className="input-flex mt-10">
                              <div>
                                <input
                                  className="w-full"
                                  id="gst_number"
                                  name="gst_number"
                                  value={guestData.gst_number}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.gst_number && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="GST Number"
                                />
                              </div>
                              <div>
                                <input
                                  className="w-full"
                                  id="business_email"
                                  name="business_email"
                                  value={guestData.business_email}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.business_email && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="Business Email ID"
                                />
                              </div>
                            </div>
                            <div className="input-flex">
                              <div>
                                <input
                                  className="w-full"
                                  id="company_number"
                                  type="number"
                                  name="company_number"
                                  value={guestData.company_number}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.company_number && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="Company Phone Number"
                                />
                              </div>
                              {/* <div>
                              <input
                                className="w-full"
                                id="company_address"
                                name="company_address"
                                value={guestData.company_address}
                                onChange={guestDataHandleChange}
                                required
                                placeholder="Company Address"
                              />
                            </div> */}
                            </div>

                            {/* <button type="submit">Save</button> */}
                          </div>
                        </form>
                      )}

                      {userType === "agent" && (
                        <>
                          <div
                            onClick={() =>
                              setPayWithAgentCredit(!payWithAgentCredit)
                            }
                            className="px_20 flex_align_center flex_gap_10"
                            id="padding"
                          >
                            <input
                              type="checkbox"
                              className="cursor_pointer"
                              checked={payWithAgentCredit}
                            />
                            <div className="cursor_pointer">
                              Pay With Agent Credits
                              <small>
                                {" "}
                                (
                                {formatIndianCurrency(
                                  agentAdvanceCalculations?.remaining_credit_balance
                                )}
                                )
                              </small>
                            </div>
                          </div>
                          <div
                            onClick={() => setPayWithAdvance(!payWithAdvance)}
                            className="p_20 flex_align_center flex_gap_10"
                            id="padding"
                          >
                            <input
                              type="checkbox"
                              className="cursor_pointer"
                              checked={payWithAdvance}
                            />
                            <div className="cursor_pointer">
                              Pay With Advance Amount
                              <small>
                                {" "}
                                (
                                {formatIndianCurrency(
                                  agentAdvanceCalculations?.remaining_advance_balance
                                )}
                                )
                              </small>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="buttons">
                  <button
                    onClick={handleSubmit}
                    // disabled
                    className="common_btn "
                  >
                    Proceed to Payment
                  </button>
                </div>
              </div>
              <aside>
                <div className="sticky">
                  <div className="common_background" id="priceBreakup">
                    {/* start */}
                    <div
                      className="flex justify_content_between cursor_pointer"
                      onClick={togglePaymentDetails}
                    >
                      <h5 className="text-left">Price Breakup</h5>
                      <div>
                        <button
                          className={`${
                            activePayment === "priceSummary" ? "" : ""
                          }`}
                          onClick={handleButtonClick}
                        >
                          <small className="link_btn">
                            {" "}
                            View Full Breakup{" "}
                          </small>
                          <svg
                            width="13"
                            height="8"
                            viewBox="0 0 13 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transition: "transform 0.3s",
                              transform: paymentDetails
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          >
                            <path
                              d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                              stroke="#222222"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {activePayment === "priceSummary" && paymentDetails && (
                      <div>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Type ({bookingData?.room} R | {noOfNights} N )
                          </p>
                          <span className="room_text-right">
                            {ratesData?.room_name}
                          </span>
                        </div>
                        {/* <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>Room Rate</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(selectedPlan?.rate_per_night)}
                          </span>
                        </div> */}
                        <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>Room Rate</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(selectedPlan?.rate_per_night)}
                          </span>
                        </div>
                        {agentAdvanceCalculations.pay_with_company_credit && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>Agent Credit</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.credit_balance -
                                  agentAdvanceCalculations?.remaining_credit_balance
                              )}
                            </span>
                          </div>
                        )}
                        {agentAdvanceCalculations.pay_with_advance && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>Agent Advance</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.advanceLimit -
                                  agentAdvanceCalculations?.remaining_advance_balance
                              )}
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Total Amount to be paid</b>
                          </h6>
                          <h6>
                            <b>
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.balanceAmount ||
                                  agentAdvanceCalculations?.balanceAmount === 0
                                  ? agentAdvanceCalculations?.balanceAmount
                                  : bookingValues?.grandTotal
                              )}
                              {/* {formatIndianCurrency(agentAdvanceCalculations?.balanceAmount)} */}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}

                    {activePayment === "priceDetails" && (
                      <div>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Type ({bookingData?.room} R | {noOfNights} N )
                          </p>
                          <span className="room_text-right">
                            {ratesData?.room_name}
                          </span>
                        </div>

                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Rate <small>(Per Night)</small>
                          </p>
                          <span className="room_text-right">
                            {formatIndianCurrency(selectedPlan?.rate_per_night)}
                          </span>
                        </div>
                        {userDetails?.agent_category_discount && (
                          <div className="flex justify_content_between mt-10 green">
                            <p>
                              Discount % <small>(TAC)</small>
                            </p>
                            <span className="room_text-right green">
                              {Number(userDetails?.agent_category_discount)}%
                            </span>
                          </div>
                        )}
                        {bookingValues?.discountAmt > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>Discount Amt.</p>
                            <span className="room_text-right">
                              {formatIndianCurrency(bookingValues?.discountAmt)}
                            </span>
                          </div>
                        )}
                        {bookingValues?.childPrices > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              {bookingData.children} Extra Child{" "}
                              <small>
                                {" "}
                                (
                                {formatIndianCurrency(
                                  bookingValues?.childPrices
                                )}{" "}
                                * {noOfNights} Night){" "}
                              </small>{" "}
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                bookingValues?.totalChildAdultCost
                              )}
                            </span>
                          </div>
                        )}
                        {/* <div className="flex justify_content_between mt-10">
                        <p>totalChildAdultCost</p>
                        <span className="room_text-right">
                          {formatIndianCurrency(bookingValues?.totalChildAdultCost)}
                          {formatIndianCurrency(bookingValues?.childPrices)}
                        </span>
                      </div> */}
                        <div className="flex justify_content_between mt-10">
                          <p>Subtotal</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(bookingValues.subtotal)}
                          </span>
                        </div>

                        <div className="flex justify_content_between mt-10">
                          <p>Tax Amount ({bookingValues?.taxSlab}%)</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(bookingValues.taxAmount)}
                          </span>
                        </div>

                        {agentAdvanceCalculations.pay_with_company_credit && (
                          <div className="flex mt-10 justify_content_between p_b_10">
                            <p>Agent Credit</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.credit_balance -
                                  agentAdvanceCalculations?.remaining_credit_balance
                              )}
                            </span>
                          </div>
                        )}
                        {agentAdvanceCalculations.pay_with_advance && (
                          <div className="flex justify_content_between border-bottom-one p_b_10">
                            <p>Agent Advance</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.advanceLimit -
                                  agentAdvanceCalculations?.remaining_advance_balance
                              )}
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Grand Total</b>
                          </h6>
                          <h6>
                            <b>
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.balanceAmount ||
                                  agentAdvanceCalculations?.balanceAmount === 0
                                  ? agentAdvanceCalculations?.balanceAmount
                                  : bookingValues?.grandTotal
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* discounts */}

                  {propertyDiscountArray.length > 0 &&
                    propertyDiscountArray.some((item) => {
                      const isValidDate = checkDates(
                        item.start_date,
                        item.end_date
                      );
                      const isBookingWithinDiscount = checkBookingDates(
                        checkInDate, // check-in date of booking
                        checkOutDate, // check-out date of booking
                        item.start_date, // discount start date
                        item.end_date // discount end date
                      );
                      const isValidCheckRatePlans = checkRatePlans(
                        selectedPlan?.meal_plan.toLowerCase(),
                        item.all_conditions.selected_rate_plans
                      );
                      const isValidCheckRooms = checkRoomsExists(
                        ratesData?.room_id,
                        item.all_conditions.selected_rooms
                      );
                      const isDayExists = checkDayExists(
                        item?.all_conditions?.stay_dates
                      );
                      const isMinLos = checkMinLOS(
                        checkInDate,
                        checkOutDate,
                        item?.all_conditions?.minLos
                      );

                      return (
                        isValidDate &&
                        isBookingWithinDiscount &&
                        isValidCheckRatePlans &&
                        isValidCheckRooms &&
                        isDayExists &&
                        isMinLos
                      );
                    }) && (
                      <>
                        <div
                          className="common_background"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="flex justify_content_between cursor_pointer">
                            <div className="flex justify_content_between w-100">
                              <div>
                                <h5 className="text-left">Discounts</h5>
                              </div>
                              <button
                                className="view_btn"
                                onClick={() => setSelectedDiscount(null)}
                              >
                                clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  <div>
                    {propertyDiscountArray.length > 0 &&
                      propertyDiscountArray
                        .filter((item) => item?.status === "enable")
                        .map((item, index) => {
                          const isCouponCode =
                            item?.method === "code" ? true : false;

                          const isValidDate = checkDates(
                            item.start_date,
                            item.end_date
                          );
                          const isBookingWithinDiscount = checkBookingDates(
                            checkInDate, // check-in date of booking
                            checkOutDate, // check-out date of booking
                            item.start_date, // discount start date
                            item.end_date // discount end date
                          );

                          const isValidCheckRatePlans = checkRatePlans(
                            selectedPlan?.meal_plan.toLowerCase(),
                            item.all_conditions.selected_rate_plans
                          );
                          const isValidCheckRooms = checkRoomsExists(
                            ratesData?.room_id,
                            item.all_conditions.selected_rooms
                          );

                          const isDayExists = checkDayExists(
                            item?.all_conditions?.stay_dates
                          );
                          const isMinLos = checkMinLOS(
                            checkInDate,
                            checkOutDate,
                            item?.all_conditions?.minLos
                          );

                          return (
                            isValidDate &&
                            isBookingWithinDiscount &&
                            isValidCheckRatePlans &&
                            isValidCheckRooms &&
                            isDayExists &&
                            isMinLos && (
                              <div
                                key={index}
                                className="flex discountSingleDiv justify_content_between w-100"
                              >
                                <div className="flex">
                                  <input
                                    checked={selectedDiscount?.id === item?.id}
                                    onChange={() => handleDiscountChange(item)}
                                    type="radio"
                                  />
                                  <label
                                    style={{ textTransform: "capitalize" }}
                                    htmlFor=""
                                  >
                                    <div>
                                      {isCouponCode
                                        ? item?.discount_code?.replace(
                                            /-/g,
                                            " "
                                          )
                                        : item?.name.replace(/-/g, " ")}
                                    </div>
                                    <div>
                                      {item?.all_conditions.promotion_name.replace(
                                        /-/g,
                                        " "
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <div>
                                  {item?.type === "fixed" ? (
                                    formatIndianCurrency(item?.amount)
                                  ) : (
                                    <>{item?.amount}%</>
                                  )}
                                </div>
                              </div>
                            )
                          );
                        })}
                  </div>

                  <OurQuery />
                </div>
              </aside>

              {/* {mobilePriceBreakup && (
                <>
                  <div className="flex justify_content_between total_amount align_items_center">
                    <div>
                      <h6 className="font_12">Total Amount to be paid </h6>
                      <h6>
                        <span
                          className={` ${
                            Number(bookingValues?.grandTotal) <= 0 &&
                            "filterBlur3"
                          }`}
                        >
                          {formatIndianCurrency(bookingValues?.grandTotal)}{" "}
                          <svg
                            onClick={() => setPriceBreakupPopup(true)}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0,0,256,256"
                            width="20px"
                            height="20px"
                            fill-rule="nonzero"
                          >
                            <g
                              fill="#ffffff"
                              fill-rule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <g transform="scale(8,8)">
                                <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h6>{" "}
                    </div>
                    {priceBreakupPopup && (
                      <>
                        <div className="bg_back_drop_overlay">
                          <div
                            className="common_background common_background_parent_sticky"
                            id="PriceBreak"
                          >
                            <div className="flex justify_content_end p_b_10 ">
                              <svg
                                onClick={() => setPriceBreakupPopup(false)}
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 12.5L13 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                                <path
                                  d="M13 12.5L1 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                              </svg>
                            </div>
                            <div className="flex justify_content_between align_items_center text-black">
                              <h4
                                style={{
                                  fontSize: "14px",
                                  padding: "10px 0px",
                                }}
                              >
                                Fare Summary
                              </h4>

                              <div onClick={togglePaymentDetails}>
                                <button
                                  className={`${
                                    activePayment === "priceSummary" ? "" : ""
                                  }`}
                                  onClick={handleButtonClick}
                                >
                                  <small className="link_btn">
                                    {activePayment === "priceSummary"
                                      ? "View Breakup"
                                      : "Minimize Breakup"}{" "}
                                  </small>
                                  <svg
                                    width="13"
                                    height="8"
                                    viewBox="0 0 13 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      transition: "transform 0.3s",
                                      transform: paymentDetails
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    }}
                                  >
                                    <path
                                      d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                      stroke="#222222"
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            {activePayment === "priceSummary" &&
                              paymentDetails && (
                                <div>
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>
                                        {" "}
                                        Room Type ({bookingData?.room} R |{" "}
                                        {noOfNights} N )
                                      </b>
                                    </p>
                                    <span className="room_text-right">
                                      {ratesData?.room_name}
                                    </span>
                                  </div>
                                  <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                    <p className="text-black">
                                      <b>Room Rate</b>
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        selectedPlan?.rate_per_night
                                      )}
                                    </span>
                                  </div>
                                  {agentAdvanceCalculations.pay_with_company_credit && (
                                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                      <p className="text-black">
                                        <b>Agent Credit</b>
                                      </p>
                                      <span className="room_text-right">
                                        -{" "}
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.credit_balance -
                                            agentAdvanceCalculations?.remaining_credit_balance
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  {agentAdvanceCalculations.pay_with_advance && (
                                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                      <p className="text-black">
                                        <b>Agent Advance</b>
                                      </p>
                                      <span className="room_text-right">
                                        -{" "}
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.advanceLimit -
                                            agentAdvanceCalculations?.remaining_advance_balance
                                        )}
                                      </span>
                                    </div>
                                  )}

                                  <div className="flex justify_content_between mt-10">
                                    <h6 className="text-black">
                                      <b>Total Amount to be paid</b>
                                    </h6>
                                    <h6 className="text-black">
                                      <b>
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.balanceAmount ||
                                            agentAdvanceCalculations?.balanceAmount ===
                                              0
                                            ? agentAdvanceCalculations?.balanceAmount
                                            : bookingValues?.grandTotal
                                        )}
                                      </b>
                                    </h6>
                                  </div>
                                </div>
                              )}

                            {activePayment === "priceDetails" && (
                              <div>
                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Room Type </b>({bookingData?.room} R |{" "}
                                    {noOfNights} N )
                                  </p>
                                  <span className="room_text-right">
                                    {ratesData?.room_name}
                                  </span>
                                </div>

                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Room Rate</b> <small>(Per Night)</small>
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      selectedPlan?.rate_per_night
                                    )}
                                  </span>
                                </div>
                                {userDetails?.agent_category_discount && (
                                  <div className="flex justify_content_between mt-10 green">
                                    <p className="text-black">
                                      <b> Discount % </b>
                                      <small>(TAC)</small>
                                    </p>
                                    <span className="room_text-right green">
                                      {Number(
                                        userDetails?.agent_category_discount
                                      )}
                                      %
                                    </span>
                                  </div>
                                )}
                                {bookingValues?.discountAmt > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>Discount Amt.</b>
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        bookingValues?.discountAmt
                                      )}
                                    </span>
                                  </div>
                                )}
                                {bookingValues?.childPrices > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>{bookingData.children} Extra Child </b>
                                      <small>
                                        {" "}
                                        (
                                        {formatIndianCurrency(
                                          bookingValues?.childPrices
                                        )}{" "}
                                        * {noOfNights} Night){" "}
                                      </small>{" "}
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        bookingValues?.totalChildAdultCost
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Subtotal</b>
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      bookingValues.subtotal
                                    )}
                                  </span>
                                </div>

                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Tax Amount</b> ({bookingValues?.taxSlab}
                                    %)
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      bookingValues.taxAmount
                                    )}
                                  </span>
                                </div>

                                {agentAdvanceCalculations.pay_with_company_credit && (
                                  <div className="flex mt-10 justify_content_between p_b_10">
                                    <p className="text-black">
                                      <b>Agent Credit</b>
                                    </p>
                                    <span className="room_text-right">
                                      -{" "}
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.credit_balance -
                                          agentAdvanceCalculations?.remaining_credit_balance
                                      )}
                                    </span>
                                  </div>
                                )}
                                {agentAdvanceCalculations.pay_with_advance && (
                                  <div className="flex justify_content_between border-bottom-one p_b_10">
                                    <p className="text-black">
                                      <b>Agent Advance</b>
                                    </p>
                                    <span className="room_text-right">
                                      -{" "}
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.advanceLimit -
                                          agentAdvanceCalculations?.remaining_advance_balance
                                      )}
                                    </span>
                                  </div>
                                )}

                                <div className="flex justify_content_between mt-10 border-top pt_10">
                                  <h6 className="text-black">
                                    <b>Grand Total</b>
                                  </h6>
                                  <h6 className="text-black">
                                    <b>
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.balanceAmount ||
                                          agentAdvanceCalculations?.balanceAmount ===
                                            0
                                          ? agentAdvanceCalculations?.balanceAmount
                                          : bookingValues?.grandTotal
                                      )}
                                    </b>
                                  </h6>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )} */}
            </main>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default CheckOutHotelNew;
