import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";

function TermConditions() {
    return (
        <>
            <PageMetaData
                title="Terms and Conditions | Mountain Mysteries"
                description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
                keywords="hotel management, RevCatalyst, property optimization"
            />
            <Header />
            <ReviewReservationBanner heading="Term & Conditions" />
            {/* <PageLinks links={["Home", "Term &  Conditions"]} /> */}
            <section className="pb_30">
                <div className="container">
                    <div className="overview-content">
                        <div>
                            <h4 className='mt-20 mb-10'>Acknowledgment</h4>
                            <p>Welcome to Mountain Mysteries! These Terms and Conditions govern your access and use of our travel services, accommodations, and online and offline inventory sales. By using our services, you agree to comply with and be bound by these Terms and Conditions. They outline your rights and obligations and those of Mountain Mysteries, ensuring a safe and enjoyable experience for all.</p>
                            <p>
                                Your access to and use of our services are conditioned on your acceptance of and compliance with these Terms. These terms apply to all visitors, users, and others who access or use our services. If you disagree with any part of these Terms and Conditions, you must refrain from using our services.
                            </p>
                            <p>By using our services, you confirm that you are over 18 years of age. Mountain Mysteries does not permit individuals under 18 to use our services.

                            </p>
                            <p>In addition to these Terms, your use of our services is subject to your acceptance of our Privacy Policy. Our Privacy Policy explains how we collect, use, and protect your personal information. It also outlines your privacy rights and how the law safeguards you. We encourage you to review our Privacy Policy carefully before using our services.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Links to Other Websites</h4>
                            <p>Our services may include links to third-party websites or services not owned or controlled by Mountain Mysteries. We have no control over the content, privacy policies, or practices of any third-party sites or services and assume no responsibility for them. You acknowledge and agree that Mountain Mysteries is not liable, directly or indirectly, for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any third-party websites or services.
                            </p>
                            <p>We strongly advise you to review the terms and conditions and privacy policies of any third-party websites or services you visit.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Termination</h4>
                            <p>Mountain Mysteries reserves the right to terminate or suspend your access to our services immediately, without prior notice or liability, for any reason whatsoever, including, but not limited to, if you breach these Terms and Conditions. Upon termination, your right to use our services will cease immediately.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Limitation of Liability</h4>
                            <p>To the fullest extent permitted by applicable law, Mountain Mysteries and its suppliers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use our services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>

                            <p>In no event shall Mountain Mysteries' aggregate liability for all claims relating to our services exceed the amount you paid, if any, for accessing our services or $100 if you have not made any purchases through our services.

                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>"As Is" and "As Available" Disclaimer  </h4>
                            <p>Our services are provided on an "AS IS" and "AS AVAILABLE" basis. Mountain Mysteries makes no representations or warranties of any kind, express or implied, regarding the operation or availability of our services, or the information, content, materials, or products included thereon. To the fullest extent permitted by applicable law, Mountain Mysteries disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, as well as any warranties that may arise out of the course of dealing, course of performance, usage, or trade practice.</p>
                            <p>Mountain Mysteries does not warrant that our services will be uninterrupted, error-free, secure, or free of viruses or other harmful components, nor does it warrant that any errors or defects will be corrected.

                            </p>
                            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on statutory rights, so some or all of the above exclusions and limitations may not apply to you. In such jurisdictions, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Governing Law</h4>
                            <p>These Terms and Conditions are governed by and construed in accordance with the laws of the Country, excluding its conflict of law principles. Your use of the Application and services may also be subject to other local, state, national, or international laws.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Dispute Resolution
                            </h4>
                            <p>If you have any concerns or disputes regarding our services, you agree to first attempt to resolve the issue informally by contacting Mountain Mysteries. We are committed to addressing your concerns and finding a mutually satisfactory resolution.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Severability</h4>
                            <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be modified and interpreted to achieve the objectives of the original provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'   >Waiver</h4>
                            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter, nor shall the waiver of a breach constitute a waiver of any subsequent breach.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Translation Interpretation</h4>
                            <p>These Terms and Conditions may have been translated if made available to you on our services. You agree that the original English text shall prevail in the event of a dispute.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Changes to These Terms and Conditions                            </h4>
                            <p>Mountain Mysteries reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.
                            </p>
                            <p>By continuing to access or use our services after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please discontinue using our website and services.</p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Contact Us</h4>
                            <p>If you have any questions about these Terms and Conditions, please contact us at the phone number or email address provided below. We are here to assist you and ensure your experience with Mountain Mysteries is exceptional.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TermConditions;