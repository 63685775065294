import React, { useEffect, useState } from "react";
import Header from "../../../CustomComponents/Header/Header";
import Footer from "../../../CustomComponents/Footer/Footer";
import { textDateFormatter } from "../../../utils/utils";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import Loader from "../../../CustomComponents/Loader/Loader";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import AgentProfile from "../AgentProfile/AgentProfile";
import AgentHeader from "../AgentHeader/AgentHeader";

const AgentCreditLogs = () => {
  const [creditLog, setCreditLog] = useState([]);
  const [loader, setLoader] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetails);

  const fetchCreditLogsApi = () => {
    setLoader(true);
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/credit-logs/55`,
      requestOptions
    )
      .then((response) => response.json()) // Parse the response as JSON
      .then((result) => {
        if (result.success) {
          setCreditLog(result.data);
          setLoader(false); // Hide loader after request completion
        } else {
          setLoader(false);
        }
      })
      .catch((error) => console.error("Error fetching single rate:", error));
  };
  useEffect(() => {
    if (userDetails.AgentId) {
      fetchCreditLogsApi(userDetails.AgentId);
    }
  }, [userDetails.AgentId]);

  return (
    <>
      <AgentHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <AgentSideBar />
        <section className="w-full table-data">
          <div>
            <div className="overflow_x_scroll_y_hidden">
              {loader ? (
                <Loader size="4em" color="#016937" />
              ) : (
                <>
                  <h4>Credit Logs</h4>
                  <AgentProfile />
                  <div className="overflow_x_scroll_y_hidden">
                    <table className="inventory_table m_0">
                      <tr style={{ borderBottom: "1px solid #cccccc96" }}>
                        <th> S.No</th>
                        <th> Date</th>
                        <th>Booking ID</th>
                        <th>Total Credits</th>
                        <th>Credits Amount</th>
                        <th>Debit Amount</th>
                        <th>Transaction Type</th>
                        <th>Used</th>
                        <th>Available</th>
                      </tr>

                      {creditLog?.length > 0 ? (
                        <>
                          {loader ? (
                            <Loader size="4em" color="#016937" />
                          ) : (
                            <>
                              {creditLog?.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {textDateFormatter(item?.payment_date)}
                                    </td>
                                    <td>{item?.booking_id}</td>
                                    <td>{item?.total_credits}</td>
                                    <td>{item?.credit_amount}</td>
                                    <td>{item?.debit_amount}</td>
                                    <td>{item?.trans_type}</td>
                                    <td>{item?.used_credit_limit}</td>
                                    <td>{item?.available_credit_limit}</td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-end p_20">No Data Found</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      )}
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AgentCreditLogs;
