import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { calculateToDatePlus } from "../../../utils/utils";
import "../CustomSearchFormHolidays/CustomSearchFormHolidays.css";
import Flatpickr from "react-flatpickr";
import NewHeaderBg from "../../NewHeaderBg/NewHeaderBg";
import GooglePlaceApi from "../../GoogleApi/GooglePlaceApi/GooglePlaceApi";
import SearchStateInput from "../../SearchSateInput/SearchStateInput";
import Header from "../../Header/Header";
import NewSearchSateInput from "../../NewSearchSateInput/NewSearchSateInput";

function NewHotelsSearchForm({ topRadios }) {
  const [dataFromChild, setDataFromChild] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [bookingData, setBookingData] = useState({});

  // const location = useLocation();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      // hotelID: params.get("hotelID"),
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      checkoutDay: params.get("checkoutDay"),
      checkoutMonth: params.get("checkoutMonth"),
      checkoutYear: params.get("checkoutYear"),
      radio: params.get("radio"),
      childAge: childAges, // Assign the array of child ages
    };
    // console.log(hotelData, "data hotel")
    setBookingData(data);
    // postBookingData(data);
  }, [location.search]);

  const getDataFromChild = (data, resultArray, selectedType) => {
    setDataFromChild(data);
    setSelectedType(selectedType);
  };
  // console.log(dataFromChild, "getDataFromChild");

  const handleStartDateChange = ([date]) => {
    setStartDate(date);
    // Automatically set end date to the selected start date if it's not already set
    if (!endDate || date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = ([date]) => {
    setEndDate(date);
  };

  const [guestDetails, setIsguestDetails] = useState(false);
  const [destinationToggle, setdestinationToggle] = useState(false);
  const [selectDates, setselectDates] = useState(false);

  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };

  const queryParams = new URLSearchParams(location.search);
  const checkinDay = queryParams.get("checkinDay") || "";
  const checkinMonth = queryParams.get("checkinMonth") || "";
  const checkinYear = queryParams.get("checkinYear") || "";
  const checkoutDay = queryParams.get("checkoutDay") || "";
  const checkoutMonth = queryParams.get("checkoutMonth") || "";
  const checkoutYear = queryParams.get("checkoutYear") || "";

  const [checkin, setCheckin] = useState({
    day: checkinDay,
    month: checkinMonth,
    year: checkinYear,
  });

  const [checkout, setCheckout] = useState({
    day: checkoutDay,
    month: checkoutMonth,
    year: checkoutYear,
  });
  const navigate = useNavigate();

  // STATES
  const [date, setDate] = useState(new Date());
  const [selectedRadio, setSelectedRadio] = useState("s");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [data, setData] = useState({ fromCity: "", toCity: "" });

  const showErrorToast = (message) => {
    alert(message); // You can use a better toast notification library
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(calculateToDatePlus(new Date(), 1));

  //
  const [adult, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);

  // Date alterations
  const [loader, setLoader] = useState(false);

  // const [checkin, setCheckin] = useState({
  //   day: router.query.checkinDay || "",
  //   month: router.query.checkinMonth || "",
  //   year: router.query.checkinYear || "",
  // });

  // const [checkout, setCheckout] = useState({
  //   day: router.query.checkoutDay || "",
  //   month: router.query.checkoutMonth || "",
  //   year: router.query.checkoutYear || "",
  // });

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };

  // Effect to add click event listener when guestDetails is true
  useEffect(() => {
    if (guestDetails) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [guestDetails]);

  useEffect(() => {
    if (
      checkinDay &&
      checkinMonth &&
      checkinYear &&
      checkoutDay &&
      checkoutMonth &&
      checkoutYear
    ) {
      const formatDateToString = (year, month, day) => {
        const paddedMonth = String(month).padStart(2, "0");
        const paddedDay = String(day).padStart(2, "0");
        return `${year}-${paddedMonth}-${paddedDay}`;
      };

      const checkInDateString = formatDateToString(
        checkinYear,
        checkinMonth,
        checkinDay
      );
      const checkOutDateString = formatDateToString(
        checkoutYear,
        checkoutMonth,
        checkoutDay
      );

      const checkInDate = new Date(checkInDateString);
      const checkOutDate = new Date(checkOutDateString);

      setStartDate(checkInDate);
      setEndDate(checkOutDate);
    }
  }, [
    checkinDay,
    checkinMonth,
    checkinYear,
    checkoutDay,
    checkoutMonth,
    checkoutYear,
  ]);

  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = children - 1;
    setChildren(updatedChildren);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = children + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const onAdultIncrease = () => {
    const newAdult = adult + 1;
    setAdults(newAdult);
    if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
      setRooms(rooms + 1);
    }
  };

  const onAdultDecrease = () => {
    if (adult > 1) {
      const newAdult = adult - 1;
      setAdults(newAdult);
      if (newAdult % 2 !== 0 && rooms > 1) {
        setRooms(rooms - 1);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const handleSubmitButtonClickHandler = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      showErrorToast("Fill required detail first");
      return;
    }

    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    setLoader(true);

    try {
      await navigate(
        `/listing-hotels?hotelID=1&location=${dataFromChild}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkinDay=${new Date(
          startDate
        ).getDate()}&checkinMonth=${
          new Date(startDate).getMonth() + 1
        }&checkinYear=${new Date(
          startDate
        ).getFullYear()}&checkoutDay=${new Date(
          endDate
        ).getDate()}&checkoutMonth=${
          new Date(endDate).getMonth() + 1
        }&checkoutYear=${new Date(
          endDate
        ).getFullYear()}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&radio=${selectedRadio}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  // console.log("selectedType", selectedType);

  const handleSubmitButtonClickHandlerHotels = async (e) => {
    let hasChildAgeError = false;
    e.preventDefault();
    try {
      await navigate(
        // `/single-hotel/${selectedType?.id}?hotelId=${selectedType?.id}&location=${selectedType.location}&room=1&adult=2&children=0&checkinDay=${checkinDay}&checkinMonth=${checkinMonth}&checkinYear=${checkinYear}&checkoutDay=${checkoutDay}&checkoutMonth=${checkoutMonth}&checkoutYear=${checkoutYear}&hotel_name=${selectedType.name}&hotel_address=${selectedType.location}`,
        `/single-hotel/${selectedType?.hotel_slug}?hotelId=${
          selectedType?.id
        }&location=${
          selectedType?.location
        }&room=${rooms}&adult=${adult}&children=0&&checkinDay=${new Date(
          startDate
        ).getDate()}&checkinMonth=${
          new Date(startDate).getMonth() + 1
        }&checkinYear=${new Date(
          startDate
        ).getFullYear()}&checkoutDay=${new Date(
          endDate
        ).getDate()}&checkoutMonth=${
          new Date(endDate).getMonth() + 1
        }&checkoutYear=${new Date(endDate).getFullYear()}&hotel_name=${
          selectedType?.name
        }&hotel_address=${selectedType?.location}`
        // single-hotel/1?hotelId=1&location=Shimla&room=1&adult=2&children=0&checkinDay=4&checkinMonth=10&checkinYear=2024&checkoutDay=5&checkoutMonth=10&checkoutYear=2024&hotel_name=Golden%20Fern%20Resort%20by%20Eco%20Hospitality&hotel_address=Kachi%20Ghatti,%20Shimla,%20Himachal%20Pradesh%20171010
      );
      // const url = `/single-hotel/${selectedType?.id}?hotelId=${selectedType?.id}&location=${selectedType.location}&room=1&adult=1&children=0&checkinDay=${selectedType.checkinDay}&checkinMonth=${selectedType.checkinMonth}&checkinYear=${selectedType.checkinYear}&checkoutDay=${selectedType.checkoutDay}&checkoutMonth=${selectedType.checkoutMonth}&checkoutYear=${selectedType.checkoutYear}&hotel_name=${selectedType.hotel_name}&hotel_address=${selectedType.hotel_address}`;

      // Open the new tab with the URL
      // window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (destinationToggle) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [destinationToggle]);

  return (
    <>
      <Header />
      <section className="NewSearch-BG">
        <div className="container">
          {/* <NewHeaderBg /> */}
          <div className="top-banner-img">
            <div
              className="NewSearch-header"
              id="width-NewSearch"
              style={
                guestDetails || destinationToggle
                  ? { zIndex: "9999" }
                  : { position: "" }
              }
            >
              {" "}
              <h1 className="text-center">Himachal’s Hotel & Stays</h1>
              <p className="common_text">Book Hotel & Stays in Himachal</p>
              <div
                className="NewSearch-Main search_form"
                style={
                  guestDetails || destinationToggle
                    ? { zIndex: "999" }
                    : { position: "" }
                }
              >
                <form
                  className="search_form_main"
                  onSubmit={
                    selectedType?.type === "hotel"
                      ? handleSubmitButtonClickHandlerHotels
                      : handleSubmitButtonClickHandler
                  }
                >
                  <button className="hide_radio_btn">
                    <span>All</span>
                    <svg
                      fill="#BB735D"
                      height="10px"
                      width="10px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 330 330"
                      xmlSpace="preserve"
                    >
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  </button>

                  <div className="input_search_top search_data_home">
                    <div className="search_form_div NewSearch-tags">
                      <label htmlFor="">
                        <small>City/Destination/Hotel</small>{" "}
                      </label>
                      {/* <SearchStateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        setIsguestDetails={setIsguestDetails}
                      /> */}
                      <NewSearchSateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        setIsguestDetails={setIsguestDetails}
                        type="hotel"
                      />
                    </div>

                    <div
                      className="search_form_div w-100"
                      onClick={() => [
                        setStartDate(null),
                        setdestinationToggle(true),
                      ]}
                      setStartDate={setStartDate}
                    >
                      <label>Check In</label>
                      <Flatpickr
                        className="search_form_input"
                        value={startDate}
                        onChange={([date1, date2]) => {
                          setStartDate(date1);
                          setEndDate(date2);
                        }}
                        options={{ mode: "range", minDate: "today" }}
                        required
                      />
                    </div>
                    <div className="search_form_div w-100">
                      <label>Check Out</label>
                      <Flatpickr
                        className="search_form_input"
                        value={endDate}
                        onChange={([date1, date2]) => {
                          setStartDate(date1);
                          setEndDate(date2);
                        }}
                        options={{ mode: "range", minDate: "today" }}
                        required
                      />
                      {/* <Flatpickr
                        className="search_form_input"
                        value={endDate}
                        onChange={([date1, date2]) => {
                          setStartDate(date1);
                          setEndDate(date2);
                        }}
                        options={{ mode: "range", minDate: "today" }}
                        required
                      /> */}
                    </div>
                    <div className="search_form_div NewSearch-tags icon_button_only">
                      <label
                        onClick={dropdownGuestDetails}
                        className="search_label"
                      >
                        Room & Guests{" "}
                        <svg
                          width="9"
                          height="5"
                          viewBox="0 0 9 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 0.75L4.75 4.25L1.25 0.75"
                            stroke="#BB735D"
                          />
                        </svg>
                      </label>
                      <p
                        style={{ position: "relative" }}
                        className="dropdown-toggle search_form_input"
                      >
                        <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                          <d iv className="optionCounter"></d>
                          <span className="font_16" id="adult_count">
                            <b> {adult}</b>
                          </span>
                          <span className="font_12"> Adults,</span>{" "}
                          <span className="font_16" id="room_count">
                            <b> {rooms}</b>
                          </span>
                          <span className="font_12"> Rooms, </span>{" "}
                          <span className="font_16" id="child_count">
                            <b> {children}</b>
                          </span>
                          <span className="font_12"> Children</span>
                        </div>
                        {guestDetails && (
                          <div
                            className="guest_details_dropdown"
                            id="guestDetailsPopup"
                          >
                            <div className="agent">
                              <span>Adult</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={adult <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onAdultDecrease}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={adult}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onAdultIncrease}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="agent">
                              <span>Rooms</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => setRooms(Number(rooms) - 1)}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={rooms}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => setRooms(Number(rooms) + 1)}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="agent">
                              <span>Children</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={children <= 0}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildDecrease}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={children}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={children <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildIncrease}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>

                            {childAge.map((age, index) => (
                              // <div className="optionItem" key={index}>
                              //   <span className="optionText">Child {index + 1} Age</span>
                              //   <div className="optionCounter">
                              //     <input
                              //       className="childAge_input"
                              //       type="number"
                              //       name="childAge[]"
                              //       min="1"
                              //       value={age}
                              //       onChange={(event) =>
                              //         handleChildrenAgeChange(index, event)
                              //       }
                              //       required
                              //     />
                              //   </div>
                              // </div>
                              <div className="navbar agent">
                                <div>
                                  <span>Child Age</span>
                                </div>
                                <div>
                                  <input
                                    className="childAge_input"
                                    type="number"
                                    name="childAge[]"
                                    min="1"
                                    value={age}
                                    onChange={(event) =>
                                      handleChildrenAgeChange(index, event)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </p>
                    </div>

                    <div className="">
                      <button type="submit" className="search-btn m_0">
                        SEARCH
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>

      {(guestDetails || destinationToggle || selectDates) && (
        <div
          onClick={() => [
            setIsguestDetails(false),
            setdestinationToggle(false),
            setselectDates(false),
          ]}
          className="ScreenDimmer"
        ></div>
      )}
    </>
  );
}

export default NewHotelsSearchForm;
