import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ThankYouPage = () => {
  const [orderDetails, setOrderDetails] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    async function fetchOrderId() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/new/check/payment/${id}/hotel`
        );
        const data = await response.json();
        // console.log(data);

        setOrderDetails(data);
      } catch (error) {
        console.error("Error fetching order ID:", error);
      }
    }
    fetchOrderId();
  }, []);

  //   const purchaseamount = parseFloat(orderDetails.amount);
  const getPaymentStatus = () => {
    const balance = parseFloat(orderDetails?.booking?.balance_amount);
    const total = parseFloat(orderDetails?.booking?.grand_total);

    if (balance === 0) {
      return "Paid";
    } else if (balance > 0 && balance < total) {
      return "Partly Paid";
    } else {
      return "Unpaid";
    }
  };

  const payment_status = getPaymentStatus();

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {orderDetails && (
              <div className="thankyou_main mt-20">
                <div className="text-center">
                  {/* <Image src="/icons/econew.svg" width={160} height={60} alt="Eco Logo"></Image> */}
                  <h1 className="mt-3">Booking Confirmation!</h1>
                </div>
                <div className="mt-20 text-center">
                  <svg
                  style={{transform:"rotate(20deg)"}}
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <g
                      fill="#11a135"
                      fill-rule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style={{mixBlendMode: "normal"}}
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                {/* <h3>Dear {orderDetails.booking.booked_from},</h3> */}
                <p className="mt-20">
                  We are thrilled to inform you that your booking has been
                  successfully confirmed. On behalf of the entire team at we
                  would like to express our sincere gratitude for choosing our
                  services.
                </p>
                <p>
                  We understand that your trust and confidence in us are
                  paramount, and we assure you that we will go above and beyond
                  to ensure your stay is nothing short of extraordinary.
                </p>
                <p>Here are the payment details for your reference:</p>
                <div className="payment-details mt-10">
                  <p>
                    <strong>Payment Status:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {payment_status ? payment_status : ""}
                  </p>
                  <p>
                    <strong>Ref Booking ID:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails.booking.unique_booking_id}
                  </p>
                  <p>
                    <strong>Total Amount:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails.booking.grand_total}
                  </p>
                  <p>
                    <strong>Payment Method:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails.gatewayMethod}
                  </p>
                  <p>
                    <strong>Transaction ID:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails.gatewayPaymentId}
                  </p>
                </div>
                <div className="text-center mt-20">
                  <Link to={"/"} className="btn-lnk">
                    <span>Back to Home</span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYouPage;
