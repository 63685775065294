import React, { useState, useEffect, useCallback } from "react";
import Footer from "../Footer/Footer";
import PageLinks from "../PageLinks/PageLinks";
import SingleSearchForm from "../SingleSearchForm/SingleSearchForm";
import Header from "../Header/Header";
import OurQuery from "../OurQuery/OurQuery";
import ChooseUs from "../ChooseUs/ChooseUs";
import SendItinerary from "../SendItinerary/SendItinerary";
import CustomSlider from "../CustomSlider/CustomSlider";
import { DisabledTextButton } from "../Buttons/Buttons";
import StarRating from "../StarRating/StarRating";
import { Link } from "react-router-dom";
import carImg from "../../assets/images/car.png";
import OverviewContent from "../CustomTabHeader/OverviewContent";
import ItineraryContent from "../CustomTabHeader/ItineraryContent";
import AccommodationContent from "../CustomTabHeader/AccommodationContent";
import InclusionExclusionContent from "../CustomTabHeader/InclusionExclusionContent";

import TabHeader from "../CustomTabHeader/TabHeader";
import SinglePackageHeader from "../SinglePackageHeader/SinglePackageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { showSuccessToast } from "../../assets/toastUtils";
import Cancellation from "../CustomTabHeader/Cancellation";
import PointRemember from "../CustomTabHeader/PointRemember";
import PackageDetails from "../ButtonToDownload/PackageDetails";
import Gallery from "../CustomTabHeader/Gallery";
import {
  calculateToDatePlus,
  calculateTotalWithReduce,
  currentDateSingle,
  formatIndianCurrency,
} from "../../utils/utils";
import CustomizableAccommodation from "../CustomTabHeader/CustomizableAccommodation";
import TabHeaderCustomizable from "../CustomTabHeader/TabHeaderCustomizable";
import Loader from "../Loader/Loader";
import SmallLoader from "../Loader/SmallLoader";
import NewCustomizableAccommodation from "../CustomTabHeader/NewCustomizableAccommodation";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";

function NewCustomizeSinglePackage() {
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [showRatePopup, setshowRatePopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(
    JSON.parse(localStorage.getItem("selected")) || null
  );

  const [minusTransportationCost, setMinusTransportationCost] = useState(0);

  const [removedVehicle, setRemovedVehicle] = useState(null);

  const [daySelected, setDaySelected] = useState("");

  const [isCustomizedClick, setIsCustomizedClick] = useState(false);

  const [isVehicleCustomizedClick, setIsVehicleCustomizedClick] =
    useState(false);

  const [selectedAddons, setSelectedAddons] = useState([]);
  const [isAddActivityClick, setIsAddActivityClick] = useState(false);
  const [addActivityIdClick, setAddActivityIdClick] = useState(null);

  // new flow

  const [oldVehicleCost, setOldVehicleCost] = useState(0);
  const [newVehicleCost, setNewVehicleCost] = useState(0);

  const [basePriceOfPackage, setBasePriceOfPackage] = useState(0);

  const [customizableRatesLoader, setCustomizableRatesLoader] = useState(false);

  const [calculationData, setCalculationData] = useState(null);

  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [allActivities, setAllActivities] = useState([]);

  const navigate = useNavigate();

  const [hotelDayByDay, setHotelDayByDay] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [ratesOfPackages, setRatesOfPackages] = useState(null);
  const [customizedRatesOfPackages, setCustomizedRatesOfPackages] =
    useState(null);
  const [selectedHotelPrice, setSelectedHotelPrice] = useState(null);
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(2);
  const [childAge, setChildAge] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [singlePackageData, setSinglePackageData] = useState({});

  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };
  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };

  function getDatesInArray(startDate, numberOfDays) {
    const resultArray = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < numberOfDays; i++) {
      resultArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return resultArray;
  }
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const storedData = JSON.parse(localStorage.getItem("selected"));
  const agentDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (storedData) {
      setData(selectedHotelId);
    }
  }, [localStorage]);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const [activeTab, setActiveTab] = useState("package-information");
  const getHotelDayByDay = (data) => {
    setHotelDayByDay(data);
  };

  const getUpdatedBasePrice = (array, basePrice) => {
    const oldRoomRateTotal = calculateTotalWithReduce(array, "oldRate");
    const newRoomRateTotal = calculateTotalWithReduce(array, "newRate");

    // console.log();

    // console.log(basePrice, oldRoomRateTotal, newRoomRateTotal);

    if (oldRoomRateTotal === 0 || newRoomRateTotal === 0) {
      return basePrice;
    }

    return basePrice - oldRoomRateTotal + newRoomRateTotal;
  };

  const renderContent = () => {
    switch (activeTab) {
      case "package-information":
        return singlePackageData?.canCustomise ? (
          <NewCustomizableAccommodation
            singlePackageData={singlePackageData}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            getHotelDayByDay={getHotelDayByDay}
            getAccommodationDetails={getAccommodationDetails}
            setIsCustomizedClick={setIsCustomizedClick}
            setCustomizableRatesLoader={setCustomizableRatesLoader}
            setCalculationData={setCalculationData}
            packageStartDate={"startDate"}
            bookingData={bookingData}
            ratesOfPackages={ratesOfPackages}
            fetchTransportCost={fetchTransportCost}
            vehicleDetails={vehicleDetails}
            setIsVehicleCustomizedClick={setIsVehicleCustomizedClick}
            isVehicleCustomizedClick={isVehicleCustomizedClick}
            fetchTransportsArray={fetchTransportsArray}
            fetchCostOfVehicle={fetchCostOfVehicle}
            allVehicles={allVehicles}
            changeSelectedVehicle={changeSelectedVehicle}
            calculateSingleHotelDetails={calculateSingleHotelDetails}
            fetchHotelData={fetchHotelData}
            fetchMelPlanPrice={fetchMelPlanPrice}
            daySelected={daySelected}
            setDaySelected={setDaySelected}
            hotelDayByDay={hotelDayByDay}
            setHotelDayByDay={setHotelDayByDay}
            // vehicles
            setOldVehicleCost={setOldVehicleCost}
            setNewVehicleCost={setNewVehicleCost}
            setVehicleDetails={setVehicleDetails}
            setMinusTransportationCost={setMinusTransportationCost}
            setRatesOfPackages={setRatesOfPackages}
            setRemovedVehicle={setRemovedVehicle}
            calculationsOfCustomizedPackage={calculationsOfCustomizedPackage}
            //activities
            allActivities={allActivities}
            setIsAddActivityClick={setIsAddActivityClick}
            isAddActivityClick={isAddActivityClick}
            fetchActivitiesArray={fetchActivitiesArray}
            changeSelectedActivity={changeSelectedActivity}
            setAddActivityIdClick={setAddActivityIdClick}
            addActivityIdClick={addActivityIdClick}
            handleRemoveActivity={handleRemoveActivity}
          />
        ) : (
          <OverviewContent singlePackageData={singlePackageData} />
        );
      case "package-itinerary":
        return (
          <ItineraryContent
            singlePackageData={singlePackageData}
            bookingData={bookingData}
            customized={true}
          />
        );
      case "package-hotels":
        return (
          <AccommodationContent
            singlePackageData={singlePackageData}
            setSelectedCategory={setSelectedCategory}
          />
        );
      case "package-destinations":
        return (
          <InclusionExclusionContent singlePackageData={singlePackageData} />
        );
      case "package-cancellation":
        return <Cancellation singlePackageData={singlePackageData} />;
      case "package-remember":
        return <PointRemember singlePackageData={singlePackageData} />;
      case "package-gallery":
        return <Gallery singlePackageData={singlePackageData} />;
      default:
        return null;
    }
  };

  // new flow

  const fetchMelPlanPrice = async (
    data,
    hotel_id,
    mealPlan,
    checkIn,
    checkOut,
    selectedDay,
    roomId
  ) => {
    // Assuming setLoader is a state setter for loading indicator

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    // const data = {
    //   hotelId: selectedHotelId,
    //   // location: "shimla",
    //   // room: 3,
    //   // adult: 1,
    //   // children: 1,
    //   // childAge: [7],
    //   // checkinDay: 28,
    //   // checkinMonth: 7,
    //   // checkinYear: 2024,
    //   // checkoutDay: 29,
    //   // checkoutMonth: 7,
    //   // checkoutYear: 2024,
    // };

    // setBookingData(data);

    const roomsData = getRoomsExtraAdults(guest, childAge);

    const formdata = new FormData();
    formdata.append("hotelId", hotel_id);
    formdata.append("rooms", roomsData.noOfRooms);
    formdata.append("checkIn", checkIn);
    formdata.append("checkOut", checkOut);
    // formdata.append("location", data.location);
    // formdata.append("PropertyType", "hotel");
    formdata.append("adults", data?.guest);
    formdata.append("child", data?.children);

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 0; i < data.children; i++) {
        formdata.append(`childAge[${i}]`, data.childAge[i]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      const roomRates = result?.data?.rooms.find(
        (room) => room.room_id === roomId
      )?.rates;

      const findRateObject = roomRates.find(
        (rate) => rate.meal_plan.toLowerCase() === mealPlan[0].toLowerCase()
      );

      const findSelectedDay = hotelDayByDay.find(
        (day) => day.dayCount === selectedDay
      );

      // Create a copy of hotelDayByDay and update the selected day's data
      const updatedHotelDayByDay = hotelDayByDay?.map((day) => {
        if (day.accommodationId === findSelectedDay?.accommodationId) {
          return {
            ...day,
            // ...data,
            oldRate: findRateObject.rate_per_night,
          };
        }
        return day;
      });

      // Update the state with the modified array
      setHotelDayByDay(updatedHotelDayByDay);

      // setHotelDayByDay((prevData) => {
      //   const updatedData = [...prevData];
      //   updatedData[selectedDay] = {
      //     ...updatedData[selectedDay], // Spread the existing object at index i
      //     oldRate: findRateObject.rate_per_night, // Merge the fetched details into the object at index i
      //   };
      //   return updatedData;
      // });

      return findRateObject;
      // setHotelData(result.data); // Assuming setHotelData is a state setter for hotel data
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false); // Hide loader after request completion
    }
  };

  const selectedCategorySend = (category) => {
    // setActiveTab(category);
  };

  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      departureDay: params.get("checkinDay"),
      departureMonth: params.get("checkinMonth"),
      departureYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      packageId: params.get("packageId"),
      packagehandle: params.get("packagehandle"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
      },
      // body: raw,

      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/single/${data?.packagehandle}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSinglePackageData(data?.data);
        setSelectedCategory(
          data?.data?.packageHotels[0]?.accommodationCategory
        );

        // navigate("/");
        // window.location.href = "/";
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );
    const checkInDate = `${bookingData.departureYear}-${String(
      bookingData.departureMonth
    ).padStart(2, "0")}-${String(bookingData.departureDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
    });

    setGuest(data.guest);
    setChildren(data.children);
    setRooms(data.room);
    setChildAge(childAges);

    if (data.departureDay && data.departureMonth && data.departureYear) {
      setStartDate(checkInDate);
    } else {
      setStartDate(calculateToDatePlus(currentDateSingle, 5));
    }

    // setStartDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);
  }, [location.search]);

  // console.log("bookingData", bookingData);

  const getRatesFromApi = async (packageId, pax, selectedCategory) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const formdata = new FormData();
    formdata.append("package_id", packageId);
    formdata.append("mealPlan", "map");
    formdata.append("no_pax", pax == 1 ? 2 : pax);
    formdata.append("hotelCategory", selectedCategory);
    formdata.append("children", children);
    formdata.append("childAge", childAge);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/rate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRatesOfPackages(result?.data);
        setBasePriceOfPackage(result?.data?.Price);
        fetchTransportCost(result?.data?.VehicleType);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (singlePackageData?.packageId && bookingData?.guest) {
      getRatesFromApi(
        singlePackageData?.packageId,
        bookingData?.guest,
        selectedCategory
      );
    }
  }, [singlePackageData, bookingData?.guest, selectedCategory]);

  function calculateTotalAmount(
    basePrice = 0,
    discount = 0,
    discountType = "fixed",
    guest = 0,
    ratesOfPackages,
    childAgeArray,
    transportationCost,
    minusTransportationCost
  ) {
    const childWithAgeGreaterThanSix = childAgeArray.filter(
      (age) => age > 6
    )?.length;

    const perPaxCount =
      Number(bookingData.guest) +
      childWithAgeGreaterThanSix +
      ratesOfPackages?.ExtraAdultCount;

    guest = Number(guest);
    basePrice = Number(basePrice);

    if (minusTransportationCost) {
      basePrice -= minusTransportationCost / guest;
    }

    discount = Number(discount);

    let startingPrice = basePrice;

    basePrice = basePrice * guest;
    let onlyAdultCost = startingPrice * guest;

    let adultCosts = 0;
    if (ratesOfPackages?.ExtraAdultCount) {
      adultCosts =
        Number(ratesOfPackages?.ExtraAdultCount) *
        Number(ratesOfPackages?.ExtraAdult);

      const price = basePrice + adultCosts;
      basePrice = basePrice + adultCosts;
    }

    let childCosts = 0;
    if (childAgeArray?.length > 0) {
      childAgeArray.forEach((age) => {
        if (Number(age) > 6) {
          childCosts += Number(ratesOfPackages?.ChildWithBedCost);
          // basePrice += childCosts;

          // childCosts += childCost;
        }
      });
    }
    if (childCosts) {
      basePrice += childCosts;
    }

    //     if(minusTransportationCost) {

    //   basePrice -= minusTransportationCost
    //   onlyAdultCost -= minusTransportationCost
    // }

    // transportation cost
    // transportationCost = Number(transportationCost);
    // basePrice += transportationCost;

    let discountAmount;
    if (discountType === "percentage") {
      discountAmount = (basePrice * discount) / 100;
    } else {
      discountAmount = discount;
    }

    const priceAfterDiscount = basePrice - discountAmount;

    const gst = priceAfterDiscount * 0.05;

    const totalAmount = priceAfterDiscount + gst;

    const discountPercentage = (discountAmount / basePrice) * 100;

    return {
      basePrice: basePrice.toFixed(2),
      discount: discountAmount.toFixed(2),
      priceAfterDiscount: priceAfterDiscount.toFixed(2),
      gst: gst.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      discountPercentage: discountPercentage,
      taxAmount: gst.toFixed(2),
      ExtraAdultCost: adultCosts,
      childCosts,
      adultNumber: guest,
      onlyAdultCost: onlyAdultCost,
      extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      extraAdultCost: adultCosts,
      childNumber: childAgeArray?.length,
      transportationCost: transportationCost,
      perPaxCount,
      perPaxCost: (totalAmount / perPaxCount).toFixed(2),
    };
  }
  function calculateTotalAmountCustomize(
    basePrice = 0,
    discount = 0,
    discountType = "fixed",
    guest = 0,
    ratesOfPackages,
    childAgeArray,
    transportationCost = 0,
    minusTransportationCost = 0,
    removedVehicle,
    activities,
    selectedAddons
  ) {
    // console.log("activities", activities);

    // setTotalCosts(costs);

    // console.log("basePrice", basePrice);

    const childWithAgeGreaterThanSix = childAgeArray.filter(
      (age) => age > 6
    )?.length;

    const perPaxCount =
      Number(bookingData.guest) +
      childWithAgeGreaterThanSix +
      ratesOfPackages?.ExtraAdultCount;

    let totalBaseCost = 0;
    let addonsCost = 0;

    const result = getRoomsExtraAdults(guest, childAgeArray);

    minusTransportationCost = Number(minusTransportationCost);
    guest = Number(guest);

    basePrice = Number(basePrice);

    discount = Number(discount);

    let startingPrice = basePrice;

    basePrice = basePrice * guest;

    let adultCosts = 0;

    addonsCost =
      selectedAddons && selectedAddons.length > 0
        ? selectedAddons.reduce((total, addon) => {
            return Number(total) + Number(addon?.addonPrice);
          }, 0)
        : 0;

    // console.log("addonsCost", addonsCost);

    const singleTotalPackageCost = activities?.reduce((total, day) => {
      const dayCost = day?.activity
        ? day.activity.reduce(
            (sum, activity) =>
              (Number(sum) + Number(activity?.activity_price)) *
              Number(activity?.guest),
            0
          )
        : 0;
      return total + dayCost;
    }, 0);

    // setTotalActivityCost(totalCost);

    const totalActivityCost = singleTotalPackageCost;

    // console.log("totalActivityCost", totalActivityCost);

    if (ratesOfPackages?.ExtraAdultCount) {
      adultCosts =
        Number(ratesOfPackages?.ExtraAdultCount) *
        Number(ratesOfPackages?.ExtraAdult);

      const price = basePrice + adultCosts;
      basePrice = basePrice + adultCosts;
    }

    let childCosts = 0;
    if (childAgeArray?.length > 0) {
      childAgeArray.forEach((age) => {
        if (Number(age) > 6) {
          childCosts += Number(ratesOfPackages?.ChildWithBedCost);
          // basePrice += childCosts;

          // childCosts += childCost;
        }
      });
    }
    if (childCosts) {
      basePrice += childCosts;
    }

    transportationCost = Number(transportationCost);
    // basePrice += transportationCost;

    let onlyAdultCost = startingPrice * guest;

    if (totalActivityCost) {
      // onlyAdultCost += totalActivityCost
      basePrice += totalActivityCost;
    }
    if (addonsCost) {
      // onlyAdultCost += totalActivityCost
      basePrice += addonsCost;
    }

    if (minusTransportationCost) {
      // if(transportationCost){
      //   onlyAdultCost += transportationCost
      //   basePrice += transportationCost
      // }
      //
      basePrice -= minusTransportationCost;
      // onlyAdultCost += transportationCost
      onlyAdultCost -= minusTransportationCost;
    }

    // if (removedVehicle) {
    //   basePrice -= removedVehicle?.Price ;
    //   // basePrice += transportationCost;
    //   onlyAdultCost -= removedVehicle?.Price;

    // }

    totalBaseCost = basePrice + transportationCost;

    let discountAmount;
    if (discountType === "percentage") {
      discountAmount = (totalBaseCost * discount) / 100;
    } else {
      discountAmount = discount;
    }

    const priceAfterDiscount = totalBaseCost - discountAmount;

    const gst = priceAfterDiscount * 0.05;

    const totalAmount = priceAfterDiscount + gst;

    const discountPercentage = (discountAmount / totalBaseCost) * 100;

    return {
      basePrice: basePrice.toFixed(2),
      basePriceAfterTransportCost: totalBaseCost.toFixed(2),
      discount: discountAmount.toFixed(2),
      priceAfterDiscount: priceAfterDiscount.toFixed(2),
      gst: gst.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      discountPercentage: discountPercentage,
      taxAmount: gst.toFixed(2),
      ExtraAdultCost: adultCosts,
      childCosts,
      adultNumber: guest,
      onlyAdultCost: onlyAdultCost,
      // extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      extraAdultCost: adultCosts,
      childNumber: childAgeArray?.length,
      transportationCost,
      extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      perPaxCount,
      perPaxCost: (totalAmount / perPaxCount).toFixed(2),
      totalActivityCost,
      addonsCost,
    };
  }

  const calculationsOfPackages = calculateTotalAmount(
    minusTransportationCost ? ratesOfPackages?.Price : ratesOfPackages?.Price,
    agentDetails?.agent_category_discount
      ? agentDetails?.agent_category_discount
      : 0,
    agentDetails?.agent_discount_type
      ? agentDetails?.agent_discount_type
      : "percentage",
    ratesOfPackages?.NoPax,
    ratesOfPackages,
    bookingData?.childAge ? bookingData?.childAge : [],
    vehicleDetails && vehicleDetails.length > 0 && vehicleDetails[0].Price
      ? Number(vehicleDetails[0].Price)
      : 0,
    minusTransportationCost
  );

  const newBasePrice = getUpdatedBasePrice(
    hotelDayByDay,
    ratesOfPackages?.Price
  );

  const calculationsOfCustomizedPackage = calculationData
    ? calculateTotalAmountCustomize(
        newBasePrice,
        // calculationData?.total_rates?.subtotal,
        agentDetails?.agent_category_discount
          ? agentDetails?.agent_category_discount
          : 0,
        agentDetails?.agent_discount_type
          ? agentDetails?.agent_discount_type
          : "percentage",
        ratesOfPackages?.NoPax,
        ratesOfPackages,
        bookingData?.childAge ? bookingData?.childAge : [],
        // vehicleDetails && vehicleDetails.length > 0 && vehicleDetails[0].Price
        //   ? Number(vehicleDetails[0].Price)
        //   : 0
        newVehicleCost - oldVehicleCost,
        minusTransportationCost,
        removedVehicle,
        hotelDayByDay,
        selectedAddons
      )
    : calculateTotalAmountCustomize(
        newBasePrice,
        // calculationData?.total_rates?.subtotal,
        agentDetails?.agent_category_discount
          ? agentDetails?.agent_category_discount
          : 0,
        agentDetails?.agent_discount_type
          ? agentDetails?.agent_discount_type
          : "percentage",
        ratesOfPackages?.NoPax,
        ratesOfPackages,
        bookingData?.childAge ? bookingData?.childAge : [],
        // vehicleDetails && vehicleDetails.length > 0 && vehicleDetails[0].Price
        //   ? Number(vehicleDetails[0].Price)
        //   : 0
        newVehicleCost - oldVehicleCost,
        minusTransportationCost,
        removedVehicle,
        hotelDayByDay,
        selectedAddons
      );

  const navigateToCheckOutPackage = () => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");
    navigate(
      `/checkout-package/${bookingData?.packagehandle}?location=${bookingData?.location}&guest=${bookingData?.guest}&children=${bookingData?.children}${childAgeParams}&checkinDay=${bookingData?.departureDay}&checkinMonth=${bookingData?.departureMonth}&checkinYear=${bookingData?.departureYear}`,
      {
        state: {
          packageData: singlePackageData,
          bookingData: bookingData,
          selectedCategory: selectedCategory,
          calculationsOfPackages: isCustomizedClick
            ? calculationsOfCustomizedPackage
            : calculationsOfPackages,
          hotelDayByDay: hotelDayByDay,
          vehicleDetails: vehicleDetails,
          customized: true,
        },
      }
    );
  };

  // const childAgeArray = [7, 8, 6];
  // const guests = 7;

  function getRoomsExtraAdults(guests, childAgeArray) {
    const allowedAdultsPerRoom = 2;
    let totalAdults = guests;

    childAgeArray.forEach((age) => {
      if (Number(age) > 5) {
        totalAdults++;
      }
    });

    let extraAdults = totalAdults % allowedAdultsPerRoom;
    const noOfRooms = Math.floor(totalAdults / allowedAdultsPerRoom);

    return { extraAdults, noOfRooms };
  }

  const fetchHotelData = async (
    data,
    hotel_id,
    mealPlan,
    checkIn,
    checkOut,
    roomId
  ) => {
    // Assuming setLoader is a state setter for loading indicator

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    // const data = {
    //   hotelId: selectedHotelId,
    //   // location: "shimla",
    //   // room: 3,
    //   // adult: 1,
    //   // children: 1,
    //   // childAge: [7],
    //   // checkinDay: 28,
    //   // checkinMonth: 7,
    //   // checkinYear: 2024,
    //   // checkoutDay: 29,
    //   // checkoutMonth: 7,
    //   // checkoutYear: 2024,
    // };

    // setBookingData(data);

    const roomsData = getRoomsExtraAdults(guest, childAge);

    const formdata = new FormData();
    formdata.append("hotelId", hotel_id);
    formdata.append("rooms", roomsData.noOfRooms);
    formdata.append("checkIn", checkIn);
    formdata.append("checkOut", checkOut);
    // formdata.append("location", data.location);
    // formdata.append("PropertyType", "hotel");
    formdata.append("adults", data?.guest);
    formdata.append("child", data?.children);

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 0; i < data.children; i++) {
        formdata.append(`childAge[${i}]`, data.childAge[i]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      const roomRates = result?.data?.rooms.find(
        (room) => room.room_id === roomId
      )?.rates;

      const findRateObject = roomRates.find(
        (rate) => rate.meal_plan.toLowerCase() === mealPlan[0].toLowerCase()
      );

      return findRateObject;
      // setHotelData(result.data); // Assuming setHotelData is a state setter for hotel data
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false); // Hide loader after request completion
    }
  };

  // const fetchHotelData = async (data, hotel_id) => {
  //   // Assuming setLoader is a state setter for loading indicator

  //   const today = new Date();
  //   const tomorrow = new Date(today);
  //   tomorrow.setDate(tomorrow.getDate() + 1);

  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");

  //   // const data = {
  //   //   hotelId: selectedHotelId,
  //   //   // location: "shimla",
  //   //   // room: 3,
  //   //   // adult: 1,
  //   //   // children: 1,
  //   //   // childAge: [7],
  //   //   // checkinDay: 28,
  //   //   // checkinMonth: 7,
  //   //   // checkinYear: 2024,
  //   //   // checkoutDay: 29,
  //   //   // checkoutMonth: 7,
  //   //   // checkoutYear: 2024,
  //   // };

  //   // setBookingData(data);

  //   const checkInDate = `${data.checkinYear}-${String(
  //     data.checkinMonth
  //   ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
  //   const checkOutDate = `${data.checkoutYear}-${String(
  //     data.checkoutMonth
  //   ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

  //   const formdata = new FormData();
  //   formdata.append("hotelId", hotel_id);
  //   formdata.append("rooms", data.room);
  //   formdata.append("checkIn", "2024-09-02");
  //   // formdata.append("checkOut", checkOutDate);
  //   // formdata.append("location", data.location);
  //   // formdata.append("PropertyType", "hotel");
  //   formdata.append("adults", data.guest);
  //   formdata.append("children", data.children);

  //   // Append childAge values dynamically
  //   if (data.children > 0) {
  //     for (let i = 0; i < data.children; i++) {
  //       formdata.append(`childAge[${i}]`, data.childAge[i]);
  //     }
  //   }

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   try {
  //     setLoader(true);
  //     const response = await fetch(
  //       "https://extranet.travcatalyst.com/api/v1/hotel-engine/check-availability",
  //       requestOptions
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     // setHotelData(result.data); // Assuming setHotelData is a state setter for hotel data
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   } finally {
  //     setLoader(false); // Hide loader after request completion
  //   }
  // };

  // useEffect(() => {

  //   fetchHotelData(bookingData, 1);
  // }, [bookingData]);

  async function getAccommodationDetails(loopData) {
    const accommodationDetails = [];

    for (let i = 0; i < loopData.length; i++) {
      const accommodationId = loopData[i].accommodationId;
      const accommodationMealPlan = loopData[i].accomodationMealPlan && [0];

      // const dateArray

      if (
        !bookingData.departureDay ||
        !bookingData.departureMonth ||
        !bookingData.departureYear
      ) {
      }

      const checkInDate = `${bookingData.departureYear}-${String(
        bookingData.departureMonth
      ).padStart(2, "0")}-${String(bookingData.departureDay).padStart(2, "0")}`;
      const checkOutDate = `${data.checkoutYear}-${String(
        data.checkoutMonth
      ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

      function getDatesInArray(startDate, numberOfDays) {
        const resultArray = [];
        let currentDate = new Date(startDate);

        for (let i = 0; i < numberOfDays; i++) {
          resultArray.push(currentDate.toISOString().split("T")[0]);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return resultArray;
      }

      const resultDateArray = getDatesInArray(checkInDate, 30);

      if (accommodationId) {
        try {
          const details = await fetchHotelData(
            bookingData,
            accommodationId,
            accommodationMealPlan,
            resultDateArray[i],
            resultDateArray[i + 1]
          );
          accommodationDetails[i] = details;

          setHotelDayByDay((prevData) => {
            const updatedData = [...prevData];
            updatedData[i] = {
              ...updatedData[i], // Spread the existing object at index i
              ...details, // Merge the fetched details into the object at index i
            };
            return updatedData;
          });
        } catch (error) {
          console.error(
            `Failed to fetch data for accommodation ID ${accommodationId}:`,
            error
          );
        }
      }
    }

    return accommodationDetails;
  }

  const calculateSingleHotelDetails = (
    selectedHotel,
    selectedRates,
    allArray,
    selectedDay,
    roomCategory
  ) => {
    // console.log("roomCategory", roomCategory);

    setSelectedHotelPrice(selectedRates.rate_per_night);

    const findSelectedDay = hotelDayByDay.find(
      (day) => day.dayCount === selectedDay.dayCount
    );
    // console.log("selectedRates", selectedRates);

    // Create a copy of hotelDayByDay and update the selected day's data
    const updatedHotelDayByDay = allArray?.map((day) => {
      if (Number(day.accommodationId) === Number(selectedHotel.hotel_id)) {
        return {
          ...day,
          // ...data,
          newRate: selectedRates.rate_per_night,
          accomodationRoomName: roomCategory,
          accomodationMealPlan: [selectedRates.meal_plan],
          plan: "iii",
          // ...day,
          // ...data,
          // accommodationName: selectedHotel?.hotel_name,
          // accommodationId: selectedHotel?.hotel_id,
          // accommodationStarRating: selectedHotel?.hotel_rating,
          // accommodationCity: selectedHotel?.hotel_city,
          // accommodationImage: selectedHotel?.hotel_image[0],
        };
      }
      return day;
    });

    // Update the state with the modified array
    setHotelDayByDay(updatedHotelDayByDay);
    setIsCustomizedClick(true);
    setCustomizableRatesLoader(false);

    // setHotelDayByDay((prevData) => {
    //   const updatedData = [...prevData];
    //   updatedData[selectedDay] = {
    //     ...updatedData[selectedDay], // Spread the existing object at index i
    //     newRate: selectedRates.rate_per_night, // Merge the fetched details into the object at index i
    //   };
    //   return updatedData;
    // });
  };

  const fetchTransportCost = (vehicle) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const titles = singlePackageData?.packageDestination?.map(
      (destination) => destination.title
    );

    // Create FormData
    const formdata = new FormData();

    // Log the FormData object for verification

    // const formdata = new FormData();
    formdata.append("hub", singlePackageData?.packageHub);
    formdata.append("days", singlePackageData.totalDays);
    formdata.append("nights", singlePackageData.totalNights);
    formdata.append("vehicle_type", vehicle);
    formdata.append("destinations", JSON.stringify(titles));
    formdata.append("capacity", guest);

    // formdata.append("hub", "Ex-Chandigarh");
    // formdata.append("days", "9");
    // formdata.append("nights", "8");
    // formdata.append("vehicle_type", "Sedan");
    // formdata.append("destinations", ["Shimla","Dalhousie","Dharamshala","Manali"]);
    // formdata.append("capacity", "1");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/transport-fixed-rates/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setVehicleDetails(result?.data);
      })
      .catch((error) => console.error(error));
  };

  const fetchCostOfVehicle = (vehicle) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const titles = singlePackageData?.packageDestination?.map(
      (destination) => destination.title
    );

    // Create FormData
    const formdata = new FormData();

    // Log the FormData object for verification

    // const formdata = new FormData();
    formdata.append("hub", singlePackageData?.packageHub);
    formdata.append("days", singlePackageData.totalDays);
    formdata.append("nights", singlePackageData.totalNights);
    formdata.append("vehicle_type", vehicle);
    formdata.append("destinations", JSON.stringify(titles));
    formdata.append("capacity", guest);

    // formdata.append("hub", "Ex-Chandigarh");
    // formdata.append("days", "9");
    // formdata.append("nights", "8");
    // formdata.append("vehicle_type", "Sedan");
    // formdata.append("destinations", ["Shimla","Dalhousie","Dharamshala","Manali"]);
    // formdata.append("capacity", "1");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/transport-fixed-rates/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.data[0]?.Price) {
          setOldVehicleCost(Number(result?.data[0]?.Price));
        }
      })
      .catch((error) => console.error(error));
  };
  const fetchTransportsArray = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const titles = singlePackageData?.packageDestination?.map(
      (destination) => destination.title
    );

    const formdata = new FormData();
    formdata.append("hub", singlePackageData?.packageHub);
    formdata.append("days", singlePackageData.totalDays);
    formdata.append("nights", singlePackageData.totalNights);
    // formdata.append("vehicle_type", vehicle);
    formdata.append("destinations", JSON.stringify(titles));
    formdata.append("capacity", guest);
    formdata.append("query", "list");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/transport-fixed-rates/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // if(result?.success){
        setIsVehicleCustomizedClick(true);

        setAllVehicles(result?.data);
        // }
      })
      .catch((error) => console.error(error));
  };
  const fetchActivitiesArray = (index, location) => {
    // console.log("location", location);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const titles = singlePackageData?.packageDestination?.map(
      (destination) => destination.title
    );

    const formdata = new FormData();
    formdata.append("location", location);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/activities/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setIsAddActivityClick(true);

          setAllActivities(result?.data ? result?.data : []);
        } else if (result?.error) {
          setAllActivities([]);
        } else {
          setAllActivities([]);
        }
      })
      .catch((error) => {
        setAllActivities([]);
      });
  };

  const changeSelectedVehicle = (vehicle) => {
    setNewVehicleCost(Number(vehicle?.Price));

    setIsCustomizedClick(true);

    setVehicleDetails([vehicle]);

    // fetchTransportCost(vehicle?.Vehicle);
    // setSelectedVehicle(vehicle);
  };

  const changeSelectedActivity = (activity, index) => {
    setIsCustomizedClick(true);

    setHotelDayByDay((prevData) => {
      const updatedData = [...prevData];
      const currentActivities = updatedData[addActivityIdClick]?.activity || [];
      const activityExists = currentActivities.some(
        (existingActivity) => existingActivity.name === activity.name
      );
      if (!activityExists) {
        updatedData[addActivityIdClick] = {
          ...updatedData[addActivityIdClick],
          activity: [
            ...currentActivities,
            { ...activity, guest: bookingData?.guest },
          ],
        };
      }

      return updatedData;
    });
  };
  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const handleRemoveActivity = (addActivityIdClick, index) => {
    setHotelDayByDay((prevData) => {
      const updatedData = [...prevData];
      const currentActivities = updatedData[addActivityIdClick]?.activity || [];
      updatedData[addActivityIdClick] = {
        ...updatedData[addActivityIdClick],
        activity: currentActivities.filter((_, i) => i !== index),
      };
      return updatedData;
    });
  };

  const handleAddonChange = (item) => {
    setIsCustomizedClick(true);
    setSelectedAddons((prevSelectedAddons) => {
      if (prevSelectedAddons.some((addon) => addon.addonId === item.addonId)) {
        return prevSelectedAddons.filter(
          (addon) => addon.addonId !== item.addonId
        );
      } else {
        return [...prevSelectedAddons, item];
      }
    });
  };

  return (
    <>
      <Header />
      {/* <SingleSearchForm /> */}
      {/* <PageLinks /> */}
      <div style={{ justifyContent: "end", padding: "10px" }}></div>
      <section>
        <div className="container">
          {/* <PackageDetails packageData={singlePackageData} /> */}
          <SinglePackageHeader packageData={singlePackageData} />
          <main className="justify_content_center">
            <div className="reservation-content">
              <div
                className="m_0 package-update-profile-tab-form profile_tab_form"
                style={{ padding: "0" }}
              >
                <div className="package-update-content">
                  {singlePackageData?.canCustomise ? (
                    <TabHeaderCustomizable
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : (
                    <TabHeader
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}

                  <div className="package-update-content-containers">
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>

            <aside id="Price-Break">
              {/* {customizableRatesLoader === true ? <>loader</> : <>no loader</>} */}
              {isCustomizedClick ? (
                <div className="common_background common_background_parent_sticky">
                  <div className="justify_content_between flex align_items_center">
                    <h4 style={{ fontSize: "14px" }}>
                      Fare Summary (Customized)
                    </h4>

                    {customizableRatesLoader === true && (
                      <div>
                        <SmallLoader color={"green"} size={"20px"} />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Adult Cost{" "}
                        <small>
                          {" "}
                          (
                          {Number(
                            calculationsOfCustomizedPackage?.onlyAdultCost /
                              Number(
                                calculationsOfCustomizedPackage?.adultNumber
                              )
                          )}{" "}
                          X {calculationsOfCustomizedPackage?.adultNumber})
                        </small>{" "}
                      </p>

                      <span className="room_text-right ">
                        {customizableRatesLoader === true ? (
                          <div style={{ width: "100px", filter: "blur(3px)" }}>
                            loader
                          </div>
                        ) : (
                          <>
                            {" "}
                            {formatIndianCurrency(
                              Number(
                                calculationsOfCustomizedPackage?.onlyAdultCost
                              )
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    {calculationsOfCustomizedPackage?.extraAdultNumber ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Extra Adult Cost{" "}
                          <small>
                            {" "}
                            (
                            {calculationsOfCustomizedPackage?.ExtraAdultCost /
                              calculationsOfCustomizedPackage?.extraAdultNumber}{" "}
                            X{" "}
                            {calculationsOfCustomizedPackage?.extraAdultNumber})
                          </small>
                        </p>
                        <span className="room_text-right alert_text">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.extraAdultCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    {calculationsOfCustomizedPackage?.extraAdultNumber ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          <b>Total Adult Cost</b>{" "}
                          <small>
                            {" "}
                            (
                            {calculationsOfCustomizedPackage?.ExtraAdultCost /
                              calculationsOfCustomizedPackage?.extraAdultNumber}{" "}
                            X{" "}
                            {calculationsOfCustomizedPackage?.extraAdultNumber})
                          </small>
                        </p>
                        <span className="room_text-right alert_text">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.extraAdultCost +
                                  calculationsOfCustomizedPackage?.onlyAdultCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    {calculationsOfCustomizedPackage?.childNumber ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Child Cost{" "}
                          <small>
                            {" "}
                            (
                            {calculationsOfCustomizedPackage?.childCosts /
                              calculationsOfCustomizedPackage?.childNumber}{" "}
                            X {calculationsOfCustomizedPackage?.childNumber})
                          </small>
                        </p>
                        <span className="room_text-right ">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.childCosts
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    {calculationsOfCustomizedPackage?.transportationCost ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Transportation Cost{" "}
                          <span className="customizedSinglePackageInfoIcon">
                            <svg
                              height="15px"
                              width="15px"
                              version="1.1"
                              id="_x32_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <style type="text/css">
                                  {" "}
                                  {`.st0{fill:#000000;}`}{" "}
                                </style>
                                <g>
                                  <path
                                    className="st0"
                                    d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,86.069 c28.463,0,51.538,23.074,51.538,51.538c0,28.464-23.074,51.538-51.538,51.538c-28.463,0-51.538-23.074-51.538-51.538 C204.462,109.143,227.537,86.069,256,86.069z M310.491,425.931H201.51v-43.593h35.667V276.329H215.38v-43.593h65.389v3.963v39.63 v106.009h29.722V425.931z"
                                  ></path>
                                </g>
                              </g>
                            </svg>

                            <div className="customizedSinglePackageInfoIconChild">
                              <div className="flex justify_content_between">
                                <div>
                                  New Vehicle Price{" "}
                                  {vehicleDetails &&
                                    vehicleDetails.length > 0 && (
                                      <small>
                                        ({vehicleDetails[0]?.Vehicle})
                                      </small>
                                    )}
                                </div>

                                <div>
                                  {" "}
                                  {formatIndianCurrency(newVehicleCost)}
                                </div>
                              </div>
                              <div className="flex justify_content_between">
                                <div>
                                  Old Vehicle Price{" "}
                                  <small>
                                    ({ratesOfPackages?.VehicleType})
                                  </small>
                                </div>

                                <div>
                                  {" "}
                                  {formatIndianCurrency(oldVehicleCost)}
                                </div>
                              </div>
                              <div className="flex justify_content_between alert_text">
                                <div>
                                  <b>New Transportation Cost</b>
                                </div>

                                <div>
                                  {" "}
                                  <b>
                                    {" "}
                                    {formatIndianCurrency(
                                      calculationsOfCustomizedPackage?.transportationCost
                                    )}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </span>
                        </p>
                        <span className="room_text-right ">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.transportationCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    {calculationsOfCustomizedPackage?.totalActivityCost ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Activity Cost
                          <small>
                            {/* {" "}
                            (
                            {calculationsOfCustomizedPackage?.totalActivityCost /
                              calculationsOfPackages?.adultNumber}{" "}
                            X {calculationsOfPackages?.adultNumber}) */}
                          </small>
                        </p>
                        <span className="room_text-right ">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.totalActivityCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    {calculationsOfCustomizedPackage?.addonsCost ? (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Addons Cost
                          <small>
                            {/* {" "}
                            (
                            {calculationsOfCustomizedPackage?.totalActivityCost /
                              calculationsOfPackages?.adultNumber}{" "}
                            X {calculationsOfPackages?.adultNumber}) */}
                          </small>
                        </p>
                        <span className="room_text-right ">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.addonsCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null}
                    <div className="flex justify_content_between mt-10">
                      <p>
                        <b>Total Package Cost</b>
                      </p>
                      <span className="room_text-right alert_text">
                        {customizableRatesLoader === true ? (
                          <div style={{ width: "100px", filter: "blur(3px)" }}>
                            loader
                          </div>
                        ) : (
                          <>
                            {" "}
                            {formatIndianCurrency(
                              calculationsOfCustomizedPackage?.basePriceAfterTransportCost
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    {calculationsOfCustomizedPackage?.discount > 0 && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Discount (
                            {
                              calculationsOfCustomizedPackage?.discountPercentage
                            }
                            %)
                          </p>
                          <span className="room_text-right">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfCustomizedPackage?.discountAmount
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    {calculationsOfCustomizedPackage?.discount > 0 && (
                      <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                        <p>
                          Package Cost <br />
                          After Discount
                        </p>
                        <span className="room_text-right">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.priceAfterDiscount
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    )}
                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                      <p>GST(5%) </p>
                      <span className="room_text-right">
                        {customizableRatesLoader === true ? (
                          <div style={{ width: "100px", filter: "blur(3px)" }}>
                            loader
                          </div>
                        ) : (
                          <>
                            {" "}
                            {formatIndianCurrency(
                              calculationsOfCustomizedPackage?.taxAmount
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>Total Amount to be paid</b>
                      </h6>
                      <h6>
                        <b>
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.totalAmount
                              )}
                            </>
                          )}
                        </b>
                      </h6>
                    </div>
                    {/* <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>
                          Per Pax Cost{" "}
                          <small>
                            ({calculationsOfCustomizedPackage?.perPaxCount})
                          </small>
                        </b>
                      </h6>
                      <h6>
                        <b>
                          {customizableRatesLoader === true ? (
                            <div
                              style={{
                                width: "100px",
                                filter: "blur(3px)",
                              }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfCustomizedPackage?.perPaxCost
                              )}
                            </>
                          )}
                        </b>
                      </h6>
                    </div>{" "} */}
                  </div>

                  <div className="buttons flex_direction_col">
                    {/* <Link to={"/checkout-package"}> */}
                    <button
                      onClick={navigateToCheckOutPackage}
                      className="common_btn"
                    >
                      Book Now
                    </button>{" "}
                    {/* </Link> */}
                    <button
                      onClick={(e) => handleshowRatePopup(e)}
                      className="secondary_btn mt-10 border-radius"
                    >
                      Send Enquiry
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="common_background common_background_parent_sticky"
                  style={{ overflow: "auto" }}
                >
                  <div className="justify_content_between flex align_items_center">
                    <h4 style={{ fontSize: "14px" }}>Fare Summary (NEW)</h4>

                    {customizableRatesLoader === true ? (
                      <div>
                        <SmallLoader color={"green"} size={"20px"} />
                      </div>
                    ) : (
                      <div onClick={togglePaymentDetails}>
                        <button
                          className={`${
                            activePayment === "priceSummary" ? "" : ""
                          }`}
                          onClick={handleButtonClick}
                        >
                          <small className="link_btn">
                            {activePayment === "priceSummary"
                              ? "View Breakup"
                              : "Minimize Breakup"}{" "}
                          </small>
                          <svg
                            width="13"
                            height="8"
                            viewBox="0 0 13 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transition: "transform 0.3s",
                              transform: paymentDetails
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          >
                            <path
                              d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                              stroke="#222222"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    {activePayment === "priceSummary" && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>Package Cost</p>
                          <span className="room_text-right alert_text">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.basePrice
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        {calculationsOfPackages?.discount > 0 && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Discount (
                                {calculationsOfPackages?.discountPercentage}
                                %)
                              </p>
                              <span className="room_text-right">
                                {customizableRatesLoader === true ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      filter: "blur(3px)",
                                    }}
                                  >
                                    loader
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.discountAmount
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        )}
                        {calculationsOfPackages?.discount > 0 && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>
                              Package Cost <br />
                              After Discount
                            </p>
                            <span className="room_text-right">
                              {customizableRatesLoader === true ? (
                                <div
                                  style={{
                                    width: "100px",
                                    filter: "blur(3px)",
                                  }}
                                >
                                  loader
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.priceAfterDiscount
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        )}
                        <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>GST(5%) </p>
                          <span className="room_text-right">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.taxAmount
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Total Amount to be paid</b>
                          </h6>
                          <h6>
                            <b>
                              {customizableRatesLoader === true ? (
                                <div
                                  style={{
                                    width: "100px",
                                    filter: "blur(3px)",
                                  }}
                                >
                                  loader
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.totalAmount
                                  )}
                                </>
                              )}
                            </b>
                          </h6>
                        </div>{" "}
                        {/* <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>
                              Per Pax Cost{" "}
                              <small>
                                ({calculationsOfPackages?.perPaxCount})
                              </small>
                            </b>
                          </h6>
                          <h6>
                            <b>
                              {customizableRatesLoader === true ? (
                                <div
                                  style={{
                                    width: "100px",
                                    filter: "blur(3px)",
                                  }}
                                >
                                  loader
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.perPaxCost
                                  )}
                                </>
                              )}
                            </b>
                          </h6>
                        </div>{" "} */}
                      </>
                    )}
                    {activePayment === "priceDetails" && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Adult Cost
                            <small>
                              {" "}
                              (
                              {calculationsOfPackages?.onlyAdultCost /
                                calculationsOfPackages?.adultNumber}{" "}
                              X {calculationsOfPackages?.adultNumber})
                            </small>
                          </p>
                          <span
                            className={`room_text-right ${
                              Number(calculationsOfPackages?.onlyAdultCost) <=
                                0 && "filterBlur3"
                            }`}
                          >
                            {formatIndianCurrency(
                              Number(calculationsOfPackages?.onlyAdultCost)
                            )}
                          </span>
                        </div>
                        {calculationsOfPackages?.extraAdultNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Extra Adult Cost{" "}
                              <small>
                                {" "}
                                (
                                {calculationsOfPackages?.ExtraAdultCost /
                                  calculationsOfPackages?.extraAdultNumber}{" "}
                                X {calculationsOfPackages?.extraAdultNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.ExtraAdultCost
                              )}
                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        )}
                        {calculationsOfPackages?.extraAdultNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              <b>Total Adult Cost</b>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              <b>
                                {formatIndianCurrency(
                                  calculationsOfPackages?.ExtraAdultCost +
                                    calculationsOfPackages?.onlyAdultCost
                                )}
                              </b>

                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        )}
                        {calculationsOfPackages?.childNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost{" "}
                              <small>
                                {" "}
                                (
                                {calculationsOfPackages?.childCosts /
                                  calculationsOfPackages?.childNumber}{" "}
                                X {calculationsOfPackages?.childNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(calculationsOfPackages?.childCosts) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCosts
                              )}
                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        )}
                        {/* {calculationsOfPackages?.transportationCost ? (
                      <div className="flex justify_content_between mt-10">
                        <p>Transportation Cost</p>
                        <span className="room_text-right ">
                          {customizableRatesLoader === true ? (
                            <div
                              style={{ width: "100px", filter: "blur(3px)" }}
                            >
                              loader
                            </div>
                          ) : (
                            <>
                              {" "}
                              {formatIndianCurrency(
                                calculationsOfPackages?.transportationCost
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    ) : null} */}
                        {/* <div className="flex justify_content_between mt-10">
                          <p>
                            Per Head Cost <small> ({bookingData?.guest})</small>
                          </p>
                          <span className="room_text-right">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {formatIndianCurrency(
                                  calculationsOfPackages?.basePrice /
                                    bookingData?.guest
                                )}
                              </>
                            )}
                          </span>
                        </div> */}
                        <div className="flex justify_content_between mt-10">
                          <p>
                            <b>Total Package Cost</b>
                          </p>
                          <span className="room_text-right alert_text">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.basePrice
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        {calculationsOfPackages?.discount > 0 && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Discount (
                                {calculationsOfPackages?.discountPercentage}
                                %)
                              </p>
                              <span className="room_text-right">
                                {customizableRatesLoader === true ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      filter: "blur(3px)",
                                    }}
                                  >
                                    loader
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.discountAmount
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        )}
                        {calculationsOfPackages?.discount > 0 && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>
                              Package Cost <br />
                              After Discount
                            </p>
                            <span className="room_text-right">
                              {customizableRatesLoader === true ? (
                                <div
                                  style={{
                                    width: "100px",
                                    filter: "blur(3px)",
                                  }}
                                >
                                  loader
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.priceAfterDiscount
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        )}
                        <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>GST(5%) </p>
                          <span className="room_text-right">
                            {customizableRatesLoader === true ? (
                              <div
                                style={{ width: "100px", filter: "blur(3px)" }}
                              >
                                loader
                              </div>
                            ) : (
                              <>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.taxAmount
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Total Amount to be paid</b>
                          </h6>
                          <h6>
                            <b>
                              {customizableRatesLoader === true ? (
                                <div
                                  style={{
                                    width: "100px",
                                    filter: "blur(3px)",
                                  }}
                                >
                                  loader
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.totalAmount
                                  )}
                                </>
                              )}
                            </b>
                          </h6>
                        </div>{" "}
                      </>
                    )}
                  </div>

                  <div className="buttons flex_direction_col">
                    {/* <Link to={"/checkout-package"}> */}
                    <button
                      onClick={navigateToCheckOutPackage}
                      className="common_btn"
                    >
                      Book Now
                    </button>{" "}
                    {/* </Link> */}
                    <button
                      onClick={(e) => handleshowRatePopup(e)}
                      className="secondary_btn mt-10 border-radius"
                    >
                      Send Enquiry
                    </button>
                  </div>
                </div>
              )}

              {singlePackageData?.packageAddons &&
                singlePackageData?.packageAddons?.length > 0 &&
                singlePackageData?.packageAddons?.map((item, index) => (
                  <>
                    <div className="common_background">
                      <div className="justify_content_between flex align_items_center">
                        <h4 style={{ fontSize: "14px", padding: "10px 0px" }}>
                          Add Addons
                        </h4>

                        {customizableRatesLoader === true && (
                          <div>
                            <SmallLoader color={"green"} size={"20px"} />
                          </div>
                        )}
                      </div>
                      <div
                        key={index}
                        className="flex discountSingleDiv justify_content_between w-100 flex_gap_10"
                      >
                        <div className="flex flex_gap_10 justify_content_center align_items_center">
                          <input
                            type="checkbox"
                            checked={
                              selectedAddons &&
                              selectedAddons.length > 0 &&
                              selectedAddons.some(
                                (addon) => addon?.addonId === item?.addonId
                              )
                            }
                            id={item?.addonId}
                            onChange={() => handleAddonChange(item)}
                          />
                          <label
                            style={{ textTransform: "capitalize" }}
                            htmlFor={item?.addonId}
                            id={item?.addonId}
                          >
                            <div>{item?.addonName}</div>
                          </label>
                        </div>
                        <div>{formatIndianCurrency(item?.addonPrice)}</div>
                      </div>
                    </div>
                  </>
                ))}

              <OurQuery />
              <ChooseUs />
              <SendItinerary singlePackageData={singlePackageData} />
            </aside>

            {mobilePriceBreakup && (
              <>
                <div className="flex justify_content_between total_amount align_items_center">
                  {isCustomizedClick ? (
                    <div>
                      <h6 className="font_12">Total Amount to be paid </h6>
                      <h6>
                        <span
                          className={` ${
                            Number(
                              calculationsOfCustomizedPackage?.totalAmount
                            ) <= 0 && "filterBlur3"
                          }`}
                        >
                          {formatIndianCurrency(
                            calculationsOfCustomizedPackage?.totalAmount
                          )}{" "}
                          <svg
                            onClick={() => setPriceBreakupPopup(true)}
                            xmlns="http://www.w3.org/2000/svg"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0,0,256,256"
                            width="20px"
                            height="20px"
                            fill-rule="nonzero"
                          >
                            <g
                              fill="#ffffff"
                              fill-rule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <g transform="scale(8,8)">
                                <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h6>{" "}
                    </div>
                  ) : (
                    <div>
                      <h6 className="font_12">Total Amount to be paid </h6>
                      <h6>
                        <span
                          className={` ${
                            Number(
                              calculationsOfCustomizedPackage?.totalAmount
                            ) <= 0 && "filterBlur3"
                          }`}
                        >
                          {formatIndianCurrency(
                            calculationsOfCustomizedPackage?.totalAmount
                          )}{" "}
                          <svg
                            onClick={() => setPriceBreakupPopup(true)}
                            xmlns="http://www.w3.org/2000/svg"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0,0,256,256"
                            width="20px"
                            height="20px"
                            fill-rule="nonzero"
                          >
                            <g
                              fill="#ffffff"
                              fill-rule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <g transform="scale(8,8)">
                                <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h6>{" "}
                    </div>
                  )}
                  <button
                    style={{ background: "white", color: "#016937" }}
                    onClick={navigateToCheckOutPackage}
                    className="primary_btn fit-width"
                  >
                    <b> Book Now</b>
                  </button>
                </div>
                {priceBreakupPopup && (
                  <>
                    {isCustomizedClick ? (
                      <div className="bg_back_drop_overlay">
                        <div
                          className="common_background common_background_parent_sticky"
                          id="PriceBreak"
                        >
                          <div className="flex justify_content_end p_b_10 ">
                            <svg
                              onClick={() => setPriceBreakupPopup(false)}
                              width="14"
                              height="13"
                              viewBox="0 0 14 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 12.5L13 0.5"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                              <path
                                d="M13 12.5L1 0.5"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          <div className="justify_content_between flex align_items_center">
                            <h4
                              style={{ fontSize: "14px", padding: "10px 0px" }}
                            >
                              Fare Summary (Customized)
                            </h4>

                            {customizableRatesLoader === true && (
                              <div>
                                <SmallLoader color={"green"} size={"20px"} />
                              </div>
                            )}
                          </div>
                          <div>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Adult Cost{" "}
                                <small>
                                  {" "}
                                  (
                                  {Number(
                                    calculationsOfCustomizedPackage?.onlyAdultCost /
                                      Number(
                                        calculationsOfCustomizedPackage?.adultNumber
                                      )
                                  )}{" "}
                                  X{" "}
                                  {calculationsOfCustomizedPackage?.adultNumber}
                                  )
                                </small>{" "}
                              </p>

                              <span className="room_text-right ">
                                {customizableRatesLoader === true ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      filter: "blur(3px)",
                                    }}
                                  >
                                    loader
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {formatIndianCurrency(
                                      Number(
                                        calculationsOfCustomizedPackage?.onlyAdultCost
                                      )
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            {calculationsOfCustomizedPackage?.extraAdultNumber ? (
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Extra Adult Cost{" "}
                                  <small>
                                    {" "}
                                    (
                                    {calculationsOfCustomizedPackage?.ExtraAdultCost /
                                      calculationsOfCustomizedPackage?.extraAdultNumber}{" "}
                                    X{" "}
                                    {
                                      calculationsOfCustomizedPackage?.extraAdultNumber
                                    }
                                    )
                                  </small>
                                </p>
                                <span className="room_text-right alert_text">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.extraAdultCost
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            ) : null}
                            {calculationsOfCustomizedPackage?.extraAdultNumber ? (
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  <b>Total Adult Cost</b>{" "}
                                  <small>
                                    {" "}
                                    (
                                    {calculationsOfCustomizedPackage?.ExtraAdultCost /
                                      calculationsOfCustomizedPackage?.extraAdultNumber}{" "}
                                    X{" "}
                                    {
                                      calculationsOfCustomizedPackage?.extraAdultNumber
                                    }
                                    )
                                  </small>
                                </p>
                                <span className="room_text-right alert_text">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.extraAdultCost +
                                          calculationsOfCustomizedPackage?.onlyAdultCost
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            ) : null}
                            {calculationsOfCustomizedPackage?.childNumber ? (
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Child Cost{" "}
                                  <small>
                                    {" "}
                                    (
                                    {calculationsOfCustomizedPackage?.childCosts /
                                      calculationsOfCustomizedPackage?.childNumber}{" "}
                                    X{" "}
                                    {
                                      calculationsOfCustomizedPackage?.childNumber
                                    }
                                    )
                                  </small>
                                </p>
                                <span className="room_text-right ">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.childCosts
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            ) : null}
                            {calculationsOfCustomizedPackage?.transportationCost ? (
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Transportation Cost{" "}
                                  <span className="customizedSinglePackageInfoIcon">
                                    <svg
                                      height="15px"
                                      width="15px"
                                      version="1.1"
                                      id="_x32_"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        strokeWidth="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <style type="text/css">
                                          {" "}
                                          {`.st0{fill:#000000;}`}{" "}
                                        </style>
                                        <g>
                                          <path
                                            className="st0"
                                            d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,86.069 c28.463,0,51.538,23.074,51.538,51.538c0,28.464-23.074,51.538-51.538,51.538c-28.463,0-51.538-23.074-51.538-51.538 C204.462,109.143,227.537,86.069,256,86.069z M310.491,425.931H201.51v-43.593h35.667V276.329H215.38v-43.593h65.389v3.963v39.63 v106.009h29.722V425.931z"
                                          ></path>
                                        </g>
                                      </g>
                                    </svg>

                                    <div className="customizedSinglePackageInfoIconChild">
                                      <div className="flex justify_content_between">
                                        <div>
                                          New Vehicle Price{" "}
                                          {vehicleDetails &&
                                            vehicleDetails.length > 0 && (
                                              <small>
                                                ({vehicleDetails[0]?.Vehicle})
                                              </small>
                                            )}
                                        </div>

                                        <div>
                                          {" "}
                                          {formatIndianCurrency(newVehicleCost)}
                                        </div>
                                      </div>
                                      <div className="flex justify_content_between">
                                        <div>
                                          Old Vehicle Price{" "}
                                          <small>
                                            ({ratesOfPackages?.VehicleType})
                                          </small>
                                        </div>

                                        <div>
                                          {" "}
                                          {formatIndianCurrency(oldVehicleCost)}
                                        </div>
                                      </div>
                                      <div className="flex justify_content_between alert_text">
                                        <div>
                                          <b>New Transportation Cost</b>
                                        </div>

                                        <div>
                                          {" "}
                                          <b>
                                            {" "}
                                            {formatIndianCurrency(
                                              calculationsOfCustomizedPackage?.transportationCost
                                            )}
                                          </b>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </p>
                                <span className="room_text-right ">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.transportationCost
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            ) : null}
                            {calculationsOfCustomizedPackage?.totalActivityCost ? (
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Activity Cost
                                  <small>
                                    {" "}
                                    {/* (
                                    {calculationsOfCustomizedPackage?.totalActivityCost /
                                      calculationsOfPackages?.adultNumber}{" "}
                                    X {calculationsOfPackages?.adultNumber}) */}
                                  </small>
                                </p>
                                <span className="room_text-right ">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.totalActivityCost
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            ) : null}
                            <div className="flex justify_content_between mt-10">
                              <p>
                                <b>Total Package Cost</b>
                              </p>
                              <span className="room_text-right alert_text">
                                {customizableRatesLoader === true ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      filter: "blur(3px)",
                                    }}
                                  >
                                    loader
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {formatIndianCurrency(
                                      calculationsOfCustomizedPackage?.basePriceAfterTransportCost
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            {calculationsOfCustomizedPackage?.discount > 0 && (
                              <>
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Discount (
                                    {
                                      calculationsOfCustomizedPackage?.discountPercentage
                                    }
                                    %)
                                  </p>
                                  <span className="room_text-right">
                                    {customizableRatesLoader === true ? (
                                      <div
                                        style={{
                                          width: "100px",
                                          filter: "blur(3px)",
                                        }}
                                      >
                                        loader
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {formatIndianCurrency(
                                          calculationsOfCustomizedPackage?.discountAmount
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                            {calculationsOfCustomizedPackage?.discount > 0 && (
                              <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                <p>
                                  Package Cost <br />
                                  After Discount
                                </p>
                                <span className="room_text-right">
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.priceAfterDiscount
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            )}
                            <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                              <p>GST(5%) </p>
                              <span className="room_text-right">
                                {customizableRatesLoader === true ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      filter: "blur(3px)",
                                    }}
                                  >
                                    loader
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {formatIndianCurrency(
                                      calculationsOfCustomizedPackage?.taxAmount
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="flex justify_content_between mt-10">
                              <h6>
                                <b>Total Amount to be paid</b>
                              </h6>
                              <h6>
                                <b>
                                  {customizableRatesLoader === true ? (
                                    <div
                                      style={{
                                        width: "100px",
                                        filter: "blur(3px)",
                                      }}
                                    >
                                      loader
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {formatIndianCurrency(
                                        calculationsOfCustomizedPackage?.totalAmount
                                      )}
                                    </>
                                  )}
                                </b>
                              </h6>
                            </div>
                          </div>
                          <div className="buttons flex_direction_col">
                            {/* <Link to={"/checkout-package"}> */}
                            <button
                              onClick={navigateToCheckOutPackage}
                              className="common_btn"
                            >
                              Book Now
                            </button>{" "}
                            {/* </Link> */}
                            <button
                              onClick={(e) => handleshowRatePopup(e)}
                              className="secondary_btn mt-10 border-radius"
                            >
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="bg_back_drop_overlay">
                        <div
                          className="common_background common_background_parent_sticky"
                          id="PriceBreak"
                          style={{ overflow: "auto" }}
                        >
                          {" "}
                          <div className="flex justify_content_end p_b_10 ">
                            <svg
                              onClick={() => setPriceBreakupPopup(false)}
                              width="14"
                              height="13"
                              viewBox="0 0 14 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 12.5L13 0.5"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                              <path
                                d="M13 12.5L1 0.5"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          <div className="justify_content_between flex align_items_center">
                            <h4
                              style={{ fontSize: "14px", padding: "10px 0px" }}
                            >
                              Fare Summary (NEW)
                            </h4>

                            {customizableRatesLoader === true ? (
                              <div>
                                <SmallLoader color={"green"} size={"20px"} />
                              </div>
                            ) : (
                              <div onClick={togglePaymentDetails}>
                                <button
                                  className={`${
                                    activePayment === "priceSummary" ? "" : ""
                                  }`}
                                  onClick={handleButtonClick}
                                >
                                  <small className="link_btn">
                                    {activePayment === "priceSummary"
                                      ? "View Breakup"
                                      : "Minimize Breakup"}{" "}
                                  </small>
                                  <svg
                                    width="13"
                                    height="8"
                                    viewBox="0 0 13 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      transition: "transform 0.3s",
                                      transform: paymentDetails
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    }}
                                  >
                                    <path
                                      d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                      stroke="#222222"
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                          <div>
                            {activePayment === "priceSummary" && (
                              <>
                                <div className="flex justify_content_between mt-10">
                                  <p>Package Cost</p>
                                  <span className="room_text-right alert_text">
                                    {customizableRatesLoader === true ? (
                                      <div
                                        style={{
                                          width: "100px",
                                          filter: "blur(3px)",
                                        }}
                                      >
                                        loader
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {formatIndianCurrency(
                                          calculationsOfPackages?.basePrice
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                                {calculationsOfPackages?.discount > 0 && (
                                  <>
                                    <div className="flex justify_content_between mt-10">
                                      <p>
                                        Discount (
                                        {
                                          calculationsOfPackages?.discountPercentage
                                        }
                                        %)
                                      </p>
                                      <span className="room_text-right">
                                        {customizableRatesLoader === true ? (
                                          <div
                                            style={{
                                              width: "100px",
                                              filter: "blur(3px)",
                                            }}
                                          >
                                            loader
                                          </div>
                                        ) : (
                                          <>
                                            {" "}
                                            {formatIndianCurrency(
                                              calculationsOfPackages?.discountAmount
                                            )}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {calculationsOfPackages?.discount > 0 && (
                                  <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                    <p>
                                      Package Cost <br />
                                      After Discount
                                    </p>
                                    <span className="room_text-right">
                                      {customizableRatesLoader === true ? (
                                        <div
                                          style={{
                                            width: "100px",
                                            filter: "blur(3px)",
                                          }}
                                        >
                                          loader
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          {formatIndianCurrency(
                                            calculationsOfPackages?.priceAfterDiscount
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                  <p>GST(5%) </p>
                                  <span className="room_text-right">
                                    {customizableRatesLoader === true ? (
                                      <div
                                        style={{
                                          width: "100px",
                                          filter: "blur(3px)",
                                        }}
                                      >
                                        loader
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {formatIndianCurrency(
                                          calculationsOfPackages?.taxAmount
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="flex justify_content_between mt-10">
                                  <h6>
                                    <b>Total Amount to be paid</b>
                                  </h6>
                                  <h6>
                                    <b>
                                      {customizableRatesLoader === true ? (
                                        <div
                                          style={{
                                            width: "100px",
                                            filter: "blur(3px)",
                                          }}
                                        >
                                          loader
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          {formatIndianCurrency(
                                            calculationsOfPackages?.totalAmount
                                          )}
                                        </>
                                      )}
                                    </b>
                                  </h6>
                                </div>{" "}
                              </>
                            )}
                            {activePayment === "priceDetails" && (
                              <>
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Adult Cost
                                    <small>
                                      {" "}
                                      (
                                      {calculationsOfPackages?.onlyAdultCost /
                                        calculationsOfPackages?.adultNumber}{" "}
                                      X {calculationsOfPackages?.adultNumber})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${
                                      Number(
                                        calculationsOfPackages?.onlyAdultCost
                                      ) <= 0 && "filterBlur3"
                                    }`}
                                  >
                                    {formatIndianCurrency(
                                      Number(
                                        calculationsOfPackages?.onlyAdultCost
                                      )
                                    )}
                                  </span>
                                </div>
                                {calculationsOfPackages?.extraAdultNumber >
                                  0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p>
                                      Extra Adult Cost{" "}
                                      <small>
                                        {" "}
                                        (
                                        {calculationsOfPackages?.ExtraAdultCost /
                                          calculationsOfPackages?.extraAdultNumber}{" "}
                                        X{" "}
                                        {
                                          calculationsOfPackages?.extraAdultNumber
                                        }
                                        )
                                      </small>
                                    </p>
                                    <span
                                      className={`room_text-right ${
                                        Number(
                                          calculationsOfPackages?.totalAmount
                                        ) <= 0 && "filterBlur3"
                                      }`}
                                    >
                                      {formatIndianCurrency(
                                        calculationsOfPackages?.ExtraAdultCost
                                      )}
                                      {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                    </span>
                                  </div>
                                )}
                                {calculationsOfPackages?.extraAdultNumber >
                                  0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p>
                                      <b>Total Adult Cost</b>
                                    </p>
                                    <span
                                      className={`room_text-right ${
                                        Number(
                                          calculationsOfPackages?.totalAmount
                                        ) <= 0 && "filterBlur3"
                                      }`}
                                    >
                                      <b>
                                        {formatIndianCurrency(
                                          calculationsOfPackages?.ExtraAdultCost +
                                            calculationsOfPackages?.onlyAdultCost
                                        )}
                                      </b>

                                      {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                    </span>
                                  </div>
                                )}
                                {calculationsOfPackages?.childNumber > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p>
                                      Child Cost{" "}
                                      <small>
                                        {" "}
                                        (
                                        {calculationsOfPackages?.childCosts /
                                          calculationsOfPackages?.childNumber}{" "}
                                        X {calculationsOfPackages?.childNumber})
                                      </small>
                                    </p>
                                    <span
                                      className={`room_text-right ${
                                        Number(
                                          calculationsOfPackages?.childCosts
                                        ) <= 0 && "filterBlur3"
                                      }`}
                                    >
                                      {formatIndianCurrency(
                                        calculationsOfPackages?.childCosts
                                      )}
                                      {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                    </span>
                                  </div>
                                )}
                                {/* {calculationsOfPackages?.transportationCost ? (
             <div className="flex justify_content_between mt-10">
               <p>Transportation Cost</p>
               <span className="room_text-right ">
                 {customizableRatesLoader === true ? (
                   <div
                     style={{ width: "100px", filter: "blur(3px)" }}
                   >
                     loader
                   </div>
                 ) : (
                   <>
                     {" "}
                     {formatIndianCurrency(
                       calculationsOfPackages?.transportationCost
                     )}
                   </>
                 )}
               </span>
             </div>
           ) : null} */}
                                {/* <div className="flex justify_content_between mt-10">
                 <p>
                   Per Head Cost <small> ({bookingData?.guest})</small>
                 </p>
                 <span className="room_text-right">
                   {customizableRatesLoader === true ? (
                     <div
                       style={{ width: "100px", filter: "blur(3px)" }}
                     >
                       loader
                     </div>
                   ) : (
                     <>
                       {formatIndianCurrency(
                         calculationsOfPackages?.basePrice /
                           bookingData?.guest
                       )}
                     </>
                   )}
                 </span>
               </div> */}
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    <b>Total Package Cost</b>
                                  </p>
                                  <span className="room_text-right alert_text">
                                    {customizableRatesLoader === true ? (
                                      <div
                                        style={{
                                          width: "100px",
                                          filter: "blur(3px)",
                                        }}
                                      >
                                        loader
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {formatIndianCurrency(
                                          calculationsOfPackages?.basePrice
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                                {calculationsOfPackages?.discount > 0 && (
                                  <>
                                    <div className="flex justify_content_between mt-10">
                                      <p>
                                        Discount (
                                        {
                                          calculationsOfPackages?.discountPercentage
                                        }
                                        %)
                                      </p>
                                      <span className="room_text-right">
                                        {customizableRatesLoader === true ? (
                                          <div
                                            style={{
                                              width: "100px",
                                              filter: "blur(3px)",
                                            }}
                                          >
                                            loader
                                          </div>
                                        ) : (
                                          <>
                                            {" "}
                                            {formatIndianCurrency(
                                              calculationsOfPackages?.discountAmount
                                            )}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {calculationsOfPackages?.discount > 0 && (
                                  <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                    <p>
                                      Package Cost <br />
                                      After Discount
                                    </p>
                                    <span className="room_text-right">
                                      {customizableRatesLoader === true ? (
                                        <div
                                          style={{
                                            width: "100px",
                                            filter: "blur(3px)",
                                          }}
                                        >
                                          loader
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          {formatIndianCurrency(
                                            calculationsOfPackages?.priceAfterDiscount
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                  <p>GST(5%) </p>
                                  <span className="room_text-right">
                                    {customizableRatesLoader === true ? (
                                      <div
                                        style={{
                                          width: "100px",
                                          filter: "blur(3px)",
                                        }}
                                      >
                                        loader
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        {formatIndianCurrency(
                                          calculationsOfPackages?.taxAmount
                                        )}
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="flex justify_content_between mt-10">
                                  <h6>
                                    <b>Total Amount to be paid</b>
                                  </h6>
                                  <h6>
                                    <b>
                                      {customizableRatesLoader === true ? (
                                        <div
                                          style={{
                                            width: "100px",
                                            filter: "blur(3px)",
                                          }}
                                        >
                                          loader
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          {formatIndianCurrency(
                                            calculationsOfPackages?.totalAmount
                                          )}
                                        </>
                                      )}
                                    </b>
                                  </h6>
                                </div>{" "}
                              </>
                            )}
                          </div>
                          <div className="buttons flex_direction_col">
                            {/* <Link to={"/checkout-package"}> */}
                            <button
                              onClick={navigateToCheckOutPackage}
                              className="common_btn"
                            >
                              Book Now
                            </button>{" "}
                            {/* </Link> */}
                            <button
                              onClick={(e) => handleshowRatePopup(e)}
                              className="secondary_btn mt-10 border-radius"
                            >
                              Send Enquiry
                            </button>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                  </>
                )}

                <aside>
                  <OurQuery />
                  <ChooseUs className="mt-20" />
                  <SendItinerary singlePackageData={singlePackageData} />
                </aside>
              </>
            )}
          </main>
        </div>
      </section>
      <Footer />

      {showRatePopup && (
        <SendEnquiryPopup
          package_id={singlePackageData?.packageId}
          setshowRatePopup={setshowRatePopup}
        />
      )}
    </>
  );
}

export default NewCustomizeSinglePackage;
