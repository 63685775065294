import React from "react";
import Slider from "react-slick";
import { hotelChainData } from "../../constants";

function HotelChains() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          infinite: true,
          // arrows:true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true,
          // arrows:true
        },
      },
      {
        breakpoint: 565,
        settings: {
          infinite: true,
          slidesToShow: 2,
          // arrows:true
        },
      },
      {
        breakpoint: 374,
        settings: {
          infinite: true,
          slidesToShow: 1,
          centerMode: "true",
          // arrows:true
        },
      },
    ],
  };

  return (
    <section className="common_bg p_0">
      <div className="container">
        <div className="common_padding">
          <h3>Top Hotel Chains With Us</h3>
          <p className="Highlight-Exp common_content mt-10 text-center">
            Stay in style with the best hotel chains at unbeatable rates. From
            luxury to budget, we’ve got exclusive deals just for you. Book now
            and enjoy top-tier comfort and service wherever you go!
          </p>
        </div>{" "}
      </div>
      <div style={{padding: "25px 0" }}>
        <div className="container">
          <Slider {...settings}>
            {hotelChainData.map((items, index) => {
              return (
                <div className="" key={index}>
                  <div
                    className="flex align_items_center"
                    style={{ height: "100px" }}
                  >
                    <img
                      src={items.image}
                      alt=""
                      style={{ height: "auto", width: "110px" }}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default HotelChains;
