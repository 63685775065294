import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import AllRooms from "../../CustomComponents/Property/AllRooms/AllRooms";
import { useRef } from "react";
import SeeBookingSlider from "../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import { useParams } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter } from "../../utils/utils";
import Loader from "../../CustomComponents/Loader/Loader";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";

function CustomerPackages({ allPropertiesPage, selectedButton,  setSelectedButton }) {
  const [editPopup, setEditPopup] = useState(null);
  const [singleSliderBookingData, setSliderSingleBookingData] = useState(null);

  const [singleBookingData, setSingleBookingData] = useState([]);

  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const { id: propertyId } = useParams();

  const handleOnClick = (index) => {
    if (editPopup === index) {
      setEditPopup(null); // Close if the same index is clicked again
    } else {
      setEditPopup(index); // Open for the clicked index
    }
  };

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };
  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setEditPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const fetchSidebarDetails = (bookingId) => {
    // console.log(bookingId);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-booking/${bookingId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // console.log(result);

          setSliderSingleBookingData(result?.data);
          setIsSliderVisible(true);
          // setEditPopup(null)
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchBookingData = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/customers/get-packages-booking`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          // setSingleBookingData(result?.bookings);
          setSingleBookingData([]);

          setLoader(false);
        } else {
          setLoader(false);
          setSingleBookingData([]);
        }
      })
      .catch((error) => setLoader(false));
  };

  useEffect(() => {
    // if (propertyId) {
    fetchBookingData(propertyId);
    // }
  }, []);

  // console.log(singleBookingData, "singleBookingData");

  return (
    <>
      <section className="">
      <div className="agent_dashboard_bookings">
          <div
            onClick={() => setSelectedButton("bookings")}
            className={`agent_button ${
              selectedButton === "bookings" ? "button-selected" : ""
            }`}
          >
            Hotel Bookings
          </div>
          <div
            onClick={() => setSelectedButton("tours")}
            className={`agent_button ${
              selectedButton === "tours" ? "button-selected" : ""
            }`}
          >
            Tour Bookings
          </div>
        </div>
        {loader ? (
          <Loader size="4em" color="#016937" />
        ) : (
          <>
            {/* <div className="flex justify_content_between bg-white  p_10 rounded mt-10 align_items_center">
                <div className="flex">
                  <div className="flex flex_gap_5 px-10 border-right-one">
                    <h6>150</h6>
                    <span>Total Reservations</span>
                  </div>
                  <div className="flex flex_gap_5 px-10 border-right-one">
                    <h6>140</h6>
                    <span>Confirmed</span>
                  </div>
                  <div className="flex flex_gap_5 px-10 border-right-one">
                    <h6>08</h6>
                    <span> Cancelled</span>
                  </div>
                  <div className="flex flex_gap_5 px-10">
                    <h6>02</h6>
                    <span>Pending</span>
                  </div>
                </div>
                <div style={{ position: "relative" }} className="search-bar">
                  <input type="text" placeholder="booking ID or guest name" />
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2108 10.36L13.7092 12.8578L12.8838 13.6832L10.386 11.1848C9.45656 11.9298 8.30054 12.3351 7.10938 12.3334C4.21137 12.3334 1.85938 9.98137 1.85938 7.08337C1.85938 4.18537 4.21137 1.83337 7.10938 1.83337C10.0074 1.83337 12.3594 4.18537 12.3594 7.08337C12.3611 8.27454 11.9558 9.43055 11.2108 10.36ZM10.0406 9.92712C10.7809 9.16581 11.1944 8.14529 11.1927 7.08337C11.1927 4.82762 9.36512 3.00004 7.10938 3.00004C4.85362 3.00004 3.02604 4.82762 3.02604 7.08337C3.02604 9.33912 4.85362 11.1667 7.10938 11.1667C8.17129 11.1684 9.19181 10.7549 9.95312 10.0146L10.0406 9.92712Z"
                      fill="#cccccc"
                    />
                  </svg>
                </div>
              </div> */}
            <div className="overflow_x_scroll_y_hidden">
              {singleBookingData && singleBookingData.length > 0 ? (
                <table className="rates_table">
                  <tr>
                    <th>Booking Details</th>
                    <th>Stay Duration</th>
                    <th>Rooms & Meal Plan</th>
                    <th>Phone</th>
                    <th>Net Amt.</th>
                    <th>Paid Amt.</th>
                    <th>Due Amt.</th>
                    <th>Booking Status</th>
                    <th></th>
                  </tr>
                  {singleBookingData &&
                    singleBookingData?.map((item, index) => (
                      <>
                        <tr>
                          <td>
                            <div
                              className="link_btn pt_10"
                              onClick={() =>
                                fetchSidebarDetails(item?.unique_booking_id)
                              }
                            >
                              {" "}
                              #{item?.unique_booking_id}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-bottom">
                            {" "}
                            {item?.guest?.guest_name}
                          </td>
                          <td className="border-bottom ">
                            {textDateFormatter(item?.tour_start)} to{" "}
                            {textDateFormatter(item?.tour_end)}
                          </td>
                          <td className="border-bottom ">
                            {item?.items &&
                              item?.items?.length > 0 &&
                              item?.items.map((data) => (
                                <p>{`${data?.no_of_rooms} X ${data?.room_plan}`}</p>
                              ))}
                          </td>
                          <td className="border-bottom ">
                            {item?.guest?.guest_phone}
                          </td>
                          <td className="border-bottom ">
                            {formatIndianCurrency(item?.grand_total)}
                          </td>
                          <td
                            style={{ color: "#009A50" }}
                            className="border-bottom "
                          >
                            {formatIndianCurrency(item?.paid_amount)}
                          </td>
                          <td
                            style={{ color: "#BD941B" }}
                            className="border-bottom "
                          >
                            ₹ 0.00
                          </td>
                          <td className="border-bottom ">
                            <button className="confirmed_btn">
                              {item?.booking_status}
                            </button>
                          </td>
                          <td
                            style={{ position: "relative" }}
                            className="border-bottom "
                          >
                            <svg
                              onClick={() => handleOnClick(index)}
                              width="4"
                              height="12"
                              viewBox="0 0 4 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.00156 3.20002C1.22946 3.20002 0.601562 2.57212 0.601562 1.80002C0.601562 1.02792 1.22946 0.400024 2.00156 0.400024C2.77366 0.400024 3.40156 1.02792 3.40156 1.80002C3.40156 2.57212 2.77366 3.20002 2.00156 3.20002ZM2.00156 4.60002C2.77366 4.60002 3.40156 5.22792 3.40156 6.00002C3.40156 6.77212 2.77366 7.40002 2.00156 7.40002C1.22946 7.40002 0.601562 6.77212 0.601562 6.00002C0.601562 5.22792 1.22946 4.60002 2.00156 4.60002ZM2.00156 8.80002C2.77366 8.80002 3.40156 9.42793 3.40156 10.2C3.40156 10.9721 2.77366 11.6 2.00156 11.6C1.22946 11.6 0.601562 10.9721 0.601562 10.2C0.601562 9.42793 1.22946 8.80002 2.00156 8.80002Z"
                                fill="#666666"
                              />
                            </svg>
                            {editPopup === index && (
                              <div
                                className="bg-white rounded p_10 editPopup"
                                ref={popupsRef}
                              >
                                <button
                                  className="block font_12 py-5"
                                  onClick={() =>
                                    fetchSidebarDetails(item?.unique_booking_id)
                                  }
                                >
                                  View Details
                                </button>

                                {/* <button className="block font_12 py-5 cancel-text">
                                     Cancel Booking
                                   </button> */}
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                </table>
              ) : (
                <NoDataFound />
              )}

              {isSliderVisible && (
                <SeeBookingSlider
                  singleBookingData={singleSliderBookingData}
                  onClose={handleCloseSlider}
                  setIsSliderVisible={setIsSliderVisible}
                />
              )}
            </div>
          </>
        )}
        {/* <section className="w-full">
            <div className="promotion_bg">
              <div className="text-center">
                <svg
                  width="245"
                  height="150"
                  viewBox="0 0 245 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4612_5621)">
                    <path
                      d="M78.3621 53.4598L76.8159 53.953C76.7956 53.8689 76.7753 53.7847 76.7492 53.7006C76.549 53.0566 76.4591 54.2141 75.9369 53.7847C75.9746 54.3417 75.1739 48.6209 75.0956 49.1721C75.0637 49.4042 75.3422 53.2364 75.29 53.4627L63.2797 53.5788C63.3465 52.4213 62.7314 52.868 62.1861 51.8469C61.864 52.8071 61.8147 52.1109 61.3041 52.9783C61.0895 53.3467 60.8632 53.6977 60.6166 54.0429C60.4977 54.2141 60.3729 54.3795 60.2453 54.5448L60.0828 54.597L59.2734 38.743C59.2734 33.5153 63.8019 30.9769 67.3615 34.374C69.2529 36.1785 73.8772 33.817 74.9447 35.5286C76.0094 37.2431 76.4214 39.6075 76.4214 42.2213L78.3621 53.4627V53.4598Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M59.4258 143.505L62.0309 143.566L63.4843 131.86L59.6376 131.77L59.4258 143.505Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M68.3623 148.938C68.3623 149.379 68.049 149.736 67.6661 149.736H62.4936C62.4936 149.736 61.9859 147.691 59.9087 146.809L59.7666 149.736H57.0977L57.4197 145.031C57.4197 145.031 56.706 142.513 58.1884 141.227C59.6709 139.939 58.4698 140.119 58.4698 140.119L59.0529 137.207L63.0854 137.679L63.1144 142.251L65.0697 146.789L67.9388 148.207C68.194 148.332 68.3594 148.622 68.3594 148.938H68.3623Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M67.3477 141.596L69.8541 142.318L74.2289 131.364L70.533 130.299L67.3477 141.596Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M74.6149 149.121C74.5047 149.547 74.1101 149.811 73.7388 149.716L68.7375 148.404C68.7375 148.404 68.7636 146.295 66.9794 144.917L66.0975 147.711L63.5156 147.035L65.0213 142.565C65.0213 142.565 64.969 139.948 66.73 139.08C68.4909 138.213 67.284 138.08 67.284 138.08L68.5866 135.411L72.3666 136.893L71.2352 141.323L71.975 146.208L74.3915 148.309C74.6062 148.494 74.6932 148.816 74.612 149.124L74.6149 149.121Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M73.6719 49.178L65.7463 49.9874L60.7305 78.7829L75.9376 81.2081L73.6719 49.178Z"
                      fill="#016937"
                    />
                    <path
                      d="M58.467 54.516L59.2764 73.2798L52.1602 85.7368L57.3356 90.4277C57.3356 90.4277 66.8799 71.5015 67.2019 66.3261C67.5239 61.1506 65.6644 50.4546 65.6644 50.4546L58.467 54.516Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M81.1119 54.516L80.3025 73.2798L87.4187 85.7368L76.418 90.7526C76.418 90.7526 72.696 71.5015 72.374 66.3261C72.0491 61.1506 73.9115 50.4546 73.9115 50.4546L81.109 54.516H81.1119Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M61.7002 77.0017L57.9782 77.4891L55.875 99.972L58.7876 134.018H63.5511L67.0381 96.0904L73.1854 110.326L68.6859 134.018L73.1854 134.477L82.2424 109.841L75.9355 81.2082L61.7002 77.0017Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M76.9695 97.3C77.1464 96.6792 77.4481 96.1512 77.805 95.7741L79.1075 89.7632L81.9505 90.4681L80.323 96.4877C80.4304 96.9983 80.4101 97.6046 80.2331 98.2254C79.8299 99.6411 78.7739 100.581 77.8746 100.326C76.9753 100.07 76.5691 98.7157 76.9724 97.3H76.9695Z"
                      fill="#FFB6B6"
                    />
                    <path
                      d="M77.3613 53.3004C77.3613 53.3004 82.6092 53.0335 82.9892 56.5118C83.3693 59.9901 84.5935 79.807 84.5935 79.807L81.7592 93.1836L77.7181 92.6208L79.5022 75.5947L77.3555 53.2975L77.3613 53.3004Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M32.393 63.6947C32.9906 63.9413 33.4809 64.3039 33.8145 64.7072L39.6513 66.7089L38.6186 69.459L32.8078 67.1324C32.2886 67.1789 31.6851 67.086 31.0875 66.8394C29.7241 66.2737 28.9089 65.1104 29.2686 64.243C29.6283 63.3756 31.0266 63.129 32.3901 63.6947H32.393Z"
                      fill="#FFB6B6"
                    />
                    <path
                      d="M65.7287 55.3484L52.4102 73.326L36.7969 69.0267L37.4757 64.9537L49.3641 67.2049L55.9958 57.0078C58.1629 52.8217 62.4303 51.9775 65.7287 55.3455V55.3484Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M69.1239 47.542C72.3507 47.542 74.9665 44.9261 74.9665 41.6993C74.9665 38.4725 72.3507 35.8567 69.1239 35.8567C65.8971 35.8567 63.2812 38.4725 63.2812 41.6993C63.2812 44.9261 65.8971 47.542 69.1239 47.542Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M77.7144 41.4876H75.7475L75.2137 40.7681C74.9526 40.7681 74.764 41.4615 74.5146 41.4092C73.7574 41.2468 73.0611 40.8522 72.4896 40.3039C72.4403 40.2575 72.391 40.214 72.3388 40.1705V40.185C72.3852 40.8958 71.7963 41.4905 71.0856 41.4905H67.291C66.2554 41.4905 65.4982 42.471 65.7622 43.4719L65.4199 55.7258C65.4199 53.2251 54.3554 40.4548 61.8603 35.3432C63.598 34.1596 64.86 34.3714 67.3606 34.3714C71.2045 32.3203 76.0405 34.235 77.2879 38.4067C77.5664 39.3408 77.7115 40.3765 77.7115 41.4876H77.7144Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M190.728 41.528C190.446 41.2321 190.249 37.9743 189.901 37.7567C190.04 38.2238 189.976 41.7079 189.956 42.1953C186.893 44.284 184.815 38.9171 182.648 38.9171C178.723 38.9171 173.945 30.0777 173.945 26.1527C173.945 22.2276 178.889 20.1418 182.503 18.61C187.29 16.5793 191.668 21.1049 192.222 24.9923C193.672 35.1458 192.477 39.5844 190.728 41.528Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M181.331 33.4399C184.558 33.4399 187.174 30.8241 187.174 27.5973C187.174 24.3705 184.558 21.7546 181.331 21.7546C178.104 21.7546 175.488 24.3705 175.488 27.5973C175.488 30.8241 178.104 33.4399 181.331 33.4399Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M172.828 26.3296L175.738 26.6923L176.454 26.2629L176.533 26.7909C176.942 26.8431 177.357 26.6836 177.606 26.3528C177.803 26.0888 178.047 25.8597 178.326 25.6827C178.288 25.8742 178.262 26.0685 178.241 26.2629C178.201 26.6691 178.485 27.0346 178.888 27.0839L183.173 27.6148C184.2 27.7424 184.83 28.8071 184.447 29.7702C184.447 29.7702 185.488 27.5191 186.419 28.1834C188.45 29.6339 194.299 26.5617 189.32 22.1812C187.742 20.7916 186.463 20.8496 183.983 20.5392C180.42 18.0298 175.387 19.3353 173.632 23.3213C173.24 24.2148 172.967 25.2214 172.831 26.3267L172.828 26.3296Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M185.711 63.0101H168.656C170.086 59.8189 170.965 56.7816 169.59 54.365L185.711 53.4309C184.905 56.6917 184.806 59.8944 185.711 63.0101Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M183.606 34.7427L176.832 34.2756L174.03 37.7801L172.609 38.0383C170.479 38.427 168.962 40.3272 169.055 42.4913L169.609 55.0556L185.802 56.2363L189.681 39.8746L185.008 38.015L183.606 34.7456V34.7427Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M186.549 137.584L183.773 137.65L182.227 125.179L186.323 125.08L186.549 137.584Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M177.026 143.374C177.026 143.844 177.36 144.224 177.769 144.224H183.278C183.278 144.224 183.821 142.045 186.031 141.105L186.185 144.224H189.028L188.683 139.211C188.683 139.211 189.443 136.528 187.865 135.158C186.286 133.786 187.566 133.978 187.566 133.978L186.945 130.874L182.649 131.378L182.617 136.249L180.531 141.085L177.473 142.597C177.2 142.73 177.023 143.038 177.023 143.377L177.026 143.374Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M176.397 137.293L173.62 137.36L172.074 124.889L176.17 124.79L176.397 137.293Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M166.874 143.084C166.874 143.554 167.208 143.934 167.617 143.934H173.126C173.126 143.934 173.668 141.755 175.879 140.815L176.033 143.934H178.876L178.53 138.921C178.53 138.921 179.29 136.238 177.712 134.868C176.134 133.496 177.413 133.688 177.413 133.688L176.793 130.583L172.496 131.088L172.464 135.959L170.378 140.795L167.321 142.307C167.048 142.44 166.871 142.747 166.871 143.087L166.874 143.084Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M169.304 60.6747L166.984 68.2173L166.113 98.9681L172.144 127.445L176.795 126.803L177.918 87.5207L180.804 126.803L187.346 126.557C187.346 126.557 192.803 69.6679 185.936 62.6677L185.315 60.022L169.307 60.6747H169.304Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M165.072 49.033L155.501 56.0187L145.281 59.9931L144.57 57.5823L152.946 52.6129L163.343 44.6438L165.072 49.033Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M143.912 61.002C145.292 60.3126 146.079 59.0891 145.67 58.2692C145.26 57.4493 143.809 57.3436 142.429 58.033C141.049 58.7224 140.262 59.9459 140.671 60.7658C141.081 61.5856 142.532 61.6914 143.912 61.002Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M171.198 39.0681C171.198 39.0681 174.171 43.1498 172.021 44.757C169.872 46.3642 163.362 53.8981 163.362 53.8981L157.98 47.98L171.198 39.071V39.0681Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M31.6501 0C23.5592 0 17 6.5592 17 14.6501C17 21.4559 22.9645 30.3156 28.5606 34.6788C29.0624 35.0704 29.6252 35.3431 30.2112 35.5056L30.7595 37.484C30.8727 37.896 31.2469 38.1803 31.6762 38.1803H31.7459C32.1897 38.1803 32.5756 37.8699 32.6742 37.4376L33.1268 35.404C33.6141 35.23 34.0812 34.9776 34.5076 34.6439C40.768 29.7412 46.3032 21.6851 46.3032 14.6501C46.3032 6.5592 39.744 0 31.653 0H31.6501Z"
                      fill="#016937"
                    />
                    <path
                      d="M34.0909 36.4019C32.33 40.8114 30.9143 39.8077 29.6523 36.4019V35.5142H34.0909V36.4019Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M32.0939 35.9609L31.9924 44.3332L31.9895 44.6175L31.9692 73.6944V75.0434L31.9634 75.4699H31.5195L31.5224 75.0666V73.6944L31.5456 44.6175L31.5688 42.6129L31.6501 35.958L32.0939 35.9609Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M32.0145 75.4699H31.5678L31.5243 75.0667L31.5156 73.6945L31.3357 44.6176L30.4219 35.9117L30.8628 35.8652L31.5707 42.613L31.7825 44.6176L31.8724 73.6945V74.1239L31.971 75.0435L32.0145 75.4699Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M33.3221 35.9117L32.4054 44.6176L32.1501 73.6945L32.1327 75.4699H31.7266L31.8687 74.1239L31.8716 73.6945L31.9615 44.6176L31.9906 44.3333L32.8783 35.8652L33.3221 35.9117Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M218.27 39.0244C213.402 35.0703 206.251 35.813 202.297 40.6838C198.973 44.7771 198.233 53.0247 199.469 58.3829C199.579 58.8645 199.785 59.3025 200.058 59.6854L199.422 61.1447C199.289 61.4493 199.376 61.8032 199.634 62.0092L199.675 62.044C199.942 62.2615 200.324 62.2645 200.597 62.0498L201.862 61.0489C202.242 61.1824 202.645 61.2607 203.063 61.2665C209.225 61.3738 216.492 59.2329 219.93 54.9974C223.884 50.1295 223.141 42.9785 218.27 39.0244Z"
                      fill="#EB442D"
                    />
                    <path
                      d="M201.955 62.1222C197.16 65.2843 197.441 63.3987 199.284 59.9522L199.719 59.4185L202.39 61.5884L201.955 62.1222Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M200.97 60.8806L196.821 65.8675L196.679 66.0357L182.461 83.5231L181.803 84.3354L181.591 84.5906L181.324 84.3731L181.524 84.1323L182.195 83.3055L196.412 65.8211L197.407 64.6258L200.706 60.6602L200.97 60.8806Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M181.621 84.614L181.352 84.3964L181.526 84.1324L182.19 83.3027L196.286 65.7168L199.991 60.0308L200.278 60.2193L197.409 64.626L196.556 65.9343L182.402 83.4768L182.196 83.735L181.804 84.3355L181.621 84.614Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M201.736 61.4493L196.932 66.2417L182.572 83.613L181.696 84.6748L181.449 84.4746L182.195 83.7349L182.404 83.4767L196.665 66.0242L196.822 65.8675L201.492 61.2056L201.736 61.4493Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M127.956 13.3853C121.733 16.4372 119.163 23.9537 122.215 30.1735C124.779 35.4069 132.708 39.9702 138.655 41.2147C139.189 41.3279 139.722 41.325 140.236 41.2292L141.405 42.5434C141.649 42.8161 142.043 42.8944 142.371 42.732L142.423 42.7059C142.765 42.5376 142.945 42.1547 142.855 41.7833L142.438 40.0514C142.748 39.7352 143.012 39.3639 143.212 38.9461C146.177 32.8163 147.395 24.5339 144.744 19.1235C141.692 12.9008 134.176 10.3305 127.956 13.3824V13.3853Z"
                      fill="#016937"
                    />
                    <path
                      d="M143.557 40.4576C143.461 42.7871 142.753 44.0752 140.142 42.1315L139.809 41.4498L143.223 39.7759L143.557 40.4576Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M141.857 40.8696L144.935 47.3447L145.039 47.5652L142.681 71.2607L143.189 72.2993L143.345 72.63L143.003 72.7982L142.855 72.4849L142.339 71.4289L144.697 47.7305L143.96 46.1814L141.512 41.035L141.857 40.8696Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M143.385 72.6096L143.043 72.7779L142.857 72.4849L142.332 71.4318L144.537 47.8117L140.551 41.4614L140.873 41.2583L143.962 46.1813L144.879 47.6434L142.605 71.2983L142.77 71.6291L143.191 72.2992L143.385 72.6096Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M142.783 40.3677L145.362 47.4055L142.82 71.1939L143.479 72.5632L143.166 72.7169L142.768 71.629L142.606 71.2983L145.019 47.5738L144.935 47.3446L142.426 40.4982L142.783 40.3677Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M0.558594 149.655C0.558594 149.846 0.712348 150 0.903815 150H117.899C118.09 150 118.244 149.846 118.244 149.655C118.244 149.463 118.09 149.31 117.899 149.31H0.903815C0.712348 149.31 0.558594 149.463 0.558594 149.655Z"
                      fill="#CCCCCC"
                    />
                    <path
                      d="M126.754 143.853C126.754 144.044 126.908 144.198 127.099 144.198H244.094C244.286 144.198 244.439 144.044 244.439 143.853C244.439 143.661 244.286 143.508 244.094 143.508H127.099C126.908 143.508 126.754 143.661 126.754 143.853Z"
                      fill="#CCCCCC"
                    />
                    <path
                      d="M191.735 53.4918L191.584 65.3395L188.637 75.9022L186.281 75.0231L187.326 65.3424L187.189 52.2415L191.735 53.4918Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M188.629 77.6051C188.905 76.0872 188.398 74.7235 187.497 74.5593C186.595 74.3952 185.64 75.4926 185.364 77.0106C185.087 78.5285 185.594 79.8921 186.496 80.0563C187.397 80.2205 188.352 79.1231 188.629 77.6051Z"
                      fill="#A0616A"
                    />
                    <path
                      d="M188.159 39.3872C188.159 39.3872 193.21 39.4568 193.204 42.1403C193.198 44.8237 192.647 54.8061 192.647 54.8061L184.684 55.5633L188.162 39.3901L188.159 39.3872Z"
                      fill="#E6E6E6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4612_5621">
                      <rect
                        width="243.88"
                        height="150"
                        fill="white"
                        transform="translate(0.558594)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h1 className="mt-20">Coming Soon...</h1>
              <p className="text-center mt-10">
                Our exiciting new website coming soon! Check back later...
              </p>
            </div>
          </section> */}
      </section>
    </>
  );
}

export default CustomerPackages;
