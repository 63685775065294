import React from "react";
import StarRating from "../StarRating/StarRating";
import { formatIndianCurrency } from "../../utils/utils";

function CustomSliderCard({
  items,
  onViewRoomsClick,
  selectedHotelId,
  handleClose,
  updateHotelNameForSelectedDay,
}) {
  const isSelected = items.hotel_id === selectedHotelId;

  const cardStyle = isSelected
    ? {
      boxShadow: "0 4px 8px 0 rgba(74, 74, 74, .1)",
      border: "1px solid #016937",
      position: "relative", // Ensure position is relative for overlay additional styles
    }
    : {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      position: "relative", // Ensure position is relative for overlay additional styles
    };
  // console.log(selectedHotelId)
  return (
    <div className="hotel_card" style={cardStyle}>
      {isSelected && <div className="hotel_card_overlay"></div>}
      <div className="Edit_accommodation_main hotel-content-flex hotel-main-card">
        <div className="edit_accommodation_img hotel_view_img">
          <div className="edit_accommodation_img_small">
            <img
              src={
                items.hotel_image[0]
                  ? items.hotel_image[0]
                  : "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
              }
              alt="hotel-img"
            />
          </div>
          {isSelected && (
            <>
              {/* <span class="badgeBar"></span> */}
              <div className={`selected_banner ${isSelected ? "show" : ""}`}>
                Selected
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#016937"
                  height="12px"
                  width="12px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 490 490"
                  xmlSpace="preserve"
                >
                  <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528" />
                </svg>
              </div>
            </>
          )}
        </div>
        <div className="edit_accomodation_main hotel-content">
          <div className="edit_accomodation_content hotel_main">
            <h4>
              <b>{items.hotel_name}</b>
            </h4>
            <div className="edit_accomodation_stars">
              <StarRating value={items.hotel_rating ? items.hotel_rating : 0} />
            </div>
            <small>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                  fill="#888888"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                  fill="#888888"
                />
              </svg>{" "}
              {items.hotel_address}
            </small>
            <small>{items.distance}</small>
          </div>
          <div className="room_rates align_items_center">
            <div className="room-flex">
              <div>
                <p>Starting From</p>
                <h5 className="mt-5">
                  {formatIndianCurrency(items.starting_price || 10)} <small> + GST</small>{" "}
                </h5>
              </div>
              <div className="view_room_btn mt-5">
                <button
                  onClick={() => [onViewRoomsClick(items)]}
                  className="primary_btn"
                  type="button"
                  style={{ width: "90px" }}
                >
                  View Rooms
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomSliderCard;
