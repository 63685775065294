import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../../CustomComponents/Loader/Loader";

const DashboardCustomer = () => {
  const [loader, setLoader] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  // console.log(overviewData);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  const { id: propertyId } = useParams();

  const fetchVendorOverview = () => {
    // const myHeaders = new Headers();
    // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
    // myHeaders.append("token", localStorage.getItem("token"));
    //   const  myHeaders: {
    //         "Content-Type": "application/json",
    //         key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/customers/get-dashboard-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setOverviewData(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchVendorOverview();
  }, []);

  return (
    <section className="w-full">
      <div className="basic-info-container">
        <div>
            <h4 className="text-left"> Overview</h4>
        </div>
        {loader ? (
          <Loader size="4em" color="#016937" />
        ) : (
          <div className="property-details-flex">
            <div className="property_details p_20">
              <span className="font-3 font_12">
                {overviewData?.data?.today_average_revenue}
              </span>
              <h6>Today's Average Revenue </h6>
            </div>
            <div className="property_details p_20">
              <span className="font-3 font_12">
                {overviewData?.data?.today_checkin}
              </span>
              <h6>Today's Checkin </h6>
            </div>
            <div className="property_details p_20">
              <span className="font-3 font_12">
                {overviewData?.data?.today_room_nights}
              </span>
              <h6>Today's Room Nights </h6>
            </div>
            <div className="property_details p_20">
              <span className="font-3 font_12">
                {overviewData?.data?.today_revenue}
              </span>
              <h6>Today's Revenue </h6>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DashboardCustomer;
