import React, { useState } from "react";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial";
import { highlight } from "../../constants";
import { accordionItems } from "../../constants";

import bannerImg from "../../assets/images/aboutus-banner.png";
import directorImg from "../../assets/images/mountain-mysteries-director.png";
import companyImg from "../../assets/images/mountain-mysteries-company.png";
import teamImg from "../../assets/images/mountain-mysteries-team.png";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function AboutUs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <>
      <PageMetaData
        title="About | Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title="About | Mountain Mysteries"
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/about-us`}
      />
      <Header />
      <div id="aboutus">
        <section>
          <div className="banner_img">
            <div className="top-banner-img">
              <img src={bannerImg} alt="banner-image" />
              <div className="aboutus_heading">
                <h1 className="text-white">Adventure Awaits-Go Find it!</h1>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- content section --> */}
        <section>
          <div className="container">
            <div className="common_padding p_b_10 ">
              <p className="text_center">
                From Dreams to Destinations: Where 200,000 Travelers Have Found
                Their Fun!
              </p>
            </div>
          </div>
        </section>
        {/* <!-- choose us --> */}
        <section className="packages_section">
          <div className="container">
            <div className="cards-flex flex_nowrap">
              {highlight.map((items) => {
                return (
                  <div
                    className="highlight"
                    style={{ background: "transparent" }}
                  >
                    <img
                      src={items.image}
                      alt={items.alternative_text}
                      style={{ width: "50px", margin: "0 auto" }}
                    />
                    <h5>{items.description}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* <!-- tour story section --> */}
        <section>
          <div className="container">
            <div className="overview-content">
              <div className="mission_content">
                <h3 className="m_0_auto">Our Mission</h3>
                <p>
                  Embracing the Himalayas, Mountain Mysteries is dedicated to
                  crafting unforgettable journeys that blend natural beauty,
                  cultural richness, and responsible travel. With personalized
                  care and expertise, we aim to inspire and empower travelers
                  while leaving a positive impact on local communities and
                  environments.
                </p>
              </div>
              <div className="main_div">
                <div className="description-content">
                  <h3 className="text-left">Message from the Director </h3>
                  <h5>SANJAY SHARMA</h5>
                  <p>
                    Twenty years ago, Mountain Mysteries was founded with a
                    vision to offer quality services in the Himalayas. The
                    concept was simple: provide travelers with accurate
                    information about the Himalayan region and deliver
                    high-quality services. We established a team of
                    professionals to execute itineraries in the Indian
                    Himalayas, equipped with knowledge of the region's
                    technicalities and offering remote support when needed most.
                    Exploring remote Himalayan regions allowed us to immerse
                    ourselves in the subcontinent's diverse cultures,
                    lifestyles, religions, and scenery.
                  </p>
                  <br />
                  <p>
                    {" "}
                    "We are frequently asked, 'What do you do?'" Modestly but
                    realistically, we answer that we provide tourism services in
                    places where most have never ventured. Known for our
                    commitment to quality, we uphold the highest standards in
                    the industry.
                  </p>
                </div>
                <div className="img_div">
                  <img src={directorImg} alt="mountain-mysteries-director" />
                </div>
              </div>
              <div className="main_div reverse_flex">
                <div className="img_div">
                  <img src={companyImg} alt="mountain-mysteries-company" />
                </div>
                <div className="description-content">
                  <h3 className="text-left">
                    Your Trusted Guide to the Himalayas
                  </h3>
                  <p>
                    Founded in Himachal Pradesh on March 15, 2002, Mountain
                    Mysteries has earned acclaim for delivering premium services
                    across Himachal Pradesh and the Western Himalayas. Over the
                    past nineteen years, we've established our own hotels, a
                    fleet of transportation, camps, and logistics support in
                    remote Himalayan regions. Our dedicated team of
                    professionals is committed to providing exceptional tourism
                    experiences throughout the Indian Himalayas."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- meet our team section --> */}
        <section>
          <div className="common_padding">
            <h3>Meet Our Team</h3>
          </div>
          <img src={teamImg} alt="mountain-mysteries-team" />
        </section>
        {/* <!-- testimonial --> */}
        {/* <Testimonial /> */}
        {/* <!-- accordion start --> */}
        <section>
          <div className="container mt-20">
            <h3 className="mb-10">FAQs</h3>
            {accordionItems.map((item, index) => (
              <div className="accordion mb-20">
                <div className="accordion-item" key={index}>
                  <div
                    className="flex justify_content_between p_10 cursor_pointer accordion_icon align_items_center"
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className="accordion-item-header">{item.header}</div>
                    <svg
                      className={`accordion-icon ${
                        activeIndex === index ? "rotated" : ""
                      }`}
                      width="10"
                      height="10"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.25 0.75L4.75 4.25L1.25 0.75" stroke="#000" />
                    </svg>
                  </div>
                  <div
                    className={`accordion-item-body ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <div className="accordion-item-body-content">
                      {item.content}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
