import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { sassTrue } from "sass";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../NoDataFound/NoDataFound";

const AllRooms = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { id } = useParams();

  const handleEdit = (room_id, rooms) => {
    navigate(`/partner/rooms/edit/${room_id}/${id}`, { state: { rooms } });
  };

  const openDeletePopup = (id) => {
    setSelectedId(id);
    setDeletePopup(true);
  };
  // console.log(selectedId, "dont know id");

  const closeDeletePopup = () => {
    setDeletePopup(false);
  };

  const { id: propertyId } = useParams();

  const getRooms = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rooms/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setRooms(result?.rooms);
          setLoader(false);
        } else {
          setRooms([]);
          setLoader(false);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (propertyId) {
      getRooms(propertyId);
    }
  }, [propertyId]);

  // Single Room
  const fetchSingleRoom = (rommId) => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://extranet.travcatalyst.com/apiv1/vendor/get-single-room/${rommId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (result.success) {
          getRooms(propertyId);
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteRooms = () => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/delete-room/${selectedId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setDeletePopup(false);
          getRooms(propertyId);
          toast.success(result.message);
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <section className="w-full table-data">
      <div className="flex justify_content_between">
        <h4>Rooms</h4>
        <div className="flex flex_gap_10">
          <Link
            to={`/vendor/property/add-room/${id}`}
            className="primary_btn fit-width"
          >
            Add
          </Link>
        </div>
      </div>
      {loader ? (
        <Loader size="4em" color="#016937" />
      ) : (
        <>
          {rooms?.length > 0 ? (
            <>
              {" "}
              <table className="inventory_table">
                <tr>
                  <th> NAME</th>
                  <th></th>
                </tr>
                {rooms?.map((item) => {
                  return (
                    <tr>
                      <td>{item?.name}</td>
                      <td>
                        <div className="flex justify_content_end flex_gap_10">
                          <span onClick={() => openDeletePopup(item?.id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="20px"
                              height="20px"
                            >
                              <path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z" />
                            </svg>
                          </span>
                          <span onClick={() => handleEdit(item?.id, item)}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_2797_7470"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                              >
                                <rect width="20" height="20" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_2797_7470)">
                                <path
                                  d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                                  fill="#333333"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </>
          ) : (
            <>
              <NoDataFound />
            </>
          )}
        </>
      )}
      {deletePopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div>
              <div className="flex justify_content_between">
                <h4>Are you sure to Delete.</h4>
                <svg
                  onClick={() => closeDeletePopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <button
                className="primary_btn mt-10"
                type="button"
                onClick={() => deleteRooms()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AllRooms;
