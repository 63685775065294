import React from "react";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { showSuccessToast } from "../../../assets/toastUtils";
import { useLocation } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter, textDateFormatterNoAdd, textDateFormatterNoAddInIteration } from "../../../utils/utils";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Loader from "../../../CustomComponents/Loader/Loader";
import AddressAutocomplete from "../../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import SeeBookingSlider from "../../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import AgentHeader from "../AgentHeader/AgentHeader";

function AgentPackages({ setSelectedButton, selectedButton }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const role = query.get("role");
  const [loader, setLoader] = useState(true);
  const [allBookings, setAllBookings] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResult, setUploadResult] = useState("");

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    handleSubmitCertificate(file, type);
  };

  const slug = window.location.pathname.split("/");
  // console.log(role ,"slug")

  const [activeTab, setActiveTab] = useState("Profile");
  useEffect(() => {
    if (role === "bookings") {
      setActiveTab("Bookings");
    } else {
      setActiveTab("Profile");
    }
  }, []);
  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const handleChangeClick = async () => {
    setIsSliderVisible(true);
  };
  const [formData, setFormData] = useState({
    company_name: "",
    phone: "",
    email: "",
    gst_number: "",
    country: "",
    state: "",
    city: "",
    address: "",
    agent_category: "",
    pan: "",
    agent_category_discount: "",
    agent_discount_type: "",
    tan: "",
  });

  const [agentDetails, setAgentDetails] = useState(null);
  const [agentUpdateData, setAgentUpdateData] = useState(null);
  const userToken = localStorage.getItem("token");
  const agent_id = agentDetails?.AgentId;
  const account_id = agentDetails?.AccountId;
  const status = agentUpdateData?.data?.status;

  //fetch categories
  const [categoryDetail, setCategoryDetail] = useState(null);
  const [singleBookingData, setSingleBookingData] = useState(null);
  const [error, setError] = useState(null);

  // console.log("singleBookingData", singleBookingData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetails);

  const [agentAccountDetails, setAgentAccountDetails] = useState({});
  // console.log(agentAccountDetails);

  const fetchAccountDetails = (id) => {
    setLoader(true);
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/credit-balance/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setAgentAccountDetails(result.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => console.error("Error fetching single rate:", error));
  };

  useEffect(() => {
    if (userDetails.AgentId) {
      fetchAccountDetails(userDetails.AgentId);
    }
  }, [userDetails.AgentId]);

  const handleAddressData = (data) => {
    // console.log(data, "data");
    setAddressData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log({ ...formData, ...addressData });

    const raw = JSON.stringify({ ...formData, ...addressData });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/agent/update/${agent_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAgentUpdateData(data);
        localStorage.setItem("isVerified", data?.data?.status || "false"); // Ensure proper saving
        showSuccessToast("Profile Saved Successfully");
        fetchAgentDetails();
        // navigate("/");
        // window.location.href = "/";
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );
  };

  const profileFilter = (tab) => {
    setActiveTab(tab);
  };

  const fetchAgentDetails = () => {
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/agents/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAgentDetails(data.data.agent);

        localStorage.setItem("userDetails", JSON.stringify(data.data.agent));

        localStorage.setItem("role", "agent");

        setAddressData({
          country: data.data.agent.Country || "",
          state: data.data.agent.State || "",
          city: data.data.agent.City || "",
          address: data.data.agent.Address || "",
          latitude: data.data.agent.Latitude || "",
          longitude: data.data.agent.Longitude || "",
          placeId: data.data.agent.PlaceId || "",
        });
        setFormData({
          company_name: data.data.agent.Name || "",
          name: data.data.agent.Name || "",
          phone: data.data.agent.PhoneNumber || "",
          email: data.data.agent.Email || "",
          gst_number: data.data.agent.GST_Number || "",
          country: data.data.agent.Country || "",
          state: data.data.agent.State || "",
          city: data.data.agent.City || "",
          address: data.data.agent.Address || "",
          agent_category_discount:
            +data.data.agent.agent_category_discount || "",
          agent_discount_type: data.data.agent.agent_discount_type || "",
          agent_category: data.data.agent.AgentCategoryName || "",
          pan: data.data.agent.PAN_Number || "",
          tan: data.data.agent.TAN_Number || "",
        });

        if (data.success) {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  // console.log(formData);

  useEffect(() => {
    const agentData = localStorage.getItem("role");
    // console.log(agentData);
    if (agentData === "agent") {
      fetchAgentDetails();
      fetchAgentsBookings();
      // fetchSidebarDetails();
    } else {
      fetchAgentDetails();
      // fetchAgentsBookings();
    }
    fetchAgentsBookings();
  }, [localStorage.getItem("role")]);

  const fetchCategoryDetail = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/agents/category-list/${account_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assuming the API returns JSON
      })
      .then((result) => {
        setCategoryDetail(result);
      })
      .catch((error) => {
        setError(error);
        console.error(
          "There was an error fetching the category details:",
          error
        );
      });
  };
  const fetchAgentsBookings = () => {
    setLoader(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: `Bearer ${userToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/get-packages-booking`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assuming the API returns JSON
      })
      .then((result) => {
        if (result.success) {
          const groupByCategory = result?.data?.reduce((group, booking) => {
            const { created_at: tour_start } = booking;
            group[tour_start] = group[tour_start] ?? [];
            group[tour_start].push(booking);
            return group;
          }, {});
          setLoader(false);

          setAllBookings(groupByCategory);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError(error);
        console.error(
          "There was an error fetching the category details:",
          error
        );
      });
  };

  // console.log(allBookings);

  const handleSubmitCertificate = (file, type) => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("collection", type);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: formData,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/agent-doc-upload/${agent_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          setUploadResult(result);
          fetchAgentDetails();
          toast.success("File uploaded successfully");
        } else {
          toast.error(
            result.message ? result.message : "Error uploading file."
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setUploadResult("Error uploading file.");
      });
  };

  const fetchSidebarDetails = (bookingId) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/single-booking/${bookingId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          handleChangeClick();
          setSingleBookingData(result?.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const panImageData = agentDetails?.imageUrl?.find(
    (image) => image.collection === "PAN"
  );

  const gstImageData = agentDetails?.imageUrl?.find(
    (image) => image.collection === "gst_number"
  );
  // console.log(panImageData);

  const deleteImageHandler = async (mediaId) => {
    // console.log(mediaId);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const raw = JSON.stringify({ mediaId });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/agents/delete-image`,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        toast.success("Image deleted successfully");
        fetchAgentDetails();
      } else {
        toast.error(result.message ? result.message : "Error deleting image.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="common_background_profile m_20">
      <div className="flex justify_content_between align_items_center flex_wrap">
        <h4 className="hide_heading">My Tours</h4>
        <div className="agent_dashboard_bookings">
          <div
            onClick={() => setSelectedButton("bookings")}
            className={`agent_button ${
              selectedButton === "bookings" ? "button-selected" : ""
            }`}
          >
            Hotel Bookings
          </div>
          <div
            onClick={() => setSelectedButton("tours")}
            className={`agent_button ${
              selectedButton === "tours" ? "button-selected" : ""
            }`}
          >
            Tour Bookings
          </div>
        </div>
      </div>
      <div className="common_background mt-10">
        <div className="profile_content Bookings">
          {loader ? (
            <Loader size="4em" color="#016932" />
          ) : Object.keys(allBookings).length > 0 ? (
            <div>
              {Object.keys(allBookings).map((item) => (
                <div key={item}>
                  <h6 className="mt-10"> {textDateFormatterNoAddInIteration(item)}</h6>
                  {allBookings[item]?.length > 0 &&
                    allBookings[item].map((booking) => (
                      <div
                        key={booking?.unique_booking_id}
                        className="booking_details"
                      >
                        <div className="agent details_heading">
                          <div className="vector_img">
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 16H17V8H11V16H13V10H15V16ZM1 16V1C1 0.734784 1.10536 0.48043 1.29289 0.292893C1.48043 0.105357 1.73478 0 2 0H16C16.2652 0 16.5196 0.105357 16.7071 0.292893C16.8946 0.48043 17 0.734784 17 1V6H19V16H20V18H0V16H1ZM5 8V10H7V8H5ZM5 12V14H7V12H5ZM5 4V6H7V4H5Z"
                                fill="#888888"
                              />
                            </svg>
                          </div>
                          <div>
                            <p>{booking?.package_name}</p>
                            {/* <button
                              className="view_btn"
                              onClick={() =>
                                fetchSidebarDetails(booking?.unique_booking_id)
                              }
                            >
                              See Details
                            </button> */}
                          </div>
                        </div>
                        <div className="agent">
                          <div>
                            <small>Total Amount</small>
                            <p>{formatIndianCurrency(booking?.grand_total)}</p>
                          </div>
                          <div>
                            <small>Check-In</small>
                            <p>{textDateFormatter(booking?.tour_start)}</p>
                          </div>
                          <div>
                            <small>Check-Out</small>
                            <p>{textDateFormatter(booking?.tour_end)}</p>
                          </div>
                          <div>
                            <span className="change_btn text-capitalize">
                              {booking?.booking_status}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
              {isSliderVisible && singleBookingData && (
                <SeeBookingSlider
                  singleBookingData={singleBookingData}
                  onClose={handleCloseSlider}
                  setIsSliderVisible={setIsSliderVisible}
                />
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentPackages;
