import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logoimg from "../../../assets/images/Mountain-mystries-logo.png";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { showSuccessToast } from "../../../assets/toastUtils";
import ActivePropertySelectBox from "../../../CustomComponents/Property/ActivePropertySelectBox/ActivePropertySelectBox";
import LoginBtn from "../../../CustomComponents/Login/LoginBtn";
import CustomerDetailDropdown from "./CustomerDetailsDropdown";

function CustomerHeader({ showSelectProperty, vendorProperty }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hideToggle, setHideToggle] = useState(false);
  const navigate = useNavigate();
  const handleShowToggle = () => {
    setHideToggle(!hideToggle);
  };
  const [accountDetails, setAccountDetails] = useState(false);
  const handleOnClick = (e) => {
    e.preventDefault();
    setAccountDetails(!accountDetails);
  };
  const [loginDetails, setLoginDetails] = useState(false);
  const handleOnClickLogIn = (e) => {
    e.preventDefault();
    setLoginDetails(!loginDetails);
  };

  // console.log("isLoggedIn", isLoggedIn);
  useEffect(() => {
    // Check if token is present in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();

    navigate("/");
    localStorage.clear();
    showSuccessToast("Logged Out Successful");
  };
  const customButtonStyles = {
    fontSize: "12px",
    fontWeight: "500",
  };

  // useEffect(() => {
  //   const updateHideToggle = () => {
  //     if (window.innerWidth <= 640) {
  //       setHideToggle(false);
  //     } else {
  //       setHideToggle(true);
  //     }
  //   };

  //   updateHideToggle(); // Set the initial state based on screen size
  //   window.addEventListener("resize", updateHideToggle);

  //   return () => window.removeEventListener("resize", updateHideToggle);
  // }, []);

  const [customerData, setCustomerData] = useState([]);
  const [agentData, setAgentData] = useState([]);

  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    // console.log(storedUserDetails);
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      // console.log(userDetails);
      setName(userDetails.Name);
    }

    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);
  const closePriceDetails = (id) => {
    setHideToggle(false);
  };

  const { id } = useParams();
  // console.log(id);
  const [dropdown, setDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setDropdown((prevState) => !prevState);
  };

  return (
    <header>
      <button className="toggle_menu_icon" onClick={handleShowToggle}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 12.5V10.5H18V12.5H0ZM0 7.5V5.5H18V7.5H0ZM0 2.5V0.5H18V2.5H0Z"
            fill="#fff"
          />
        </svg>
      </button>
      {hideToggle && (
        <div className="fixed_bg_navbar">
          <div className="" id="vendorProperty">
            <div className="sidebar" id="sidebar">
              <div className="close_popup">
                <svg
                  onClick={() => setHideToggle(false)}
                  width="45"
                  height="45"
                  style={{ marginLeft: "10px" }}
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>
              <NavLink
                className="sidebar_btn flex align_items_center flex_gap_5"
                to={`/customer-dashboard`}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H8V2H2V16ZM10 16H16V9H10V16ZM10 7H16V2H10V7Z"
                    fill="#1C1B1F"
                  />
                </svg>
                Dashboard
              </NavLink>
              <NavLink
                className="sidebar_btn flex align_items_center flex_gap_5"
                to={`/customer-profile`}
              >
                <svg
                  className="m_0"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.04551 6.54502C6.14832 6.54502 5.38027 6.22557 4.74137 5.58666C4.10246 4.94775 3.78301 4.17971 3.78301 3.28252C3.78301 2.38533 4.10246 1.61729 4.74137 0.978379C5.38027 0.339473 6.14832 0.0200195 7.04551 0.0200195C7.94269 0.0200195 8.71074 0.339473 9.34965 0.978379C9.98855 1.61729 10.308 2.38533 10.308 3.28252C10.308 4.17971 9.98855 4.94775 9.34965 5.58666C8.71074 6.22557 7.94269 6.54502 7.04551 6.54502ZM0.520508 11.4388V10.7863C0.520508 10.3241 0.639453 9.89928 0.877344 9.51186C1.11523 9.12443 1.43129 8.82877 1.82551 8.62486C2.66832 8.20346 3.52473 7.8874 4.39473 7.6767C5.26473 7.466 6.14832 7.36064 7.04551 7.36064C7.94269 7.36064 8.82629 7.466 9.69629 7.6767C10.5663 7.8874 11.4227 8.20346 12.2655 8.62486C12.6597 8.82877 12.9758 9.12443 13.2137 9.51186C13.4516 9.89928 13.5705 10.3241 13.5705 10.7863V11.4388C13.5705 11.8874 13.4108 12.2714 13.0913 12.5908C12.7719 12.9103 12.3879 13.07 11.9393 13.07H2.15176C1.70316 13.07 1.31914 12.9103 0.999687 12.5908C0.680234 12.2714 0.520508 11.8874 0.520508 11.4388ZM2.15176 11.4388H11.9393V10.7863C11.9393 10.6367 11.9019 10.5008 11.8271 10.3785C11.7523 10.2561 11.6538 10.161 11.5314 10.093C10.7974 9.72596 10.0565 9.45068 9.30887 9.26717C8.56121 9.08365 7.80676 8.99189 7.04551 8.99189C6.28426 8.99189 5.5298 9.08365 4.78215 9.26717C4.03449 9.45068 3.29363 9.72596 2.55957 10.093C2.43723 10.161 2.33867 10.2561 2.26391 10.3785C2.18914 10.5008 2.15176 10.6367 2.15176 10.7863V11.4388ZM7.04551 4.91377C7.4941 4.91377 7.87812 4.75404 8.19758 4.43459C8.51703 4.11514 8.67676 3.73111 8.67676 3.28252C8.67676 2.83393 8.51703 2.4499 8.19758 2.13045C7.87812 1.811 7.4941 1.65127 7.04551 1.65127C6.59691 1.65127 6.21289 1.811 5.89344 2.13045C5.57398 2.4499 5.41426 2.83393 5.41426 3.28252C5.41426 3.73111 5.57398 4.11514 5.89344 4.43459C6.21289 4.75404 6.59691 4.91377 7.04551 4.91377Z"
                    fill="#000"
                  />
                </svg>
                My Profile
              </NavLink>
              <NavLink
                className="sidebar_btn flex align_items_center  flex_gap_5"
                to={`/customer-booking`}
              >
                <svg
                  className="m_0"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3887_4171"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_3887_4171)">
                    <path
                      d="M10.95 18.35L7.4 14.8L8.85 13.35L10.95 15.45L15.15 11.25L16.6 12.7L10.95 18.35ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>
                My Booking
              </NavLink>
            </div>
          </div>
        </div>
      )}

      {!vendorProperty ? (
        <div style={{ padding: "0 10px" }}>
          <div className="flex_align_center justify_content_between HeaderMain-content">
            <div
              className="w-half Header-main flex align_items_center"
              style={{ gap: "20px" }}
            >
              <div style={{ width: "65px" }}>
                <NavLink className="Header-icon" to="/customer-profile" exact>
                  <img src={logoimg} alt="logo-image" className="logo" />
                </NavLink>
              </div>
            </div>
            <div className="flex justify_content_between align_items_center flex_gap_15">
              <Link to={"/"} className="visit-website">
                Visit Website
              </Link>
              {isLoggedIn ? (
                <div className="">
                  <CustomerDetailDropdown
                    name={name}
                    userType={userType}
                    handleLogout={handleLogout}
                  />
                </div>
              ) : (
                <div className="Mainregister-btn">
                  <LoginBtn />
                </div>
              )}
              <div className="support_btn cursor_pointer">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2485_129"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="17"
                  >
                    <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2485_129)">
                    <path
                      d="M8 15.8335V14.5002H12.6667V13.8335H10V8.50016H12.6667V7.8335C12.6667 6.54461 12.2111 5.44461 11.3 4.5335C10.3889 3.62239 9.28889 3.16683 8 3.16683C6.71111 3.16683 5.61111 3.62239 4.7 4.5335C3.78889 5.44461 3.33333 6.54461 3.33333 7.8335V8.50016H6V13.8335H3.33333C2.96667 13.8335 2.65278 13.7029 2.39167 13.4418C2.13056 13.1807 2 12.8668 2 12.5002V7.8335C2 7.01127 2.15833 6.23627 2.475 5.5085C2.79167 4.78072 3.22222 4.14461 3.76667 3.60016C4.31111 3.05572 4.94722 2.62516 5.675 2.3085C6.40278 1.99183 7.17778 1.8335 8 1.8335C8.82222 1.8335 9.59722 1.99183 10.325 2.3085C11.0528 2.62516 11.6889 3.05572 12.2333 3.60016C12.7778 4.14461 13.2083 4.78072 13.525 5.5085C13.8417 6.23627 14 7.01127 14 7.8335V14.5002C14 14.8668 13.8694 15.1807 13.6083 15.4418C13.3472 15.7029 13.0333 15.8335 12.6667 15.8335H8ZM3.33333 12.5002H4.66667V9.8335H3.33333V12.5002ZM11.3333 12.5002H12.6667V9.8335H11.3333V12.5002Z"
                      fill="#333333"
                    />
                  </g>
                </svg>
                Support
                <div className="supportDetails">
                  <Link className="flex" to="tel:+919805021966">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_992"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2263_992)">
                          <path
                            d="M14.9625 16.25C13.4 16.25 11.8563 15.9094 10.3313 15.2281C8.80625 14.5469 7.41875 13.5813 6.16875 12.3313C4.91875 11.0813 3.95312 9.69375 3.27188 8.16875C2.59063 6.64375 2.25 5.1 2.25 3.5375C2.25 3.3125 2.325 3.125 2.475 2.975C2.625 2.825 2.8125 2.75 3.0375 2.75H6.075C6.25 2.75 6.40625 2.80937 6.54375 2.92812C6.68125 3.04688 6.7625 3.1875 6.7875 3.35L7.275 5.975C7.3 6.175 7.29375 6.34375 7.25625 6.48125C7.21875 6.61875 7.15 6.7375 7.05 6.8375L5.23125 8.675C5.48125 9.1375 5.77812 9.58437 6.12187 10.0156C6.46562 10.4469 6.84375 10.8625 7.25625 11.2625C7.64375 11.65 8.05 12.0094 8.475 12.3406C8.9 12.6719 9.35 12.975 9.825 13.25L11.5875 11.4875C11.7 11.375 11.8469 11.2906 12.0281 11.2344C12.2094 11.1781 12.3875 11.1625 12.5625 11.1875L15.15 11.7125C15.325 11.7625 15.4688 11.8531 15.5812 11.9844C15.6937 12.1156 15.75 12.2625 15.75 12.425V15.4625C15.75 15.6875 15.675 15.875 15.525 16.025C15.375 16.175 15.1875 16.25 14.9625 16.25ZM4.51875 7.25L5.75625 6.0125L5.4375 4.25H3.76875C3.83125 4.7625 3.91875 5.26875 4.03125 5.76875C4.14375 6.26875 4.30625 6.7625 4.51875 7.25ZM11.2313 13.9625C11.7188 14.175 12.2156 14.3438 12.7219 14.4688C13.2281 14.5938 13.7375 14.675 14.25 14.7125V13.0625L12.4875 12.7063L11.2313 13.9625Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>
                      <b style={{ fontSize: "12px" }}>Call Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>+91-98050-21966</span>
                    </span>
                  </Link>
                  <Link className="flex" to="mailto:info@mountainmysteries.com">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_999"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect
                            x="0.333374"
                            y="0.5"
                            width="18"
                            height="18"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_2263_999)">
                          <path
                            d="M3.33337 15.5C2.92087 15.5 2.56775 15.3531 2.274 15.0594C1.98025 14.7656 1.83337 14.4125 1.83337 14V5C1.83337 4.5875 1.98025 4.23438 2.274 3.94063C2.56775 3.64688 2.92087 3.5 3.33337 3.5H15.3334C15.7459 3.5 16.099 3.64688 16.3927 3.94063C16.6865 4.23438 16.8334 4.5875 16.8334 5V14C16.8334 14.4125 16.6865 14.7656 16.3927 15.0594C16.099 15.3531 15.7459 15.5 15.3334 15.5H3.33337ZM9.33337 10.25L3.33337 6.5V14H15.3334V6.5L9.33337 10.25ZM9.33337 8.75L15.3334 5H3.33337L9.33337 8.75ZM3.33337 6.5V5V14V6.5Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>
                      <b style={{ fontSize: "12px" }}>Mail Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>
                        {" "}
                        info@mountainmysteries.com
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
              {/* ppp */}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "0 10px" }}>
          <div className="flex_align_center justify_content_between HeaderMain-content">
            <div
              className="w-half Header-main flex align_items_center"
              style={{ gap: "20px" }}
            >
              <Link className="visit-website" to={"/"}>
                Visit Website
              </Link>
              <div style={{ width: "65px" }}>
                <NavLink
                  className="Header-icon"
                  to="/vendor/property/list"
                  exact
                >
                  <img src={logoimg} alt="logo-image" className="logo" />
                </NavLink>
              </div>

              <div className="support_btn cursor_pointer">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2485_129"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="17"
                  >
                    <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2485_129)">
                    <path
                      d="M8 15.8335V14.5002H12.6667V13.8335H10V8.50016H12.6667V7.8335C12.6667 6.54461 12.2111 5.44461 11.3 4.5335C10.3889 3.62239 9.28889 3.16683 8 3.16683C6.71111 3.16683 5.61111 3.62239 4.7 4.5335C3.78889 5.44461 3.33333 6.54461 3.33333 7.8335V8.50016H6V13.8335H3.33333C2.96667 13.8335 2.65278 13.7029 2.39167 13.4418C2.13056 13.1807 2 12.8668 2 12.5002V7.8335C2 7.01127 2.15833 6.23627 2.475 5.5085C2.79167 4.78072 3.22222 4.14461 3.76667 3.60016C4.31111 3.05572 4.94722 2.62516 5.675 2.3085C6.40278 1.99183 7.17778 1.8335 8 1.8335C8.82222 1.8335 9.59722 1.99183 10.325 2.3085C11.0528 2.62516 11.6889 3.05572 12.2333 3.60016C12.7778 4.14461 13.2083 4.78072 13.525 5.5085C13.8417 6.23627 14 7.01127 14 7.8335V14.5002C14 14.8668 13.8694 15.1807 13.6083 15.4418C13.3472 15.7029 13.0333 15.8335 12.6667 15.8335H8ZM3.33333 12.5002H4.66667V9.8335H3.33333V12.5002ZM11.3333 12.5002H12.6667V9.8335H11.3333V12.5002Z"
                      fill="#333333"
                    />
                  </g>
                </svg>
                Support
                <div className="supportDetails" id="support-detail">
                  <Link className="flex" to="tel:+919805021966">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_992"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2263_992)">
                          <path
                            d="M14.9625 16.25C13.4 16.25 11.8563 15.9094 10.3313 15.2281C8.80625 14.5469 7.41875 13.5813 6.16875 12.3313C4.91875 11.0813 3.95312 9.69375 3.27188 8.16875C2.59063 6.64375 2.25 5.1 2.25 3.5375C2.25 3.3125 2.325 3.125 2.475 2.975C2.625 2.825 2.8125 2.75 3.0375 2.75H6.075C6.25 2.75 6.40625 2.80937 6.54375 2.92812C6.68125 3.04688 6.7625 3.1875 6.7875 3.35L7.275 5.975C7.3 6.175 7.29375 6.34375 7.25625 6.48125C7.21875 6.61875 7.15 6.7375 7.05 6.8375L5.23125 8.675C5.48125 9.1375 5.77812 9.58437 6.12187 10.0156C6.46562 10.4469 6.84375 10.8625 7.25625 11.2625C7.64375 11.65 8.05 12.0094 8.475 12.3406C8.9 12.6719 9.35 12.975 9.825 13.25L11.5875 11.4875C11.7 11.375 11.8469 11.2906 12.0281 11.2344C12.2094 11.1781 12.3875 11.1625 12.5625 11.1875L15.15 11.7125C15.325 11.7625 15.4688 11.8531 15.5812 11.9844C15.6937 12.1156 15.75 12.2625 15.75 12.425V15.4625C15.75 15.6875 15.675 15.875 15.525 16.025C15.375 16.175 15.1875 16.25 14.9625 16.25ZM4.51875 7.25L5.75625 6.0125L5.4375 4.25H3.76875C3.83125 4.7625 3.91875 5.26875 4.03125 5.76875C4.14375 6.26875 4.30625 6.7625 4.51875 7.25ZM11.2313 13.9625C11.7188 14.175 12.2156 14.3438 12.7219 14.4688C13.2281 14.5938 13.7375 14.675 14.25 14.7125V13.0625L12.4875 12.7063L11.2313 13.9625Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>
                      <b style={{ fontSize: "12px" }}>Call Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>+91-98050-21966</span>
                    </span>
                  </Link>
                  <Link className="flex" to="mailto:info@mountainmysteries.com">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_999"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect
                            x="0.333374"
                            y="0.5"
                            width="18"
                            height="18"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_2263_999)">
                          <path
                            d="M3.33337 15.5C2.92087 15.5 2.56775 15.3531 2.274 15.0594C1.98025 14.7656 1.83337 14.4125 1.83337 14V5C1.83337 4.5875 1.98025 4.23438 2.274 3.94063C2.56775 3.64688 2.92087 3.5 3.33337 3.5H15.3334C15.7459 3.5 16.099 3.64688 16.3927 3.94063C16.6865 4.23438 16.8334 4.5875 16.8334 5V14C16.8334 14.4125 16.6865 14.7656 16.3927 15.0594C16.099 15.3531 15.7459 15.5 15.3334 15.5H3.33337ZM9.33337 10.25L3.33337 6.5V14H15.3334V6.5L9.33337 10.25ZM9.33337 8.75L15.3334 5H3.33337L9.33337 8.75ZM3.33337 6.5V5V14V6.5Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>
                      <b style={{ fontSize: "12px" }}>Mail Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>
                        {" "}
                        info@mountainmysteries.com
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default CustomerHeader;
