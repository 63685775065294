import React, { useEffect, useState } from "react";
import Header from "../../CustomComponents/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Loader from "../../CustomComponents/Loader/Loader";
import Footer from "../../CustomComponents/Footer/Footer";
import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import ListingBanner from "../../CustomComponents/ListingBanner/ListingBanner";
import FilterSection from "../../CustomComponents/FilterSection/FilterSection";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../CustomComponents/SkeletonLoader/SkeletonLoader";
import imgNotFound from "../../assets/images/single-image.png";
import { formatIndianCurrency, scrollToTop } from "../../utils/utils";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function ListingHotels() {
  const location = useLocation();
  const [bookingData, setBookingData] = useState({});
  const [hotelData, setHotelData] = useState([]);
  const navigate = useNavigate();
  // console.log(hotelData);

  const [sortedFilter, setSortedFilter] = useState({
    show: "Popular",
    value: "polpular",
  });

  const [loader, setLoader] = useState(true);
  const [filterLoader, setFilterLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    hotelData &&
    hotelData &&
    hotelData.length > 0 &&
    hotelData.slice(startIndex, endIndex);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const data = {
  //     hotelID: params.get("hotelID"),
  //     location: params.get("location"),
  //     room: params.get("room"),
  //     adult: params.get("adult"),
  //     children: params.get("children"),
  //     checkinDay: params.get("checkinDay"),
  //     checkinMonth: params.get("checkinMonth"),
  //     checkinYear: params.get("checkinYear"),
  //     checkoutDay: params.get("checkoutDay"),
  //     checkoutMonth: params.get("checkoutMonth"),
  //     checkoutYear: params.get("checkoutYear"),
  //     radio: params.get("radio"),
  //     childAge: params.get("childAge"),
  //   };
  //   setBookingData(data);
  //   postBookingData(data);
  // }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      // hotelID: params.get("hotelID"),
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      checkoutDay: params.get("checkoutDay"),
      checkoutMonth: params.get("checkoutMonth"),
      checkoutYear: params.get("checkoutYear"),
      radio: params.get("radio"),
      childAge: childAges, // Assign the array of child ages
    };
    // console.log(hotelData, "data hotel")
    setBookingData(data);
    postBookingData(data);
  }, [location.search]);
  // console.log(items, "  data hotel")

  const postBookingData = async (data, filters) => {
    setFilterLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("checkIn", checkInDate);
    formdata.append("checkOut", checkOutDate);
    formdata.append("adults", data.adult);
    formdata.append("child", data.children);
    formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    formdata.append("PropertyType", data?.radio ? data?.radio : "hotel");

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability?star_rating=${
          filters?.stars ? filters?.stars : ""
        }&price_range=${filters?.budget ? filters?.budget : ""}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
      // scrollToTop();
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // scrollToTop();
      // showErrorToast(error.message);
    } finally {
      scrollToTop();

      setFilterLoader(false);
      setLoader(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const handleViewRoomsClickHandler = async (item) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    try {
      // await navigate(
      //   `/single-hotel?hotelId=${item?.hotel_id}&location=${bookingData.location}&room=${bookingData.room}&adult=${bookingData.adult}&children=${bookingData.children}${childAgeParams}&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`,
      //   { state: { item: item } }
      // );
      const url = `/single-hotel/${item?.hotel_slug}?hotelId=${item?.hotel_id}&location=${bookingData.location}&room=${bookingData.room}&adult=${bookingData.adult}&children=${bookingData.children}${childAgeParams}&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`;

      // Open the new tab with the URL
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const sendDataToParent = (data) => {
    // console.log(data);
    postBookingData(bookingData, data);
  };

  useEffect(() => {
    // console.log(sortedFilter?.value);
    let dummyData = [...hotelData];
    if (sortedFilter?.value === "l2hByp") {
      dummyData.sort((a, b) => a.starting_price - b.starting_price);
      setHotelData(dummyData);
    }

    if (sortedFilter?.value === "h2lByp") {
      dummyData.sort((a, b) => b.starting_price - a.starting_price);
      setHotelData(dummyData);
    }
  }, [sortedFilter?.value]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div id="Listinghotels">
      <PageMetaData
        title={`Properties In ${bookingData.location} - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />

      <OgMetaTitles
        title={`Properties In ${bookingData.location} - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={
          "https://www.mountainmysteries.com/listing-hotels?hotelID=1&location=Shimla&room=1&adult=2&children=0&checkinDay=5&checkinMonth=10&checkinYear=2024&checkoutDay=6&checkoutMonth=10&checkoutYear=2024&room=1&adult=2&children=0&radio=s"
        }
        image={
          hotelData && hotelData[0] && hotelData[0].hotel_image.length > 0
            ? hotelData[0].hotel_image[0]
            : ""
        }
      />

      <Header />
      <SingleSearchForm searchType="hotel_listing" />
      <ListingBanner heading={`Properties In ${bookingData.location}`} />
      {/* <PageLinks links={["Home", `Properties In ${bookingData.location}`]} /> */}
      <section>
        <div className="container">
          <main id="slider-hotel-img">
            {loader ? (
              <>
                <div className="filters loading-filter-main">
                  <div className="agent border-bottom-one">
                    <div>
                      <Skeleton width={100} />
                    </div>
                  </div>
                  <div className="filter_checkbox border-bottom-one">
                    <Skeleton width={150} />
                    <Skeleton count={3} width={90} />
                  </div>
                  <div className="filter_checkbox border-bottom-one">
                    <Skeleton width={150} />
                    <Skeleton count={3} width={90} />
                  </div>
                  <div className="filter_checkbox border-bottom-one">
                    <Skeleton width={150} />
                    <Skeleton count={3} width={90} />
                  </div>
                  <div className="filter_checkbox ">
                    <Skeleton width={150} />
                    <Skeleton count={3} width={90} />
                  </div>
                </div>

                <div className="loading-filter-responsive">
                  <Skeleton width={500} height={50} />
                </div>
              </>
            ) : (
              <FilterSection sendDataToParent={sendDataToParent} />
            )}

            {loader || filterLoader ? (
              <>
                <div className="hotel_properties">
                  <h3
                    style={{ textTransform: "capitalize" }}
                    className="text-left"
                  >
                    <Skeleton width={600} />
                  </h3>
                  {Array.from({ length: 9 }).map((_, index) => (
                    <div className="hotel_card" key={index}>
                      <div className="hotel-content-flex hotel-main-card">
                        <div
                          className="hotel_view_img"
                          style={{ background: "#fff" }}
                        >
                          <Skeleton height={193} />
                        </div>

                        <div
                          className="hotel-content"
                          style={{ width: "100%", background: "#fff" }}
                        >
                          <div className="hotel_main">
                            <h4>
                              <b>
                                <Skeleton width={250} />
                              </b>
                            </h4>
                            <div>
                              <Skeleton width={50} />
                            </div>
                            <small>
                              <Skeleton width={150} />
                            </small>
                            <div className="agent">
                              <div>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                              </div>
                              <div>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                              </div>
                            </div>
                          </div>
                          <div className="room_rates">
                            <div className="room-flex">
                              <div>
                                <p>
                                  <Skeleton width={100} />
                                </p>
                                <h5>
                                  <Skeleton width={70} />
                                </h5>
                                <small>
                                  <Skeleton width={50} />
                                </small>
                              </div>
                              <div className="view_room_btn">
                                <Skeleton width={100} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="hotel_properties">
                <div className="flex align_items_center justify_content_between flex_wrap">
                  <h3 className="text-left">
                    Best Properties in{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {bookingData.location}
                    </span>
                    {/* Showing {hotelData?.length} Properties in{" "}
                  {bookingData.location} */}
                  </h3>
                  <div className="sorted_div">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_5040_6776"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="14"
                      >
                        <rect width="14" height="14" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_5040_6776)">
                        <path
                          d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    Sort by:{" "}
                    <span className="cursor_pointer">
                      <b>{sortedFilter?.show}</b>
                    </span>
                    <div className="sorted_selected">
                      <div
                        onClick={() => [
                          setSortedFilter({
                            value: "l2hByp",
                            show: "Price - Low to High",
                          }),
                          setCurrentPage(1),
                        ]}
                        className="cursor_pointer p-5"
                      >
                        {" "}
                        <span>Price - Low to High</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => [
                          setSortedFilter({
                            value: "h2lByp",
                            show: "Price - high to Low",
                          }),
                          setCurrentPage(1),
                        ]}
                      >
                        {" "}
                        <span>Price - High to Low</span>
                      </div>
                    </div>
                  </div>
                </div>
                {hotelData && displayedData?.length > 0 ? (
                  displayedData?.map((items) => {
                    // console.log(items);
                    return (
                      <div className="hotel_card">
                        <div className="hotel-content-flex hotel-main-card font_12">
                          <div className="hotel_view_img">
                            <img
                              src={
                                items.hotel_image[0]
                                  ? items.hotel_image[0]
                                  : imgNotFound
                              }
                              alt="hotel-img"
                            />

                            {items.hotel_image.length > 1 && (
                              <div>
                                <button
                                  className="view_images_btn"
                                  onClick={() => {
                                    Fancybox.show(
                                      items.hotel_image.map((src) => ({
                                        src,
                                        type: "image",
                                      }))
                                    );
                                  }}
                                >
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 16 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z"
                                      fill="white"
                                    />
                                  </svg>
                                  View all images
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="hotel-content">
                            <div className="hotel_main">
                              <h4>
                                <b>{items.hotel_name}</b>
                              </h4>
                              <div>
                                {" "}
                                {
                                  <StarRating
                                    value={
                                      items.hotel_rating
                                        ? items.hotel_rating
                                        : 0
                                    }
                                  />
                                }
                              </div>
                              <small>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                    fill="#888888"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                    fill="#888888"
                                  />
                                </svg>{" "}
                                {items.hotel_address}
                              </small>
                              <div className="flex justify_content_between flex_wrap">
                                {items?.hotel_amenities
                                  ?.slice(0, 6)
                                  ?.map((amenity, index) => (
                                    <li className="w-half">{amenity?.name}</li>
                                  ))}
                              </div>
                              <small>{items.distance}</small>
                            </div>
                            <div className="room_rates">
                              <div className="room-flex align_items_center">
                                <div>
                                  <p>Starting From</p>
                                  <h5>
                                    {formatIndianCurrency(items.starting_price)}
                                  </h5>
                                  <small> + GST</small>
                                </div>
                                <div className="view_room_btn">
                                  <button
                                    style={{ width: "90px" }}
                                    onClick={() =>
                                      handleViewRoomsClickHandler(items)
                                    }
                                    className="primary_btn"
                                    type="submit"
                                  >
                                    View Rooms
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoDataFound />
                )}

                <div className="communication_pagination">
                  <CustomPagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={hotelData.length} // Pass the total length of original data
                    onPageChange={handlePageChange}
                    clearSelection={clearSelection}
                  />
                </div>
              </div>
            )}
          </main>
        </div>
      </section>

      {/* <DestinationLinks /> */}
      <Footer />
    </div>
  );
}

export default ListingHotels;
