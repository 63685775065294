import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../SingleHotelCard/SingleHotelCard.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import imgNotFound from "../../assets/images/single-image.png";
import { formatIndianCurrency } from "../../utils/utils";

function SingleHotelCard({ rooms, bookingData }) {
  const location = useLocation();
  const {
    item: selectedPlan,
    hotelData,
    ratesData,
    room_id,
  } = location?.state || {};
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const bookingDiscount = 0; // replace with actual value
  const bookingDiscountType = "percentage"; // replace with actual value
  const agentDiscount = userDetails?.agent_category_discount || 0;
  const agentDiscountType = userDetails?.agent_discount_type || "percentage";

  // console.log("hotelData", rooms);

  const [pricePopUpId, setPricePopUpId] = useState(false);
  const [isPopupVisibleId, setIsPopupVisibleId] = useState(null);
  const openPopup = (id) => {
    setIsPopupVisibleId(id);
  };

  const [priceDetail, setPriceDetail] = useState({});
  const [noOfNights, setNoOfNights] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");

  useEffect(() => {
    if (!bookingData) {
      navigate("/");
    }

    const calculateNoOfNights = () => {
      if (!bookingData.checkinYear) navigate("/");
      const checkin = new Date(
        bookingData.checkinYear,
        bookingData.checkinMonth - 1,
        bookingData.checkinDay
      );
      const checkout = new Date(
        bookingData.checkoutYear,
        bookingData.checkoutMonth - 1,
        bookingData.checkoutDay
      );
      const diffTime = Math.abs(checkout - checkin);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setNoOfNights(diffDays);
    };

    const formatDate = (year, month, day) => {
      return `${year}-${month}-${day}`;
    };

    if (bookingData) {
      calculateNoOfNights();
      setCheckInDate(
        formatDate(
          bookingData.checkinYear,
          bookingData.checkinMonth,
          bookingData.checkinDay
        )
      );
      setCheckOutDate(
        formatDate(
          bookingData.checkoutYear,
          bookingData.checkoutMonth,
          bookingData.checkoutDay
        )
      );
    }
  }, [bookingData]);

  Fancybox.bind("[data-fancybox]", {
    // Fancybox options if any
  });
  const closePopup = (id) => {
    setIsPopupVisibleId(id);
  };

  const openPriceDetails = (id) => {
    setPricePopUpId(id);
  };
  const closePriceDetails = (id) => {
    setPricePopUpId(id);
  };

  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
  });

  const calculateBookingValues = (
    roomRate,
    bookingDiscount,
    bookingDiscountType,
    agentDiscount,
    agentDiscountType,
    childPricesArray,
    noOfNights,
    noOfRooms
  ) => {
    let roomRateParsed = parseFloat(roomRate) * +noOfNights * +noOfRooms;
    if (isNaN(roomRateParsed)) {
      roomRateParsed = 0;
    }

    let taxSlab = +roomRate > 7500 ? 18 : 12;

    let bookingDiscountAmount = 0;
    if (bookingDiscountType === "percentage") {
      bookingDiscountAmount = (roomRateParsed * bookingDiscount) / 100;
    } else {
      bookingDiscountAmount = parseFloat(bookingDiscount);
      if (isNaN(bookingDiscountAmount)) {
        bookingDiscountAmount = 0;
      }
    }

    let agentDiscountAmount = 0;
    if (agentDiscountType === "percentage") {
      agentDiscountAmount =
        ((roomRateParsed - bookingDiscountAmount) * agentDiscount) / 100;
    } else {
      agentDiscountAmount = parseFloat(agentDiscount);
      if (isNaN(agentDiscountAmount)) {
        agentDiscountAmount = 0;
      }
    }

    let totalDiscountAmount = bookingDiscountAmount + agentDiscountAmount;

    let childPrices = childPricesArray.reduce((accumulator, currentPrice) => {
      const parsedPrice = parseFloat(currentPrice);
      return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
    }, 0);

    const subtotal =
      roomRateParsed - totalDiscountAmount + +childPrices * +noOfNights;
    const taxAmount = (subtotal * taxSlab) / 100;
    const grandTotal = subtotal + taxAmount;

    return {
      taxSlab,
      subtotal,
      taxAmount,
      grandTotal,
      discountAmt: totalDiscountAmount,
      totalChildAdultCost: childPrices * +noOfNights,
    };
  };
  const navigate = useNavigate();

  const handleSelectRoomClickHandler = async (item, hotelData, room_id) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    // Calculate booking values before navigating

    try {
      // calculateBookingValues(Number(8000), 20);
      await navigate(`/checkout-hotel/#booking`, {
        state: {
          item: item,
          hotelData: rooms,
          ratesData: hotelData,
          bookingData: bookingData,
          bookingValues: bookingValues,
          room_id: room_id,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewDetailsClick = (items) => {
    const calculatedValues = calculateBookingValues(
      +items.rate_per_night,
      +bookingDiscount,
      bookingDiscountType,
      +agentDiscount,
      agentDiscountType,
      items.child_price,
      +noOfNights,
      +bookingData.room
    );
    setBookingValues(calculatedValues);
  };

  return (
    <section className="SingleRoomCard-Main">
      {rooms &&
        rooms.rooms &&
        rooms.rooms.length > 0 &&
        rooms?.rooms?.map((cards) => {
          return (
            <div className="single-room-card">
              <div className="card_head p_0">
                <div className="flex justify_content_between flex_wrap p_10">
                  <h4>
                    {cards.room_name} {"  "}
                    <small style={{ textTransform: "capitalize" }}>
                      {" "}
                      ({cards?.room_view?.replace("-", " ")})
                    </small>
                  </h4>
                  <div className="flex flex_gap_10 font_12">
                    <span className="room_plan">
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.666992 11.8333V6.83329C0.666992 6.45829 0.743381 6.11802 0.896159 5.81246C1.04894 5.5069 1.25033 5.23607 1.50033 4.99996V2.66663C1.50033 1.97218 1.74338 1.3819 2.22949 0.895793C2.7156 0.409682 3.30588 0.166626 4.00033 0.166626H7.33366C7.6531 0.166626 7.95171 0.225654 8.22949 0.343709C8.50727 0.461765 8.76421 0.624959 9.00033 0.833293C9.23644 0.624959 9.49338 0.461765 9.77116 0.343709C10.0489 0.225654 10.3475 0.166626 10.667 0.166626H14.0003C14.6948 0.166626 15.285 0.409682 15.7712 0.895793C16.2573 1.3819 16.5003 1.97218 16.5003 2.66663V4.99996C16.7503 5.23607 16.9517 5.5069 17.1045 5.81246C17.2573 6.11802 17.3337 6.45829 17.3337 6.83329V11.8333H15.667V10.1666H2.33366V11.8333H0.666992ZM9.83366 4.33329H14.8337V2.66663C14.8337 2.43052 14.7538 2.2326 14.5941 2.07288C14.4344 1.91315 14.2364 1.83329 14.0003 1.83329H10.667C10.4309 1.83329 10.233 1.91315 10.0732 2.07288C9.91352 2.2326 9.83366 2.43052 9.83366 2.66663V4.33329ZM3.16699 4.33329H8.16699V2.66663C8.16699 2.43052 8.08713 2.2326 7.92741 2.07288C7.76769 1.91315 7.56977 1.83329 7.33366 1.83329H4.00033C3.76421 1.83329 3.5663 1.91315 3.40658 2.07288C3.24685 2.2326 3.16699 2.43052 3.16699 2.66663V4.33329ZM2.33366 8.49996H15.667V6.83329C15.667 6.59718 15.5871 6.39927 15.4274 6.23954C15.2677 6.07982 15.0698 5.99996 14.8337 5.99996H3.16699C2.93088 5.99996 2.73296 6.07982 2.57324 6.23954C2.41352 6.39927 2.33366 6.59718 2.33366 6.83329V8.49996Z"
                          fill="#333333"
                        />
                      </svg>
                      <span style={{ textTransform: "capitalize" }}>
                        {cards?.room_bed?.replace("-", " ")}{" "}
                      </span>
                    </span>
                    <span className="room_plan">
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.667 11.1667H14.8337V7.00004H13.167V9.50004H10.667V11.1667ZM3.16699 7.00004H4.83366V4.50004H7.33366V2.83337H3.16699V7.00004ZM2.33366 13.6667C1.87533 13.6667 1.48296 13.5035 1.15658 13.1771C0.830187 12.8507 0.666992 12.4584 0.666992 12V2.00004C0.666992 1.54171 0.830187 1.14935 1.15658 0.822957C1.48296 0.496568 1.87533 0.333374 2.33366 0.333374H15.667C16.1253 0.333374 16.5177 0.496568 16.8441 0.822957C17.1705 1.14935 17.3337 1.54171 17.3337 2.00004V12C17.3337 12.4584 17.1705 12.8507 16.8441 13.1771C16.5177 13.5035 16.1253 13.6667 15.667 13.6667H2.33366ZM2.33366 12H15.667V2.00004H2.33366V12Z"
                          fill="#333333"
                        />
                      </svg>{" "}
                      {cards?.room_area} {cards?.room_area_unit}{" "}
                    </span>
                    <span className="room_plan">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3149_3904"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                        >
                          <rect width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3149_3904)">
                          <path
                            d="M0.958984 16.6667L3.83398 10.9167C3.97287 10.6389 4.17079 10.4167 4.42773 10.25C4.68468 10.0834 4.97287 10 5.29232 10C5.62565 10 5.93121 10.0868 6.20898 10.2605C6.48676 10.4341 6.68815 10.6806 6.81315 11L7.37565 12.375C7.40343 12.4584 7.46593 12.4966 7.56315 12.4896C7.66037 12.4827 7.72287 12.4375 7.75065 12.3542L9.54232 6.37504C9.73676 5.70837 10.1083 5.17365 10.6569 4.77087C11.2055 4.3681 11.827 4.16671 12.5215 4.16671C13.202 4.16671 13.8097 4.36462 14.3444 4.76046C14.8791 5.15629 15.2507 5.67365 15.459 6.31254L19.0632 16.6667H17.2923L13.8757 6.83337C13.7645 6.51393 13.5909 6.2674 13.3548 6.09379C13.1187 5.92018 12.8409 5.83337 12.5215 5.83337C12.202 5.83337 11.9208 5.92365 11.6777 6.10421C11.4347 6.28476 11.2576 6.53476 11.1465 6.85421L9.35482 12.8334C9.22982 13.2223 9.00412 13.5417 8.67773 13.7917C8.35135 14.0417 7.97982 14.1667 7.56315 14.1667C7.18815 14.1667 6.84093 14.066 6.52148 13.8646C6.20204 13.6632 5.97287 13.382 5.83398 13.0209L5.27148 11.6459L2.81315 16.6667H0.958984ZM5.00065 8.33337C4.30621 8.33337 3.71593 8.08685 3.22982 7.59379C2.74371 7.10074 2.50065 6.51393 2.50065 5.83337C2.50065 5.13893 2.74371 4.54865 3.22982 4.06254C3.71593 3.57643 4.30621 3.33337 5.00065 3.33337C5.6951 3.33337 6.28537 3.57643 6.77148 4.06254C7.2576 4.54865 7.50065 5.13893 7.50065 5.83337C7.50065 6.51393 7.2576 7.10074 6.77148 7.59379C6.28537 8.08685 5.6951 8.33337 5.00065 8.33337ZM5.00065 6.66671C5.23676 6.66671 5.43468 6.58685 5.5944 6.42712C5.75412 6.2674 5.83398 6.06948 5.83398 5.83337C5.83398 5.59726 5.75412 5.39935 5.5944 5.23962C5.43468 5.0799 5.23676 5.00004 5.00065 5.00004C4.76454 5.00004 4.56662 5.0799 4.4069 5.23962C4.24718 5.39935 4.16732 5.59726 4.16732 5.83337C4.16732 6.06948 4.24718 6.2674 4.4069 6.42712C4.56662 6.58685 4.76454 6.66671 5.00065 6.66671Z"
                            fill="#333333"
                          />
                        </g>
                      </svg>
                      <span style={{ textTransform: "capitalize" }}>
                        {cards?.room_view?.replace("-", " ")}
                      </span>
                    </span>
                    {rooms?.hotelAmenities.length > 0 && (
                      <button
                        onClick={() => openPopup(cards?.room_id)}
                        className="details_btn"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.33301 10.3334H7.66634V6.33337H6.33301V10.3334ZM6.99967 5.00004C7.18856 5.00004 7.34701 4.93604 7.47501 4.80804C7.60301 4.68004 7.66679 4.52182 7.66634 4.33337C7.66634 4.14448 7.60234 3.98604 7.47434 3.85804C7.34634 3.73004 7.18812 3.66626 6.99967 3.66671C6.81079 3.66671 6.65234 3.73071 6.52434 3.85871C6.39634 3.98671 6.33256 4.14493 6.33301 4.33337C6.33301 4.52226 6.39701 4.68071 6.52501 4.80871C6.65301 4.93671 6.81123 5.00048 6.99967 5.00004ZM6.99967 13.6667C6.07745 13.6667 5.21079 13.4916 4.39967 13.1414C3.58856 12.7912 2.88301 12.3163 2.28301 11.7167C1.68301 11.1167 1.20812 10.4112 0.858341 9.60004C0.508563 8.78893 0.333452 7.92226 0.333008 7.00004C0.333008 6.07782 0.508119 5.21115 0.858341 4.40004C1.20856 3.58893 1.68345 2.88337 2.28301 2.28337C2.88301 1.68337 3.58856 1.20849 4.39967 0.858707C5.21079 0.50893 6.07745 0.333818 6.99967 0.333374C7.9219 0.333374 8.78856 0.508485 9.59967 0.858707C10.4108 1.20893 11.1163 1.68382 11.7163 2.28337C12.3163 2.88337 12.7915 3.58893 13.1417 4.40004C13.4919 5.21115 13.6668 6.07782 13.6663 7.00004C13.6663 7.92226 13.4912 8.78893 13.141 9.60004C12.7908 10.4112 12.3159 11.1167 11.7163 11.7167C11.1163 12.3167 10.4108 12.7918 9.59967 13.142C8.78856 13.4923 7.9219 13.6672 6.99967 13.6667ZM6.99967 12.3334C8.48856 12.3334 9.74967 11.8167 10.783 10.7834C11.8163 9.75004 12.333 8.48893 12.333 7.00004C12.333 5.51115 11.8163 4.25004 10.783 3.21671C9.74967 2.18337 8.48856 1.66671 6.99967 1.66671C5.51079 1.66671 4.24967 2.18337 3.21634 3.21671C2.18301 4.25004 1.66634 5.51115 1.66634 7.00004C1.66634 8.48893 2.18301 9.75004 3.21634 10.7834C4.24967 11.8167 5.51079 12.3334 6.99967 12.3334Z"
                            fill="#016937"
                            onClick={() => closePopup(null)}
                          />
                        </svg>
                        More Details
                      </button>
                    )}
                    {isPopupVisibleId === cards?.room_id && (
                      <div className="popup_backdrop">
                        <div className="popup_section">
                          <div className="agent">
                            <div>
                              <h6>
                                <b>{cards?.room_name}</b>
                              </h6>
                            </div>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              onClick={closePopup}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 13L13 1"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                              <path
                                d="M13 13L0.999999 1"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                              />
                            </svg>
                          </div>
                          <hr />
                          <div>
                            <h6 className="mt-10">
                              <b>Room Amenities</b>
                            </h6>
                            <div className="flex justify_content_between flex_wrap">
                              {rooms?.hotelAmenities?.map((amenity, index) => (
                                <>
                                  <li key={index} className="w-half">
                                    {amenity?.name}
                                  </li>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="SingleHotel-Details single_room_data_">
                <div className="hotel_view_img">
                  {agentDiscount ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        background: "#016937",
                        color: "#fff",
                        padding: "2px 5px",
                        borderRadius: "5px",
                      }}
                    >
                      {+agentDiscount}% OFF
                    </div>
                  ) : null}
                  <img
                    src={cards.photos[0] ? cards.photos[0] : imgNotFound}
                    alt="hotel-img"
                  />
                  {cards?.photos?.length > 1 && (
                    <div>
                      <button
                        className="view_images_btn"
                        onClick={() => {
                          Fancybox.show(
                            cards?.photos?.map((src) => ({
                              src,
                              type: "image",
                            }))
                          );
                        }}
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 16 15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z"
                            fill="white"
                          />
                        </svg>
                        view all images
                      </button>
                    </div>
                  )}
                </div>

                <div className="rooms_content">
                  {cards?.rates?.map((items) => {
                    return (
                      <div className="single_room_details">
                        <div>
                          <p>
                            {items.meal_plan_name}{" "}
                            <small>({items.meal_plan})</small>
                          </p>
                          <button
                            type="button"
                            onClick={() => {
                              handleViewDetailsClick(items);

                              openPriceDetails(items.rate_plan_id);
                            }}
                            className="view_btn"
                          >
                            View Price Details
                          </button>

                          {pricePopUpId === items.rate_plan_id && (
                            <div className="popup_backdrop">
                              <div className="popup_section">
                                <div className="agent">
                                  <div>
                                    <h6>
                                      <b>{cards.room_name}</b>
                                    </h6>
                                    <p style={{ textAlign: "left" }}>
                                      {items.meal_plan_name || "N/A"}
                                    </p>
                                  </div>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    onClick={closePriceDetails}
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 13L13 1"
                                      stroke="#AAAAAA"
                                      strokeWidth="1.2"
                                    />
                                    <path
                                      d="M13 13L1 1"
                                      stroke="#AAAAAA"
                                      strokeWidth="1.2"
                                    />
                                  </svg>
                                </div>
                                <hr />
                                <div>
                                  <div className="agent">
                                    <p>
                                      Room Rate <small>(Per Night)</small>
                                    </p>
                                    <h6>₹ {items.rate_per_night}</h6>
                                  </div>

                                  <div className="agent">
                                    <p>Discount Amt.</p>
                                    <h6>
                                      {formatIndianCurrency(
                                        selectedPlan?.rate_per_night
                                      )}
                                    </h6>
                                  </div>
                                  <div className="agent">
                                    <p>Subtotal</p>
                                    <h6>
                                      {formatIndianCurrency(
                                        bookingValues.subtotal
                                      )}
                                    </h6>
                                  </div>

                                  <div className="agent">
                                    <p>Tax Slab</p>
                                    <h6>{bookingValues.taxSlab}%</h6>
                                  </div>
                                  <div className="agent">
                                    <p>Tax Amount</p>
                                    <h6>
                                      {formatIndianCurrency(
                                        bookingValues.taxAmount
                                      )}
                                    </h6>
                                  </div>
                                  <div className="agent">
                                    <p>
                                      Total Amount<small> (Taxes Extra)</small>
                                    </p>
                                    <h6>
                                      {formatIndianCurrency(
                                        bookingValues.grandTotal
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            //   <div className="flex justify_content_between mt-10">
                            //     <p>
                            //       Room Type ({bookingData?.room} R | {noOfNights} N )
                            //     </p>
                            //     <span className="room_text-right">
                            //       {ratesData?.room_name}
                            //     </span>
                            //   </div>

                            //   <div className="flex justify_content_between mt-10">
                            //     <p>
                            //       Room Rate <small>(Per Night)</small>
                            //     </p>
                            //     <span className="room_text-right">
                            //       {formatIndianCurrency(selectedPlan?.rate_per_night)}
                            //     </span>
                            //   </div>
                            //   {userDetails?.agent_category_discount && (
                            //     <div className="flex justify_content_between mt-10 green">
                            //       <p>
                            //         Discount % <small>(TAC)</small>
                            //       </p>
                            //       <span className="room_text-right green">
                            //         {Number(userDetails?.agent_category_discount)}%
                            //       </span>
                            //     </div>
                            //   )}

                            //   <div className="flex justify_content_between mt-10">
                            //     <p>Discount Amt.</p>
                            //     <span className="room_text-right">
                            //       {formatIndianCurrency(bookingValues?.discountAmt)}
                            //     </span>
                            //   </div>
                            //   <div className="flex justify_content_between mt-10">
                            //     <p>
                            //       {bookingData.children} Extra Child{" "}
                            //       <small>
                            //         {" "}
                            //         (
                            //         {formatIndianCurrency(
                            //           bookingValues?.childPrices
                            //         )}{" "}
                            //         * {noOfNights} Night){" "}
                            //       </small>{" "}
                            //     </p>
                            //     <span className="room_text-right">
                            //       {formatIndianCurrency(
                            //         bookingValues?.totalChildAdultCost
                            //       )}
                            //     </span>
                            //   </div>

                            //   <div className="flex justify_content_between mt-10">
                            //     <p>Subtotal</p>
                            //     <span className="room_text-right">
                            //       {formatIndianCurrency(bookingValues.subtotal)}
                            //     </span>
                            //   </div>

                            //   <div className="flex justify_content_between mt-10">
                            //     <p>Tax Amount ({bookingValues?.taxSlab}%)</p>
                            //     <span className="room_text-right">
                            //       {formatIndianCurrency(bookingValues.taxAmount)}
                            //     </span>
                            //   </div>

                            //   <div className="flex justify_content_between mt-10">
                            //     <h6>
                            //       <b>Grand Total</b>
                            //     </h6>
                            //     <h6>
                            //       <b>{formatIndianCurrency(bookingValues.grandTotal)}</b>
                            //     </h6>
                            //   </div>
                            // </div>
                          )}
                        </div>
                        <div className="single_room_price">
                          <div className="flex flex_gap_5 flex_wrap align_items_center">
                            {agentDiscount ? (
                              <>
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {formatIndianCurrency(items?.rate_per_night)}{" "}
                                </span>
                                <h4 className="Singlehotel_price">
                                  {formatIndianCurrency(
                                    items.rate_per_night -
                                      (
                                        (agentDiscount / 100) *
                                        items.rate_per_night
                                      ).toFixed(2)
                                  )}
                                </h4>
                              </>
                            ) : (
                              <h5>
                                {formatIndianCurrency(items.rate_per_night)}
                              </h5>
                            )}
                            <span>+ GST</span>
                          </div>
                          <div>
                            {items.status === "AVAILABLE" ? (
                              <>
                                {items.add_rooms &&
                                Number(items.required_rooms) >=
                                  Number(bookingData.room) ? (
                                  // <CustomTooltip
                                  //   preferredPosition="below"
                                  //   content={<div>{"Please Increase Rooms"}</div>}
                                  // >
                                  <button
                                    className="primary_btn"
                                    style={{
                                      cursor: "not-allowed",
                                      background: "grey",
                                      width: "120px",
                                    }}
                                    disabled
                                  >
                                    PLEASE ADD ROOM
                                  </button>
                                ) : (
                                  // </CustomTooltip>
                                  <button
                                    style={
                                      {
                                        // width: "90px",
                                      }
                                    }
                                    onClick={() =>
                                      handleSelectRoomClickHandler(
                                        items,
                                        cards,
                                        items.room_id
                                      )
                                    }
                                    className="primary_btn"
                                  >
                                    Select Rooms
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                {items.add_rooms &&
                                Number(items.required_rooms) <=
                                  Number(rooms) ? (
                                  // <CustomTooltip
                                  //   preferredPosition="below"
                                  //   content={<div>{"Please Increase Rooms"}</div>}
                                  // >
                                  <button
                                    className="primary_btn"
                                    style={{
                                      cursor: "not-allowed",
                                      background: "grey",
                                      width: "120px",
                                    }}
                                    disabled
                                  >
                                    PLEASE ADD ROOM
                                  </button>
                                ) : (
                                  // </CustomTooltip>
                                  <button
                                    className="primary_btn"
                                    style={{
                                      cursor: "not-allowed",
                                      background: "grey",
                                      width: "74px",
                                    }}
                                    disabled
                                  >
                                    Sold Out
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
}

export default SingleHotelCard;
