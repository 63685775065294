import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatIndianCurrency } from "../../utils/utils";

const ThankYouPagePackage = () => {
  const [orderDetails, setOrderDetails] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    async function fetchOrderId() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/new/check/payment/${id}/package`
        );
        const data = await response.json();
        // console.log(data);

        setOrderDetails(data);
      } catch (error) {
        console.error("Error fetching order ID:", error);
      }
    }
    fetchOrderId();
  }, []);

  //   const purchaseamount = parseFloat(orderDetails.amount);
  const getPaymentStatus = () => {
    const balance = parseFloat(orderDetails?.packageBooking?.balance_amount);
    const total = parseFloat(orderDetails?.packageBooking?.grand_total);

    if (balance === 0) {
      return "Paid";
    } else if (balance > 0 && balance < total) {
      return "Partly Paid";
    } else {
      return "Unpaid";
    }
  };

  const payment_status = getPaymentStatus();

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {orderDetails && (
              <div className="thankyou_main">
                <div className="text-center">
                  {/* <Image src="/icons/econew.svg" width={160} height={60} alt="Eco Logo"></Image> */}
                  <h1 className="mt-3">Booking Confirmation!</h1>
                </div>
                {/* <h3>Dear {orderDetails.booking.booked_from},</h3> */}
                <p>
                  We are thrilled to inform you that your booking has been
                  successfully confirmed. On behalf of the entire team at we
                  would like to express our sincere gratitude for choosing our
                  services.
                </p>
                <p>
                  We understand that your trust and confidence in us are
                  paramount, and we assure you that we will go above and beyond
                  to ensure your stay is nothing short of extraordinary.
                </p>
                <p>Here are the payment details for your reference:</p>
                <div className="payment-details">
                  <p className="text-capitalize">
                    <strong>Payment :</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails.status ? orderDetails.status : ""}
                  </p>
                  <p>
                    <strong>Ref Booking ID:</strong>&nbsp; &nbsp; &nbsp; #
                    {orderDetails?.unique_packageBooking_id}
                  </p>
                  <p>
                    <strong>Total Amount:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {formatIndianCurrency(
                      orderDetails?.packageBooking?.grand_total
                    )}
                  </p>
                  <p>
                    <strong>Payment Method:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails?.gatewayMethod}
                  </p>
                  <p>
                    <strong>Transaction ID:</strong>&nbsp; &nbsp; &nbsp;{" "}
                    {orderDetails?.gatewayPaymentId}
                  </p>
                </div>
                <div className="text-center">
                  <Link to={"/"} className="animate_btn btn-6">
                    <span>Back to Home</span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYouPagePackage;