import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import "../CustomSearchFormHotels/CustomSearchFormHotels.css";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import Flatpickr from "react-flatpickr";
import GooglePlaceApi from "../../GoogleApi/GooglePlaceApi/GooglePlaceApi";
import NewHeaderBg from "../../NewHeaderBg/NewHeaderBg";
import SearchStateInput from "../../SearchSateInput/SearchStateInput";
import Header from "../../Header/Header";
import NewSearchSateInput from "../../NewSearchSateInput/NewSearchSateInput";

function NewHolidaysSearchForm({ topRadios }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataFromChild, setDataFromChild] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  // const [stateResultArray, setStateResultArray] = useState("");
  // console.log(stateResultArray);

  const getDataFromChild = (data, resultArray, selectedType) => {

    
    setDataFromChild(data);
    setSelectedType(selectedType);
    // setStateResultArray(resultArray)

  };


  useEffect(() => {
    // Check if token is present in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // console.log("isLoggedIn", isLoggedIn);

  const [guestDetails, setIsguestDetails] = useState(false);
  const [children, setChildren] = useState(0);
  const [childAge, setChildAge] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };
  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = children - 1;
    setChildren(updatedChildren);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = children + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const [destinationToggle, setdestinationToggle] = useState(false);
  const [selectDates, setselectDates] = useState(false);

  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };
  const location = useLocation();
  const navigate = useNavigate();

  // STATES
  const [date, setDate] = useState(new Date());
  const [selectedRadio, setSelectedRadio] = useState("");

  const [data, setData] = useState({
    fromCity: "",
    toCity: "",
  });
  //
  const [guest, setGuest] = useState(2);

  // Date alterations
  const [loader, setLoader] = useState(false);

  // functions for increase decrease

  const handleSubmitButtonClickHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    setLoader(true);

    // console.log(children, "children");

    try {
      await navigate(
        `/listing-holidays?location=${dataFromChild}&guest=${guest}&children=${children}&${childAgeParams}&radio=${selectedRadio}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const handleSubmitButtonClickHandlerPackage = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    const packagehandle = (inputString) => {
      return inputString
        .toLowerCase()                    // Convert to lowercase
        .replace(/[^a-z0-9\s]/g, "")      // Remove any non-alphanumeric characters except spaces
        .trim()                           // Trim leading and trailing spaces
        .replace(/\s+/g, "-");            // Replace spaces with hyphens
    };

    setLoader(true);

    // console.log(children, "children");
    // console.log("selectedType", selectedType);
    

    try {
      await navigate(
        `/package/${packagehandle(selectedType?.name)}?location=${data.fromCity}&guest=2&children=0&packagehandle=${selectedType?.packagehandle}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  // const handleSelectRoomClickHandler = async (item, hotelData, room_id) => {
  //   let hasChildAgeError = false;
  //   const childAge = bookingData?.childAge;
  //   const sanitizedChildAges = childAge
  //     .map((age) => (age !== "" ? age : null))
  //     .filter((age) => age !== null);

  //   const childAgeParams = sanitizedChildAges
  //     .map((age, index) => {
  //       if (age > 12) {
  //         alert("Child age must be between 0 and 12");
  //         hasChildAgeError = true;
  //         return;
  //       }
  //       return `&childAge[${index + 1}]=${age}`;
  //     })
  //     .join("");

  //   if (hasChildAgeError) {
  //     alert("Child age must be between 0 and 12");
  //     return;
  //   }

  //   // console.log(children, 'jjj');

  //   // Calculate booking values before navigating
  //   try {
  //     // http://localhost:3000/package/complete-himachal?location=shimla&guest=2&packagehandle=complete-himachal
  //     await navigate(
  //       `/package/${packagehandle}?location=${data.fromCity}&guest=${guest}&children=${children}&${childAgeParams}&packagehandle=${packagehandle}`,
  //       undefined,
  //       { shallow: false }
  //     );
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/customers/logout`,
        requestOptions
      );
      const result = await response.text();
      // console.log(result);
      showSuccessToast("Logged Out Successfull");
      localStorage.clear();
      setIsLoggedIn(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (destinationToggle) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [destinationToggle]);

  const getFunctionRunHandler = (type) => {
    switch (type) {
      case "destination":
        return handleSubmitButtonClickHandler();
      case "package":
        return handleSubmitButtonClickHandlerPackage();
      default:
        // console.log("Invalid type");
        break;
    }
  };

  return (
    <>
      {/* <NewHeaderBg /> */}
      <Header />
      <section className="NewSearch-BG" id="home">
        <div className="container">
          <div className="top-banner-img">
            {/* <img src={bgimg} alt="banner-img" /> */}

            <div
              className="NewSearch-header"
              id=""
              style={
                guestDetails || destinationToggle
                  ? { position: "absolute", zIndex: "9999" }
                  : { position: "" }
              }
            >
              <h1 className="text-center">Himachal Holiday Packages</h1>
              <p className="common_text">Book Your Himachal Escape Now</p>

              <div
                className="NewSearch-Main search_form"
                style={
                  guestDetails || destinationToggle
                    ? { zIndex: "999" }
                    : { position: "" }
                }
              >
                <form
                  className="search_form_main"
                  onSubmit={
                    selectedType?.type === "package"
                      ? handleSubmitButtonClickHandlerPackage
                      : handleSubmitButtonClickHandler
                  }
                >
                  <button className="hide_radio_btn">
                    <span>All</span>
                    <svg
                      fill="#BB735D"
                      height="10px"
                      width="10px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 330 330"
                      xmlSpace="preserve"
                    >
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  </button>
                  <div className="search_data_home">
                    <div className="search_form_div NewSearch-tags relative">
                      <label htmlFor="">From City/Destination/Package</label>
                      {/* <SearchStateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        dropdownGuestDetails={dropdownGuestDetails}
                        setIsguestDetails={setIsguestDetails}
                      /> */}
                      <NewSearchSateInput
                        onClick={() => setdestinationToggle(true)}
                        onDataChange={getDataFromChild}
                        dropdownGuestDetails={dropdownGuestDetails}
                        setIsguestDetails={setIsguestDetails}
                        type="package"
                      />
                    </div>
                    <div className="search_form_div NewSearch-tags icon_button_only">
                      <label
                        onClick={dropdownGuestDetails}
                        className="search_label"
                      >
                        Guests{" "}
                        <svg
                          width="9"
                          height="5"
                          viewBox="0 0 9 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 0.75L4.75 4.25L1.25 0.75"
                            stroke="#BB735D"
                          />
                        </svg>
                      </label>
                      <p
                        style={{ position: "relative" }}
                        className="dropdown-toggle search_form_input"
                      >
                        <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                          <div className="optionCounter"></div>
                          <span id="adult_count" className="font_16">
                            {" "}
                            <b>{guest}</b>{" "}
                          </span>
                          <span className="font_12"> Guests, </span>
                          <span id="child_count" className="font_16">
                            <b>{children}</b>
                          </span>
                          <span className="font_12"> Children</span>
                        </div>
                        {guestDetails && (
                          <div
                            className="guest_details_dropdown"
                            id="guestDetailsPopup"
                          >
                            <div className="agent">
                              <span>Guests</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={guest <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => {
                                    if (guest <= 2) return;
                                    setGuest(Number(guest) - 1);
                                  }}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={guest}
                                  min={1}
                                  type="number"
                                  onChange={(e) => setGuest(e.target.value)}
                                />

                                <button
                                  type="button"
                                  disabled={guest >= 11}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => {
                                    if (guest >= 11) return;
                                    setGuest(Number(guest) + 1);
                                  }}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="agent">
                              <span>Children</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={children <= 0}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildDecrease}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={children}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={children <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildIncrease}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>

                            {childAge.map((age, index) => (
                              <div className="navbar agent">
                                <div>
                                  <span>Child Age</span>
                                </div>
                                <div>
                                  <input
                                    className="childAge_input"
                                    type="number"
                                    name="childAge[]"
                                    min="1"
                                    value={age}
                                    onChange={(event) =>
                                      handleChildrenAgeChange(index, event)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </p>
                    </div>

                    <div className="">
                      <button type="submit" className="search-btn m_0">
                        SEARCH
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {(guestDetails || destinationToggle || selectDates) && (
        <div
          onClick={() => [
            setIsguestDetails(false),
            setdestinationToggle(false),
            setselectDates(false),
          ]}
          className="ScreenDimmer"
        ></div>
      )}
    </>
  );
}

export default NewHolidaysSearchForm;
