import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SkeletonTheme } from "react-loading-skeleton";
import { hydrate, render } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <React.StrictMode>
 <SkeletonTheme>
<App />
</SkeletonTheme>
  // </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <SkeletonTheme>
//       <App />
//     </SkeletonTheme>,
//     rootElement
//   );
// } else {
//   render(
//     <SkeletonTheme>
//       <App />
//     </SkeletonTheme>,
//     rootElement
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
