import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import AllRooms from "../../CustomComponents/Property/AllRooms/AllRooms";
import { useRef } from "react";
import SeeBookingSlider from "../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import { useParams } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter } from "../../utils/utils";
import Loader from "../../CustomComponents/Loader/Loader";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import VendorPackages from "../VendorPackages/VendorPackages";

function Bookings({ allPropertiesPage }) {
  const [editPopup, setEditPopup] = useState(null);
  const [singleSliderBookingData, setSliderSingleBookingData] = useState(null);

  const [singleBookingData, setSingleBookingData] = useState([null]);

  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const { id: propertyId } = useParams();

  const handleOnClick = (index) => {
    if (editPopup === index) {
      setEditPopup(null); // Close if the same index is clicked again
    } else {
      setEditPopup(index); // Open for the clicked index
    }
  };

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };
  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setEditPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const fetchSidebarDetails = (bookingId) => {
    // console.log(bookingId);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-booking/${bookingId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // console.log(result);

          setSliderSingleBookingData(result?.data);
          setIsSliderVisible(true);
          // setEditPopup(null)
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchBookingData = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-bookings/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setSingleBookingData(result?.bookings);
          setLoader(false);
        } else {
          setLoader(false);
          setSingleBookingData([]);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (propertyId) {
      fetchBookingData(propertyId);
    }
  }, [propertyId]);

  // console.log(singleBookingData, "singleBookingData");
  const [selectedButton, setSelectedButton] = useState("bookings");

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />
        <section className="w-full table-data">
          {selectedButton === "bookings" ? (
            <>
              {" "}
              <div className="flex justify_content_between align_items_center flex_wrap">
                {" "}
                <h4 className="hide_heading">Bookings</h4>
                {/* <div className="flex flex_gap_15 mb-10">
                  <div
                    onClick={() => setSelectedButton("bookings")}
                    style={{
                      cursor: "pointer",
                      color:
                        selectedButton === "bookings" ? "lightgray" : "#016932",
                      backgroundColor:
                        selectedButton === "bookings" ? "#016932" : "lightgray",
                      padding: "10px 20px",
                      borderRadius: "6px",
                    }}
                  >
                    Hotels Bookings
                  </div>
                  <div
                    onClick={() => setSelectedButton("tours")}
                    style={{
                      cursor: "pointer",
                      color:
                        selectedButton === "tours" ? "lightgray" : "#016932",
                      backgroundColor:
                        selectedButton === "tours" ? "#016932" : "lightgray",
                      padding: "10px 20px",
                      borderRadius: "6px",
                    }}
                  >
                    Tour Bookings
                  </div>
                </div> */}
                <div className="agent_dashboard_bookings">
                  <div
                    onClick={() => setSelectedButton("bookings")}
                    className={`agent_button ${
                      selectedButton === "bookings" ? "button-selected" : ""
                    }`}
                  >
                    Hotel Bookings
                  </div>
                  <div
                    onClick={() => setSelectedButton("tours")}
                    className={`agent_button ${
                      selectedButton === "tours" ? "button-selected" : ""
                    }`}
                  >
                    Tour Bookings
                  </div>
                </div>
              </div>
              {loader ? (
                <Loader size="4em" color="#016937" />
              ) : (
                <>
                  <div className="overflow_x_scroll_y_hidden">
                    {singleBookingData && singleBookingData.length > 0 ? (
                      <table className="rates_table">
                        <tr>
                          <th>Booking Details</th>
                          <th>Stay Duration</th>
                          <th>Rooms & Meal Plan</th>
                          <th>Phone</th>
                          <th>Net Amt.</th>
                          <th>Paid Amt.</th>
                          <th>Due Amt.</th>
                          <th>Booking Status</th>
                          <th></th>
                        </tr>
                        {singleBookingData &&
                          singleBookingData?.map((item, index) => (
                            <>
                              <tr>
                                <td>
                                  <div
                                    className="link_btn pt_10"
                                    onClick={() =>
                                      fetchSidebarDetails(
                                        item?.unique_booking_id
                                      )
                                    }
                                  >
                                    {" "}
                                    #{item?.unique_booking_id}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="border-bottom">
                                  {" "}
                                  {item?.guest?.guest_name}
                                </td>
                                <td className="border-bottom ">
                                  {textDateFormatter(item?.tour_start)} to{" "}
                                  {textDateFormatter(item?.tour_end)}
                                </td>
                                <td className="border-bottom ">
                                  {item?.items &&
                                    item?.items?.length > 0 &&
                                    item?.items.map((data) => (
                                      <p>{`${data?.no_of_rooms} X ${data?.room_plan}`}</p>
                                    ))}
                                </td>
                                <td className="border-bottom ">
                                  {item?.guest?.guest_phone}
                                </td>
                                <td className="border-bottom ">
                                  {formatIndianCurrency(item?.grand_total)}
                                </td>
                                <td
                                  style={{ color: "#009A50" }}
                                  className="border-bottom "
                                >
                                  {formatIndianCurrency(item?.paid_amount)}
                                </td>
                                <td
                                  style={{ color: "#BD941B" }}
                                  className="border-bottom "
                                >
                                  ₹ 0.00
                                </td>
                                <td className="border-bottom ">
                                  <button className="confirmed_btn">
                                    {item?.booking_status}
                                  </button>
                                </td>
                                <td
                                  style={{ position: "relative" }}
                                  className="border-bottom "
                                >
                                  <svg
                                    onClick={() => handleOnClick(index)}
                                    width="4"
                                    height="12"
                                    viewBox="0 0 4 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.00156 3.20002C1.22946 3.20002 0.601562 2.57212 0.601562 1.80002C0.601562 1.02792 1.22946 0.400024 2.00156 0.400024C2.77366 0.400024 3.40156 1.02792 3.40156 1.80002C3.40156 2.57212 2.77366 3.20002 2.00156 3.20002ZM2.00156 4.60002C2.77366 4.60002 3.40156 5.22792 3.40156 6.00002C3.40156 6.77212 2.77366 7.40002 2.00156 7.40002C1.22946 7.40002 0.601562 6.77212 0.601562 6.00002C0.601562 5.22792 1.22946 4.60002 2.00156 4.60002ZM2.00156 8.80002C2.77366 8.80002 3.40156 9.42793 3.40156 10.2C3.40156 10.9721 2.77366 11.6 2.00156 11.6C1.22946 11.6 0.601562 10.9721 0.601562 10.2C0.601562 9.42793 1.22946 8.80002 2.00156 8.80002Z"
                                      fill="#666666"
                                    />
                                  </svg>
                                  {editPopup === index && (
                                    <div
                                      className="bg-white rounded p_10 editPopup"
                                      ref={popupsRef}
                                    >
                                      <button
                                        className="block font_12 py-5"
                                        onClick={() =>
                                          fetchSidebarDetails(
                                            item?.unique_booking_id
                                          )
                                        }
                                      >
                                        View Details
                                      </button>

                                      {/* <button className="block font_12 py-5 cancel-text">
                                    Cancel Booking
                                  </button> */}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </table>
                    ) : (
                      <NoDataFound />
                    )}

                    {isSliderVisible && (
                      <SeeBookingSlider
                        singleBookingData={singleSliderBookingData}
                        onClose={handleCloseSlider}
                        setIsSliderVisible={setIsSliderVisible}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <VendorPackages
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
              />
            </>
          )}
        </section>
      </div>
    </>
  );
}

export default Bookings;
