import React, { useEffect, useState } from "react";
import "../SingleActivities/SingleActivities.css";
import Footer from "../../CustomComponents/Footer/Footer";
import SendItinerary from "../../CustomComponents/SendItinerary/SendItinerary";
import ChooseUs from "../../CustomComponents/ChooseUs/ChooseUs";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import Slider from "react-slick";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import SingleActivitySearchForm from "../../CustomComponents/SingleActivitySearchForm/SingleActivitySearchForm";
import { formatIndianCurrency, scrollToTop } from "../../utils/utils";
import BgBackdrop from "../../CustomComponents/BgBackDrop/BgBackDrop";
import Skeleton from "react-loading-skeleton";
import { Fancybox } from "@fancyapps/ui";
import { animateScroll as scroll } from "react-scroll";
import SendEnquiryPopup from "../../CustomComponents/SendEnquiryPopup/SendEnquiryPopup";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowRight slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowLeft slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SingleActivities() {
  const [singleActivityData, setSingleActivityData] = useState({});
  const location = useLocation();
  const [bookingData, setBookingData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate()

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const { id } = useParams();
  const [showRatePopup, setshowRatePopup] = useState(false);
  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchSingleActivityData = () => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    // Set loader to true when the request starts
    setLoader(true);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/activities/fetch/single/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSingleActivityData(result?.data);
        // Set loader to false when the data is fetched successfully
        scrollToTop();
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        // Set loader to false in case of an error
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchSingleActivityData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const data = {
      location: params.get("location"),
    };
    setBookingData(data);
    // fetchPackageData(data);
  }, [location.search]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  return (
    <div className="singleActivity">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <Header />
      <SingleActivitySearchForm />

      <section className="common_padding">
        <div className="container">
          {loader ? (
            <Slider ref={slider} {...settings}>
              {Array.from({ length: 5 }).map((_, index) => {
                return (
                  <div style={{ width: "33.33%" }} className="destination_link">
                    <div>
                      <Skeleton height={300} />
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="relative">
              <div className="flex align_items_center flex_gap_10">
                <span
                  onClick={() => [
                    scroll.scrollToTop({
                      duration: 500,
                      smooth: "easeInOutQuad",
                    }),
                    navigate(-1),
                  ]}
                  style={{
                    background: "#cccccc7e",
                    padding: "5px 10px 10px",
                    borderRadius: "6px",
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                      fill="#333333"
                    ></path>
                  </svg>
                </span>{" "}
                <h4>
                  <b>{singleActivityData?.name}</b>
                </h4>
              </div>
              <Slider ref={slider} {...settings} className="mt-10">
                {singleActivityData?.images?.map((item) => {
                  return (
                    <div className="activity-slider">
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </Slider>
              {singleActivityData?.images?.length > 1 && (
                <div>
                  <button
                    className="images_btn"
                    onClick={() => {
                      Fancybox.show(
                        singleActivityData?.images?.map((src) => ({
                          src,
                          type: "image",
                        }))
                      );
                    }}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 16 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z" />
                    </svg>
                    See all images
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
      {/* Activities Details */}
      <section>
        <div class="container">
          <main>
            <div class="overview-content SingleActivities-overview">
              <div>
                <p>
                  <b>Activity Location: </b>
                  {bookingData?.location}
                </p>
                {/* <p>
                  <b>Activity Timing: </b> Between 08:00 AM to 05:00 PM
                </p>
                <p>
                  <b>Pickup Location: </b>Nehru Kund, Manali
                </p> */}
                <p className="mt-10">{singleActivityData?.content}</p>{" "}
                {/* <b>How To Reach?</b>
                <li>
                  The distance between Manali to Nehru Kund is 20-23 km by road.
                  You can also find the distance from Manali to Nehru Kund using
                  other travel options like bus, subway, tram, train, and rail.
                </li>
                <li>
                  Drive down to your paragliding spot in Nehru Kund which is
                  around 23 km from Manali on the Kullu road.
                </li>
                <li>
                  {" "}
                  The package includes a transfer from the Nehru Kund
                  paragliding counter on the National highway to the start
                  point. To reach the pickup point on the national highway one
                  can access public or a private mode of transportation.
                </li>{" "} */}
                {/* <div className="mt-10">
                  {" "}
                  <b>Note</b>
                  <li>
                    Drive down to your paragliding spot in Nehru Kund which is
                    around 23 km from Manali on the Kullu road.
                  </li>
                </div> */}
              </div>
              {singleActivityData?.rules?.map((item) => {
                return (
                  <>
                    {item.rule_type === "terms-and-conditions" && (
                      <div className="list_details">
                        <h4 className="p_b_10">Terms and Conditions</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      </div>
                    )}
                  </>
                );
              })}

              {singleActivityData?.rules?.map((item) => {
                return (
                  <>
                    {item.rule_type === "cancellation-policy" && (
                      <div class="list_details">
                        <h4 className="p_b_10">Cancellation Policies</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      </div>
                    )}
                  </>
                );
              })}
              <h4 className="py-15">FAQs</h4>
              {singleActivityData?.frequently_asked_questions?.map(
                (item, index) => (
                  <div className="accordion mb-20">
                    <div className="accordion-item" key={index}>
                      <div
                        className="flex justify_content_between p_10 cursor_pointer accordion_icon align_items_center"
                        onClick={() => toggleAccordion(index)}
                      >
                        <div className="accordion-item-header">
                          {item?.name}
                        </div>
                        <svg
                          className={`accordion-icon ${
                            activeIndex === index ? "rotated" : ""
                          }`}
                          width="10"
                          height="10"
                          viewBox="0 0 9 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 0.75L4.75 4.25L1.25 0.75"
                            stroke="#000"
                          />
                        </svg>
                      </div>
                      <div
                        className={`accordion-item-body ${
                          activeIndex === index ? "active" : ""
                        }`}
                      >
                        <div
                          className="accordion-item-body-content"
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <aside>
              <div className="common_background" id="activityPrice">
                <p>Starting Price</p>
                <h4>
                  <b>
                    {formatIndianCurrency(singleActivityData?.activity_price)}{" "}
                  </b>
                  <small> per Adult</small>
                </h4>
                {/* <div class="buttons"> */}
                {/* <button className="common_btn">Book Now</button> */}
                <button
                  className="secondary_btn mt-10 border-radius"
                  onClick={(e) => handleshowRatePopup(e)}
                >
                  Send Enquiry
                </button>
                {/* </div> */}
              </div>
              <OurQuery />
              <ChooseUs />
              {/* <SendItinerary /> */}
            </aside>
          </main>
        </div>

        {showRatePopup && (
          <SendEnquiryPopup
          package_id={singleActivityData?.id}
          setshowRatePopup={setshowRatePopup}
        />
        )}
      </section>

      <Footer />
    </div>
  );
}

export default SingleActivities;
